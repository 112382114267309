import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { TextInput, minValue } from 'react-admin';

import { Measurement } from '../components';
import { guttersContainer, marginGutters } from '../helpers';
import { required } from '../validations';

const styles = theme => {
	const gutters = marginGutters(theme);
	const container = guttersContainer(theme);
	const upSmBreakpoint = theme.breakpoints.up('sm');

	const guttersSize = gutters.marginLeft + gutters.marginRight;
	const upSmGuttersSize = gutters[upSmBreakpoint].marginLeft + gutters[upSmBreakpoint].marginRight;

	return {
		container: {
			...container,
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'wrap'
		},
		input: {
			...gutters,
			flex: `1 0 calc(100% - ${guttersSize}px)`,
			[upSmBreakpoint]: {
				...gutters[upSmBreakpoint],
				flexBasis: `calc(50% - ${upSmGuttersSize}px)`
			}
		},
		fullWidth: {
			flexBasis: `calc(100% - ${guttersSize}px)`,
			[upSmBreakpoint]: {
				flexBasis: `calc(100% - ${upSmGuttersSize}px)`
			}
		}
	};
};

class UnconnectedFieldsEditSection extends Component {
	render() {
		const { classes, onChangeName, ...props } = this.props;

		return (
			<div className={classes.container}>
				<TextInput
					{...props}
					className={`${classes.input} ${classes.fullWidth}`}
					onChange={onChangeName}
					source="name"
					validate={required('storageareas', 'name')}
				/>
				<Measurement
					source="area"
					className={`${classes.input} ${classes.fullWidth}`}
					placeholder="1000"
					validate={minValue(0)}
					measure="m²"
					defaultValue={0}
				/>
			</div>
		);
	}
}

const FieldsEditSection = withStyles(styles)(UnconnectedFieldsEditSection);

FieldsEditSection.defaultProps = {
	onChangeName: () => ''
};

export default FieldsEditSection;
