import {
	Avatar,
	Button,
	CardActions,
	CircularProgress,
	MuiThemeProvider,
	createMuiTheme,
	withStyles
} from '@material-ui/core';
import { Lock as LockIcon } from '@material-ui/icons';
import React, { Component } from 'react';
import { Notification, translate, userLogin } from 'react-admin';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'recompose';

import renderInput from './renderInput';
import { lightTheme } from './themes';

export const styles = theme => ({
	main: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '100vh',
		alignItems: 'center',
		justifyContent: 'flex-start',
		background: 'url(/Warehouse1.svg) top 50px right 50% no-repeat',
		backgroundSize: 350,
		[theme.breakpoints.up('sm')]: {
			background: 'url(/Warehouse3.svg) top -40px right 50% no-repeat',
			backgroundSize: 710
		},
		[theme.breakpoints.up('lg')]: {
			background: 'url(/Warehouse2.svg) top -150px right 50% no-repeat',
			backgroundSize: 1480
		}
	},
	card: {
		minWidth: 300,
		marginTop: '10em'
	},

	avatar: {
		margin: '1em',
		display: 'flex',
		justifyContent: 'center'
	},
	icon: {
		backgroundColor: theme.palette.secondary.main
	},
	form: {
		padding: '0 1em 1em 1em'
	},
	input: {
		marginTop: '1em'
	},
	actions: {
		padding: '0 1em 1em 1em',
		flexDirection: 'column'
	},
	button: {
		margin: 0,
		'&:not(:last-child)': {
			marginBottom: theme.spacing.unit
		}
	}
});

class Login extends Component {
	login = auth => {
		const { userLogin, location } = this.props;

		userLogin(auth, location.state ? location.state.nextPathname : '/');
	};

	render() {
		const { classes, handleSubmit, isLoading, translate } = this.props;

		return (
			<div className={classes.main}>
				<div className={classes.card}>
					<div className={classes.avatar}>
						<Avatar className={classes.icon}>
							<LockIcon />
						</Avatar>
					</div>
					<form onSubmit={handleSubmit(this.login)}>
						<div className={classes.form}>
							<div className={classes.input}>
								<Field
									autoFocus
									name="email"
									component={renderInput}
									label={translate('ra.auth.username')}
									type="text"
									disabled={isLoading}
								/>
							</div>
							<div className={classes.input}>
								<Field
									name="password"
									component={renderInput}
									label={translate('ra.auth.password')}
									type="password"
									disabled={isLoading}
								/>
							</div>
						</div>
						<CardActions className={classes.actions}>
							<Button
								variant="contained"
								type="submit"
								color="primary"
								disabled={isLoading}
								className={classes.button}
								fullWidth
							>
								{isLoading && <CircularProgress size={20} thickness={2} />}
								{translate('ra.auth.sign_in')}
							</Button>
							<Button className={classes.button} component={Link} to="/forgot-password" fullWidth>
								{translate('ra.auth.forgot_password')}
							</Button>
						</CardActions>
					</form>
				</div>
				<Notification />
			</div>
		);
	}
}

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });

const enhance = compose(
	translate,
	reduxForm({
		form: 'signIn',
		validate: (values, props) => {
			const errors = {};
			const { translate } = props;
			if (!values.email) {
				const translateField = translate('ra.auth.username');
				errors.email = translate('ra.validation.required', { field: translateField });
			}
			if (!values.password) {
				const translateField = translate('ra.auth.password');
				errors.password = translate('ra.validation.required', { field: translateField });
			}

			// const emailError = email(values.email, values, { translate });
			// if (emailError) {
			//     errors.email = emailError;
			// }
			return errors;
		}
	}),
	connect(mapStateToProps, { userLogin }),
	withStyles(styles)
);

const EnhancedLogin = enhance(Login);

// We need to put the MuiThemeProvider decoration in another component
// Because otherwise the withStyles() HOC used in EnhancedLogin won't get
// the right theme
const LoginWithTheme = props => (
	<MuiThemeProvider theme={createMuiTheme(lightTheme)}>
		<EnhancedLogin {...props} />
	</MuiThemeProvider>
);

export default LoginWithTheme;
