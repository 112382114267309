import { Button, CREATE, DELETE, SimpleShowLayout, translate, withDataProvider } from 'react-admin';
import { Card, CardContent, CardHeader, Tooltip, withStyles } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { compose } from 'recompose';
import { CONTAINER, CUSTOMER, NOTE, ORDER_NUMBER } from '../searchContext/constants';
import { useContainerHallMode } from './context';
import { Add } from '@material-ui/icons';
import { nextUniqueKey } from '../../helpers';
import Rows from './Components/Rows';
import RowWithNumberOfColumns from './Components/RowsWithNumberOfColumns';
import Search from '../Search';
import Header from './Components/Header';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import setCardWidth from './helpers/setCardWidth';
import cloneDeep from 'lodash/cloneDeep';
import { addStorageArea, removeStorageArea } from '../helpers';
import { useSections } from '../sectionsContext';
import NoteForm from '../../components/NoteForm';

const styles = theme => {
	return {
		searchBlock: {
			display: 'flex',
			gap: '30px'
		},
		card: {
			width: '100%',
			marginTop: theme.spacing.unit * 3,
			maxWidth: '100%'
		},
		cardContent: {
			minHeight: '200px'
		},
		transformWrapper: {
			width: '100%',
			height: '100%'
		},
		rowWrapper: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			padding: '50px 0 0'
		},
		row: {
			display: 'flex',
			margin: '20px 0',
			alignItems: 'flex-end'
		},
		rowWithNumbersOfColumns: {
			display: 'flex',
			top: '0',
			position: 'absolute',
			zIndex: '3'
		},
		addRowButtonWrapper: {
			display: 'flex',
			'& span': {
				width: '100%'
			}
		},
		addRowButton: {
			width: '50%',
			backgroundColor: '#eae9e9',
			margin: '0 30px',
			color: 'black',
			borderRadius: '10px',
			'&:hover': {
				backgroundColor: '#4eabfc'
			}
		},
		numbersOfColumns: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			minWidth: '150px',
			backgroundColor: '#eae9e9',
			borderRadius: '10px',
			fontSize: '50px',
			margin: '0 20px'
		},
		titleWrapper: {
			width: '100px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			zIndex: '3',
			position: 'relative'
		},
		title: {
			fontSize: '50px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			position: 'absolute'
		}
	};
};

const EditLayout = ({ record, classes, dataProvider, translate }) => {
	const { sections, setSections, updateSections } = useSections();
	const { setSectionIdForNotes, sectionIdForNotes } = useContainerHallMode();

	const [isAddNewSectionDisabled, setIsAddNewSectionDisabled] = useState(false);

	useEffect(() => {
		const childrenCopy = cloneDeep(record.children).map(child => ({
			...child,
			uniqueKey: nextUniqueKey()
		}));
		setSections(childrenCopy);

		// used to limit width and height of card
		const card = document.querySelector('[data-card]');
		const cardContent = document.querySelector('[data-card-content]');
		const headroom = document.querySelector('.headroom');
		const resizeListener = () => {
			setCardWidth(card, headroom.clientWidth, cardContent);
		};
		resizeListener();
		window.addEventListener('resize', resizeListener);
		return () => {
			window.removeEventListener('resize', resizeListener);
		};
	}, []);

	const handlePanning = ref => {
		const {
			state: { positionX, positionY, scale }
		} = ref;
		const titleWrappers = document.querySelectorAll('[data-title-wrapper]');
		titleWrappers.forEach(titleWrapper => {
			titleWrapper.style.left = Math.max(-(positionX / scale), 0) + 'px';
		});

		const numberOfColumnsWrapper = document.querySelector('[data-columns-number]');
		numberOfColumnsWrapper.style.top = Math.max(-(positionY / scale), 0) + 'px';
	};

	const addNewRow = async () => {
		try {
			const maxStorageIndex = Math.max(...sections.map(child => child.storage_order ?? 0));
			const { data: createdRow } = await dataProvider(CREATE, 'storageareas', {
				id: record.id,
				data: {
					name: sections.length + 1,
					storage_order: maxStorageIndex + 1,
					parent: {
						id: record.id
					}
				}
			});

			if (createdRow) {
				addStorageArea(sections, createdRow);
				await handleAddNewColumn(createdRow);
			}
		} catch (error) {
			console.error('[warehouse]: addition new row failed', error);
		}
	};

	const handleAddNewColumn = async row => {
		setIsAddNewSectionDisabled(true);
		try {
			const { data: createdColumn } = await dataProvider(CREATE, 'storageareas', {
				id: row.id,
				data: {
					name: `${row.name}.${row.children?.length + 1 ?? 1}`,
					parent: {
						id: row.id
					}
				}
			});
			if (createdColumn) {
				addStorageArea(sections, createdColumn);
			}
			updateSections();
		} catch (error) {
			if (!row.children.length) deleteRow(row.id);
			console.error('[warehouse]: addition new column failed', error);
		} finally {
			setIsAddNewSectionDisabled(false);
		}
	};

	const deleteRow = async rowId => {
		const { data: deletedSection } = await dataProvider(DELETE, 'storageareas', {
			id: rowId
		});
		removeStorageArea(sections, deletedSection.id);
		updateSections();
	};

	const { name } = record;

	const cardContent = document.querySelector('[data-card-content]');

	const initialScale = useMemo(() => {
		const childsLengthArray = [];
		// if it's a new warehouse (containerhall), we set initialScale to 1, because we need to show TransformWrapper
		if (!record.children.length) return 1;
		record.children.forEach(child => {
			if (child) {
				childsLengthArray.push(child?.children?.length);
			}
		});
		const maxChildsCount = Math.max(...childsLengthArray);
		// 170 - column width,  40 - margins, 90 - first column width + add column button width
		const scale =
			cardContent?.clientWidth / (maxChildsCount * 170 + (40 * maxChildsCount - 1) + 90);
		// for better visualization set initialScale to 1, if it's bigger than 1
		return scale > 1 ? 1 : scale;
	}, [cardContent]);

	return (
		<SimpleShowLayout record={record}>
			<div className={classes.searchBlock}>
				<Search
					sections={sections}
					searchBy={CONTAINER}
					additionalParams={{ level: 4 }}
					rootId={record?.id}
				/>
				<Search
					sections={sections}
					searchBy={CUSTOMER}
					additionalParams={{ level: 4 }}
					rootId={record?.id}
				/>
				<Search
					sections={sections}
					searchBy={ORDER_NUMBER}
					additionalParams={{ level: 4 }}
					rootId={record?.id}
				/>
				<Search
					sections={sections}
					searchBy={NOTE}
					additionalParams={{ level: 4 }}
					rootId={record?.id}
				/>
			</div>
			<Card className={classes.card}>
				<CardHeader title={<Header name={name} record={record} />} />
				<CardContent className={classes.cardContent} data-card-content>
					{!!initialScale && (
						<TransformWrapper
							centerZoomedOut={false}
							minScale={0.1}
							maxScale={1.5}
							panning={{ velocityDisabled: true }}
							customTransform={(x, y, scale) => {
								return 'translate('
									.concat(x < 0 ? x : 0, 'px, ')
									.concat(y < 0 ? y : 0, 'px) scale(')
									.concat(scale, ')');
							}}
							onPanning={handlePanning}
							onZoomStop={handlePanning}
							alignmentAnimation={{ disabled: true }}
							zoomAnimation={{ size: 0, disabled: true }}
							initialScale={initialScale}
						>
							<TransformComponent wrapperClass={classes.transformWrapper}>
								<div className={classes.rowWrapper}>
									<div data-row data-columns-number className={classes.rowWithNumbersOfColumns}>
										<div data-title-wrapper className={classes.titleWrapper}>
											<span className={classes.title}></span>
										</div>
										<RowWithNumberOfColumns
											maxColumnsCount={
												sections.length &&
												Math.max(...sections.map(child => child.children?.length))
											}
										/>
									</div>
									<Rows
										record={record}
										rootStorageId={record.id}
										isEditMode={true}
										addNewRow={addNewRow}
										handleAddNewColumn={handleAddNewColumn}
										isAddNewSectionDisabled={isAddNewSectionDisabled}
									/>
									<div data-row>
										<div className={classes.addRowButtonWrapper}>
											<div data-title-wrapper className={classes.titleWrapper}>
												<span className={classes.title}></span>
											</div>
											<Tooltip title={translate('resources.warehouses.tooltips.add_new_row')}>
												<span>
													<Button
														aria-label="Add"
														className={classes.addRowButton}
														onClick={() => {
															if (!isAddNewSectionDisabled) addNewRow();
														}}
													>
														<Add />
													</Button>
												</span>
											</Tooltip>
										</div>
									</div>
								</div>
							</TransformComponent>
						</TransformWrapper>
					)}
				</CardContent>
			</Card>
			<NoteForm
				size="md"
				title="resources.warehouses.action.add_comment"
				showDialog={sectionIdForNotes}
				updateSections={updateSections}
				setSectionIdForNotes={setSectionIdForNotes}
				sectionIdForNotes={sectionIdForNotes}
				sections={sections}
			/>
		</SimpleShowLayout>
	);
};

const enhance = compose(translate, withStyles(styles), withDataProvider);

export default enhance(EditLayout);
