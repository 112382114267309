import React from 'react';

const WarehouseField = ({ record: { name, total_area, level, filled_area } }) => {
	return (
		<div className="changed-value" style={{ paddingLeft: (level - 1) * 16 }}>
			{name} ({Math.ceil(filled_area * 100) / 100} / {total_area})
		</div>
	);
};

export default WarehouseField;
