import { configStyles } from './helpers';

export default theme => {
	const { gutters, container, upSmGuttersSize } = configStyles(theme);

	return {
		minWidth: {
			width: 100
		},
		nowrap: {
			whiteSpace: 'nowrap'
		},
		flex: {
			display: 'flex',
			flexWrap: 'nowrap'
		},
		flexChild: {
			flex: '1 0 1px'
		},
		flexEnd: {
			alignItems: 'flex-end'
		},
		flexCenter: {
			alignItems: 'center'
		},
		buttonEnd: {
			'&:not(:first-child)': {
				marginLeft: '0.5rem'
			}
		},
		marginInput: {
			marginTop: 16,
			marginBottom: 8
		},
		spacer: {
			[theme.breakpoints.down('xs')]: {
				marginTop: theme.spacing.unit * 2
			}
		},
		fullWidth: {
			width: '100%'
		},
		container: {
			...container,
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'wrap'
		},
		input: {
			...gutters,
			flex: `1 0 calc(50% - ${upSmGuttersSize}px)`,
			minWidth: 272
		},
		toolbar: {
			float: 'right'
		},
		tabButton: {
			borderRadius: '0',
			transition: 'none',
			'&+button': {
				borderLeftColor: 'transparent'
			}
		}
	};
};
