import BaseDateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-US';
import deLocale from 'date-fns/locale/de';
import {
	DatePicker as BaseDatePicker,
	TimePicker as BaseTimePicker,
	DateTimePicker as BaseDateTimePicker,
	MuiPickersUtilsProvider
} from 'material-ui-pickers';
import { addField, FieldTitle } from 'ra-core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { translate } from 'react-admin';

class DateFnsUtils extends BaseDateFnsUtils {
	getStartOfMonth(value) {
		return this.startOfMonth(value);
	}
}

const dateFnsLocale = {
	en: enLocale,
	de: deLocale
};

class UnconnectedBasePickerComponent extends Component {
	state = {
		isWrongDateOrTime: false
	};

	onChange(date) {
		this.props.input.onChange(date);
		this.props.input.onBlur();
	}

	render() {
		const {
			input,
			options,
			label,
			source,
			resource,
			isRequired,
			className,
			meta,
			utils,
			locale,
			PickerComponent,
			translate
		} = this.props;

		const { touched, error } = meta;

		return (
			<MuiPickersUtilsProvider utils={utils} locale={dateFnsLocale[locale]}>
				<PickerComponent
					{...options}
					label={
						<FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
					}
					margin="normal"
					error={!!((touched && error) || this.state.isWrongDateOrTime)}
					helperText={
						(touched && error) || this.state.isWrongDateOrTime
							? translate('resources.deliveries.messages.wrong_date_or_time')
							: ''
					}
					ref={node => {
						this.picker = node;
					}}
					disablePast
					minDate={new Date()}
					className={className}
					value={input.value ? input.value : null}
					onChange={date => {
						if (date.getTime() > new Date().getTime()) {
							this.setState({
								isWrongDateOrTime: false
							});
							return this.onChange(date);
						} else {
							this.setState({
								isWrongDateOrTime: true
							});
						}
					}}
				/>
			</MuiPickersUtilsProvider>
		);
	}
}

const mapStateToProps = state => ({
	locale: state.i18n.locale
});

const BasePickerComponent = compose(
	translate,
	connect(mapStateToProps, undefined)
)(UnconnectedBasePickerComponent);

BasePickerComponent.defaultProps = {
	input: {},
	isRequired: 'false',
	label: '',
	meta: { touched: false, error: false },
	options: {},
	resource: '',
	source: '',
	labelTime: '',
	className: '',
	utils: DateFnsUtils,
	locale: undefined
};

const DatePicker = props => <BasePickerComponent {...props} PickerComponent={BaseDatePicker} />;
const TimePicker = props => <BasePickerComponent {...props} PickerComponent={BaseTimePicker} />;
const DateTimePicker = props => (
	<BasePickerComponent {...props} PickerComponent={BaseDateTimePicker} />
);

export const DateInput = addField(DatePicker);
export const TimeInput = addField(TimePicker);
export const DateTimeInput = addField(DateTimePicker);
