import { withStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import React, { Component, Fragment } from 'react';
import { BooleanInput } from 'react-admin';

import { AddressCard, Card, ImageInputPro } from '../components';
import generalStyles from '../styles';

import UserFormFields from '../users/UserFormFields';
import { ROLE_MAIN_CUSTOMER } from '../users/roles';

const styles = theme => ({
	...generalStyles(theme),
	loaderContainer: {
		backgroundColor: fade(theme.palette.background.default, 0.5),
		zIndex: 100
	},
	cardClass: {
		marginTop: theme.spacing.unit * 2,
		[theme.breakpoints.up('xs')]: {
			marginTop: theme.spacing.unit * 3
		}
	}
});

class ProfileFormFields extends Component {
	_isMounted = false;
	state = {
		isLoading: false
	};

	constructor(props) {
		super(props);

		const { record } = this.props;

		this.state = {
			isExistsInvoiceAddress: !!record.invoice_address
		};
	}

	componentDidMount() {
		this._isMounted = true;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleAfterUploadFile = () => {
		if (this._isMounted) {
			this.setState({ isLoading: false });
		}
	};

	handleBeforeUploadFile = () => {
		if (this._isMounted) {
			this.setState({ isLoading: true });
		}
	};

	handleChangeDifferentInvoiceAddress = (customer, checked) => {
		this.setState({
			isExistsInvoiceAddress: checked
		});
	};

	render() {
		const { classes, className, record, permissions, onChangeAvatar } = this.props;
		const { isExistsInvoiceAddress, isLoading } = this.state;

		return (
			<div className={className}>
				<ImageInputPro
					resource="documents"
					record={record}
					source="photo"
					label=""
					onBeforeUpload={this.handleBeforeUploadFile}
					onAfterUpload={this.handleAfterUploadFile}
					onChange={onChangeAvatar}
					accept={['image/x-png', 'image/gif', 'image/jpeg', 'image/png'].join(',')}
				/>

				{record.address ? (
					<Fragment>
						<Card resource="customers" source="user" cardClass={classes.cardClass}>
							<UserFormFields record={record} resource="users" roles={[]} />
						</Card>

						<AddressCard
							source="address"
							resource="customers"
							isShowName={false}
							cardClass={classes.cardClass}
						/>

						{permissions === ROLE_MAIN_CUSTOMER && (
							<Fragment>
								<BooleanInput
									source="different_invoice_address"
									className={classes.cardClass}
									onChange={this.handleChangeDifferentInvoiceAddress}
									defaultValue={isExistsInvoiceAddress}
								/>
								<AddressCard
									source="invoice_address"
									resource="customers"
									cardClass={classes.cardClass}
									disabled={!isExistsInvoiceAddress}
								/>
							</Fragment>
						)}
					</Fragment>
				) : (
					<UserFormFields record={record} resource="users" roles={[]} />
				)}

				{isLoading && (
					<div className={`loader-container ${classes.loaderContainer}`}>
						<div className="loader">Loading...</div>
					</div>
				)}
			</div>
		);
	}
}

export default withStyles(styles)(ProfileFormFields);
