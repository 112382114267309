import React from 'react';
import { Edit } from 'react-admin';

import WarehouseForm from './WarehouseForm';
import { headerNameHelper } from '../helpers/headerNameHelpers';
import { ContainerHallModeProvider } from './containerHall/context';
import { CONTAINERHALL } from './containerHall/constants';
import { default as ContainerHallEditLayout } from './containerHall/EditLayout';
import { SectionsProvider } from './sectionsContext';
import { MovingModeProvider } from './movingMode/context';
import { SearchProvider } from './searchContext';

const WarehouseTitle = ({ record }) => <span>{headerNameHelper(record, record.name)}</span>;

const Layout = props => {
	return props.record.type === CONTAINERHALL ? (
		<SectionsProvider>
			<ContainerHallEditLayout {...props} />
		</SectionsProvider>
	) : (
		<MovingModeProvider>
			<WarehouseForm {...props} isEdit={true} />
		</MovingModeProvider>
	);
};

const WarehouseEdit = props => (
	<ContainerHallModeProvider>
		<SearchProvider>
			<Edit title={<WarehouseTitle />} actions={null} undoable={false} {...props} data-card>
				<Layout {...props} />
			</Edit>
		</SearchProvider>
	</ContainerHallModeProvider>
);

export default WarehouseEdit;
