import { withStyles } from '@material-ui/core';
import { unparse as convertToCSV } from 'papaparse/papaparse';
import React, { Component } from 'react';
import {
	List,
	Responsive,
	downloadCSV,
	setListSelectedIds,
	CardActions,
	ExportButton,
	CreateButton,
	Pagination,
	Button
} from 'react-admin';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import {
	ROLE_COLLECTOR_GUY,
	ROLE_CUSTOMER,
	ROLE_MAIN_CUSTOMER,
	ROLE_WAREHOUSE_MANAGER
} from '../users/roles';

import AddToCartButton from './AddToCartButton';
import ArticleDatagrid from './ArticleDatagrid';
import ArticleFilter from './ArticleFilter';
import MobileGrid from './MobileGrid';
import { IN_WAREHOUSE, NOT_IN_WAREHOUSE } from './statuses';
import { GroupBy } from '../components';
import { groupBySN } from '../AC';

const exporter = posts => {
	posts = posts.map(el => ({
		Id: el.id,
		Name: el.name,
		Serial_number: el.serialNumber,
		Project_id: el.project,
		Barcodes: el.barcodes.reduce((a, b) => +a + b.id + ', ', '').slice(0, -2),
		Category: el.category ? el.category.name : '',
		Colors: el.colors ? el.colors.join(', ') : '',
		Damages: el.damages ? el.damages.join(', ') : '',
		Height: el.height,
		Depth: el.length,
		Width: el.width,
		Manufacturer: el.manufacturer,
		Photos: el.photos ? el.photos.join(', ') : '',
		Storage_area: el.storagearea ? el.storagearea.full_name : '',
		Type: el.type,
		Created_at: new Date(el.created_at).toLocaleString(),
		Updated_at: new Date(el.updated_at).toLocaleString()
	}));

	const csv = convertToCSV({
		data: posts,
		fields: [
			'Id',
			'Name',
			'Serial_number',
			'Project_id',
			'Barcodes',
			'Category',
			'Colors',
			'Damages',
			'Height',
			'Depth',
			'Width',
			'Manufacturer',
			'Photos',
			'Storage_area',
			'Type',
			'Created_at',
			'Updated_at'
		]
	});
	downloadCSV(csv, 'articles');
};

const styles = theme => ({
	articleList: {
		'& div[data-test="bulk-actions-toolbar"]': {
			backgroundColor: theme.palette.primary.toolbar
		}
	}
});

const ArticleListActions = ({
	basePath,
	currentSort,
	displayedFilters,
	exporter,
	filters,
	filterValues,
	resource,
	showFilter,
	total,
	createButton,
	groupBySerialNumber
}) => (
	<CardActions>
		<Button color="primary" label="ra.action.group_by_sn" onClick={() => groupBySerialNumber()}>
			<GroupBy />
		</Button>
		{filters &&
			React.cloneElement(filters, {
				resource,
				showFilter,
				displayedFilters,
				filterValues,
				context: 'button'
			})}
		{createButton ? <CreateButton basePath={basePath} /> : null}
		{exporter && (
			<ExportButton
				disabled={total === 0}
				resource={resource}
				sort={currentSort}
				filter={filterValues}
				maxResults={9999999}
				exporter={exporter}
			/>
		)}
	</CardActions>
);

const ArticleListPagination = props => {
	return <Pagination rowsPerPageOptions={[25, 100, 250, 500]} {...props} />;
};

class UnconnectedArticleList extends Component {
	componentDidMount() {
		this.props.setListSelectedIds('articles', []);
	}

	/**
	 * method for group articles by serial number
	 */
	changeFilter = () => {
		const { groupBySN, isGrouped } = this.props;
		groupBySN(!isGrouped);
	};

	render() {
		const {
			classes,
			filters,
			actions,
			customActions,
			permissions,
			bulkActionButtons,
			pagination,
			filter,
			isGrouped,
			...props
		} = this.props;

		return (
			<List
				{...props}
				className={classes.articleList}
				bulkActionButtons={permissions !== ROLE_COLLECTOR_GUY ? bulkActionButtons : false}
				filters={<ArticleFilter filters={filters} permissions={permissions} />}
				// if we use this attribute (filter), we rerender our articles' table when isGrouped parameter changed
				filter={{ ...filter, isGrouped: isGrouped }}
				perPage={25}
				actions={
					actions || (
						<ArticleListActions
							groupBySerialNumber={this.changeFilter}
							createButton={permissions !== ROLE_MAIN_CUSTOMER && permissions !== ROLE_CUSTOMER}
						/>
					)
				}
				exporter={
					!customActions &&
					permissions !== ROLE_WAREHOUSE_MANAGER &&
					permissions !== ROLE_MAIN_CUSTOMER &&
					permissions !== ROLE_COLLECTOR_GUY &&
					permissions !== ROLE_CUSTOMER
						? exporter
						: false
				}
				pagination={pagination ?? <ArticleListPagination />}
			>
				<Responsive
					medium={<MobileGrid permissions={permissions} customButtons={customActions} />}
					large={<ArticleDatagrid permissions={permissions} customActions={customActions} />}
				/>
			</List>
		);
	}
}

const mapStateToProps = state => ({
	isGrouped: state.isGroupedArticlesBySN
});

const enhance = compose(
	withStyles(styles),
	connect(mapStateToProps, { setListSelectedIds, groupBySN })
);

const ArticleList = enhance(UnconnectedArticleList);

ArticleList.defaultProps = {
	filter: {
		'status][in][': [NOT_IN_WAREHOUSE, IN_WAREHOUSE]
	},
	queryparams: {
		groupBy: 'serialNumber'
	},
	customActions: false,
	bulkActionButtons: <AddToCartButton />,
	filters: {
		q: true,
		qid: true,
		serialNumber: true,
		name: true,
		category: true,
		project: true,
		type: true,
		manufacturer: true,
		storagearea: true,
		isScratch: true,
		width: true,
		height: true,
		length: true,
		colors: true
	}
};

export default ArticleList;
