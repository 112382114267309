import { AUTH_CHECK, AUTH_GET_PERMISSIONS, AUTH_ERROR, AUTH_LOGIN, AUTH_LOGOUT } from 'react-admin';

import { ROLE_GUEST } from '../users/roles';

import httpClient from './httpClient';
import { fetchUtils, changeLocale } from 'react-admin';

export const createRequestLogin = (email, password) => ({
	url: `${process.env.REACT_APP_API_URL}/oauth/v2/token`,
	options: {
		method: 'POST',
		body: JSON.stringify({
			client_id: process.env.REACT_APP_API_CLIENT_ID,
			client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
			grant_type: 'password',

			username: email,
			password
		}),
		headers: new Headers({ 'Content-Type': 'application/json' })
	}
});

export const createRequestRefresh = refreshToken => {
	const accessToken = localStorage.getItem('access_token');
	let tokenType = localStorage.getItem('token_type');

	if (tokenType) {
		tokenType = tokenType.charAt(0).toUpperCase() + tokenType.substr(1);
	}

	const options = {
		method: 'POST',
		body: JSON.stringify({
			client_id: process.env.REACT_APP_API_CLIENT_ID,
			client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
			grant_type: 'refresh_token',

			refresh_token: refreshToken
		})
	};

	if (accessToken && tokenType) {
		options.user = {
			authenticated: true,
			token: `${tokenType} ${accessToken}`
		};
	}

	return {
		url: `${process.env.REACT_APP_API_URL}/oauth/v2/token`,
		options
	};
};

export const getToken = ({ url, options }) =>
	fetchUtils
		.fetchJson(url, options)
		.then(({ json: { access_token, expires_in, refresh_token, token_type } }) => {
			localStorage.setItem('access_token', access_token);
			localStorage.setItem('expires_at', new Date().getTime() + expires_in * 1000);
			localStorage.setItem('refresh_token', refresh_token);
			localStorage.setItem('token_type', token_type);
		});

export const login = (email, password) => {
	const request = createRequestLogin(email, password);

	return getToken(request)
		.then(() => httpClient(`${process.env.REACT_APP_API_URL}/users/me`))
		.then(({ json: user }) => {
			localStorage.setItem('user', JSON.stringify(user));
			localStorage.setItem('locale', user.language_code);
			changeLocale(user.language_code);
		});
};

const logout = () => {
	localStorage.clear();
};

export const refreshToken = () => {
	const refreshToken = localStorage.getItem('refresh_token');
	if (!refreshToken) {
		return Promise.reject('no refresh token');
	}

	const request = createRequestRefresh(refreshToken);

	return getToken(request);
};

export default (type, params) => {
	if (type === AUTH_LOGIN) {
		const { email, password } = params;

		return login(email, password);
	}

	if (type === AUTH_LOGOUT) {
		logout();

		return Promise.resolve();
	}

	if (type === AUTH_ERROR) {
		const status = params.status;

		if (status === 403) {
			// return Promise.reject();
		}

		if (status === 401) {
			return Promise.reject();
			// return refreshToken();
		}

		return Promise.resolve();
	}

	if (type === AUTH_CHECK) {
		const accessToken = localStorage.getItem('access_token');

		if (!accessToken) {
			return Promise.reject();
		}

		const expiresAt = localStorage.getItem('expires_at');
		if (new Date().getTime() > expiresAt) {
			return Promise.reject();
			// return refreshToken();
		}

		return Promise.resolve();
	}

	if (type === AUTH_GET_PERMISSIONS) {
		const user = localStorage.getItem('user');
		if (user) {
			const { role } = JSON.parse(user);

			return role ? Promise.resolve(role) : Promise.resolve(ROLE_GUEST);
		}

		return Promise.resolve(ROLE_GUEST);
	}

	return Promise.reject('Unknown method');
};
