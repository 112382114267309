import setEqualHeight from '../../../helpers/setEqualHeight';

export default isEditMode => {
	const rows = document.querySelectorAll('[data-row]');
	rows.forEach(row => {
		const columns = row.querySelectorAll('[data-column]');
		const button = row.querySelector('.add-column-button-wrapper');
		const titleWrapper = row.querySelector('[data-title-wrapper]');
		titleWrapper.style.height = `${row.offsetHeight - (isEditMode ? 60 : 20)}px`;
		setEqualHeight(columns);
		if (button) {
			button.style.height = `${row.offsetHeight - 60}px`;
		}
	});
};
