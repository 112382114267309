import { get } from 'lodash';
import { withStyles } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import {
	FileField,
	FileInput as BaseFileInput,
	ImageField,
	ImageInput,
	showNotification,
	Confirm,
	isRequired
} from 'react-admin';
import { Field } from 'redux-form';
import { compose, shallowEqual } from 'recompose';

import withUploadProvider from '../providers/withUploadProvider';
import FormHelperText from '@material-ui/core/FormHelperText';

const isExistFile = (file, files) => {
	return files.some(fileOfArray => shallowEqual(fileOfArray, file));
};

const styles = {
	root: {
		marginTop: 16,
		marginBottom: 8
	},
	input: {
		marginTop: 0,
		marginBottom: 0,
		'& .previews': {
			marginLeft: '-0.5rem',
			marginRight: '-0.5rem'
		},
		'& img': {
			maxWidth: 'calc(100% - 16px)'
		}
	},
	helper: {
		marginTop: 4
	}
};

class UnconnectedFileInput extends Component {
	state = {
		urls: [],
		files: [],
		isOpenRemoveDialog: false,
		updatedFiles: []
	};

	componentDidMount() {
		const {
			record,
			source,
			input: { onChange, onBlur }
		} = this.props;
		const urls = get(record, source) || [];

		const files = urls.map(url => ({
			src: url,
			title: url.split('/').pop()
		}));

		this.setState({
			urls,
			files
		});

		onChange(urls);
		onBlur(files);
	}

	componentWillUnmount() {
		this.setState({
			urls: [],
			files: []
		});
	}

	handleDialogClose = () => {
		this.setState({
			isOpenRemoveDialog: false
		});
	};

	handleAdd = async updatedFiles => {
		const { onBeforeUpload, onAfterUpload, dispatch, maxFilesCount } = this.props;

		if (typeof maxFilesCount === 'number' && updatedFiles.length > maxFilesCount) {
			dispatch(showNotification('ra.notification.upload_file_length_error', 'warning'));
			return [];
		}

		const filesForUpload = updatedFiles.filter(file => file.rawFile);
		const stateFiles = updatedFiles.filter(file => !file.rawFile);

		const uploadPromises = filesForUpload.map(file => this.uploadFile(file));

		onBeforeUpload();
		const files = await Promise.all(uploadPromises);

		const savedFiles = stateFiles.concat(files).filter(({ src }) => !!src);
		const modifiedStateUrls = savedFiles.map(({ src }) => src);

		this.setState({
			files: savedFiles,
			urls: modifiedStateUrls
		});

		onAfterUpload();

		if (filesForUpload.length !== files.length) {
			dispatch(showNotification('ra.notification.upload_file_error', 'warning'));
		}

		return modifiedStateUrls;
	};

	// eslint-disable-next-line no-unused-vars
	handleBlur = async ({ preventDefault, ...updatedFiles }) => {
		const {
			input: { onBlur, onChange }
		} = this.props;
		const { files: stateFiles } = this.state;

		updatedFiles = Object.values(updatedFiles);

		if (updatedFiles.length > stateFiles.length) {
			const newUrls = await this.handleAdd(updatedFiles);
			onChange(newUrls);
			if (newUrls.length === 0) {
				onBlur(null);
			} else {
				onBlur(updatedFiles);
			}
		} else if (updatedFiles.length < stateFiles.length) {
			this.setState({
				updatedFiles: updatedFiles,
				isOpenRemoveDialog: true
			});
		}
	};

	handleRemove = () => {
		const {
			input: { onChange, onBlur }
		} = this.props;
		const { updatedFiles, files: stateFiles, urls: stateUrls } = this.state;

		const removedFiles = stateFiles.filter(stateFile => !isExistFile(stateFile, updatedFiles));

		let changedFiles = [];
		let changedUrls = [];

		removedFiles.forEach(file => {
			changedFiles = updatedFiles.filter(updatedFile => updatedFile.src !== file.src);
			changedUrls = stateUrls.filter(src => src !== file.src);
		});

		this.setState({
			files: changedFiles,
			urls: changedUrls,
			isOpenRemoveDialog: false
		});

		onChange(changedUrls);
		onBlur(changedFiles);
	};

	uploadFile = file => {
		const { uploadProvider, resource } = this.props;

		return uploadProvider(resource, file);
	};

	render() {
		const {
			accept,
			className,
			classes,
			source,
			label,
			disabled,
			isImageField,
			isRequired,
			input,
			meta
		} = this.props;
		const { files: stateFiles, isOpenRemoveDialog } = this.state;
		const FileInputComponent = isImageField ? ImageInput : BaseFileInput;
		const error = meta && meta.touched && meta.error;

		return (
			<Fragment>
				<div className={`${classes.root} ${className}`}>
					<FileInputComponent
						source={source}
						className={classes.input}
						isRequired={isRequired}
						label={label}
						accept={accept}
						multiple={true}
						input={{
							...input,
							value: stateFiles,
							onBlur: this.handleBlur
						}}
						options={{
							inputProps: {
								disabled: disabled
							}
						}}
					>
						{isImageField ? (
							<ImageField
								source="src"
								title="title"
								onClick={e => window.open(e.target.src, '_blank')}
							/>
						) : (
							<FileField source="src" title="title" target="_blank" />
						)}
					</FileInputComponent>
					{error && (
						<FormHelperText error={true} className={classes.helper}>
							{meta.error}
						</FormHelperText>
					)}
				</div>
				{isOpenRemoveDialog && (
					<Confirm
						isOpen={true}
						title="ra.message.to_list"
						content="ra.message.remove_file"
						confirm="Yes"
						cancel="Cancel"
						onConfirm={this.handleRemove}
						onClose={this.handleDialogClose}
					/>
				)}
			</Fragment>
		);
	}
}

const enhance = compose(withStyles(styles), withUploadProvider);

const FileInput = enhance(UnconnectedFileInput);

FileInput.defaultProps = {
	source: 'file',
	label: 'pos.file',
	addLabel: false,
	accept: 'application/pdf',
	disabled: false,
	isImageField: false
};

const Index = ({ source, validate, ...props }) => (
	<Field
		component={FileInput}
		name={source}
		source={source}
		validate={validate}
		isRequired={isRequired(validate)}
		{...props}
	/>
);

export default Index;
