import React, { useState } from 'react';
import Context from './Context';

const Provider = ({ children }) => {
	const [isMovingModeActive, setMovingModeActive] = useState(false);
	const [isDragging, setDragging] = useState(false);
	const [isConfirmationModalShown, setConfirmationModalShown] = useState(false);

	const toggleMovingMode = () => setMovingModeActive(prev => !prev);

	return (
		<Context.Provider
			value={{
				isMovingModeActive,
				toggleMovingMode,
				isDragging,
				setDragging,
				isConfirmationModalShown,
				setConfirmationModalShown
			}}
		>
			{children}
		</Context.Provider>
	);
};

export default Provider;
