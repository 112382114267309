import { withStyles } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import { DateField, GET_LIST, List, NumberField, Responsive, withDataProvider } from 'react-admin';

import { AddressField, Datagrid, ListActions, ListFilter as DeliveryFilter } from '../components';
import CancelButton from './CancelButton';
import styles from '../styles';
import { ROLE_CUSTOMER } from '../users/roles';

import ApproveButton from './ApproveButton';
import ExpandDelivery from './ExpandDelivery';
import MobileGrid from './MobileGrid';
import { CONFIRMED, OUTSOURCED } from './statuses';
import TypeField from './TypeField';
import UpcomingDeliveredFilter from '../components/UpcomingDeliveredToggle';
import { compose } from 'recompose';

const CustomDeliveryActions = ({ permissions, record, ...props }) => (
	<Fragment>
		{(permissions !== ROLE_CUSTOMER || record.status !== CONFIRMED) &&
			record.status !== OUTSOURCED && <CancelButton record={record} {...props} />}
		{permissions !== ROLE_CUSTOMER &&
			record.status !== CONFIRMED &&
			record.status !== OUTSOURCED && <ApproveButton record={record} {...props} />}
	</Fragment>
);

const DeliveryActions = ({ permissions, ...props }) => (
	<ListActions
		{...props}
		deletable={false}
		editable={props.record.status !== OUTSOURCED}
		isShow={true}
		entityName={'deliveries'}
		customButtons={<CustomDeliveryActions permissions={permissions} />}
	/>
);

const UnconnectedDeliveryList = ({ classes, permissions, basePath, dataProvider, ...props }) => {
	const [articlesToDelivery, setArticlesToDelivery] = useState({});
	const [conditionForFilter, setConditionForFilter] = useState('neq');
	const [currentDeliveryStatus, setCurrentDeliveryStatus] = useState('upcoming');

	const addArticlesToDelivery = async deliveryId => {
		const data = await dataProvider(GET_LIST, 'articles', {
			filter: {
				delivery: deliveryId
			}
		});

		if (!data?.data) return;

		setArticlesToDelivery({
			...articlesToDelivery,
			[deliveryId]: data.data
		});
	};

	const changeFilter = condition => {
		setConditionForFilter(condition === 'upcoming' ? 'neq' : 'eq');
		setCurrentDeliveryStatus(condition);
	};

	return (
		<List
			{...props}
			filters={<DeliveryFilter permissions={permissions} />}
			filter={{ [`status][${conditionForFilter}`]: OUTSOURCED }}
			perPage={25}
			bulkActionButtons={false}
			exporter={false}
			basePath={basePath}
			actions={
				<UpcomingDeliveredFilter
					currentDeliveryStatus={currentDeliveryStatus}
					changeFilter={changeFilter}
				/>
			}
		>
			<Responsive
				medium={<MobileGrid permissions={permissions} />}
				large={
					<Datagrid
						expand={
							<ExpandDelivery
								{...props}
								permissions={permissions}
								basePath={basePath}
								articlesToDelivery={articlesToDelivery}
								addArticlesToDelivery={addArticlesToDelivery}
							/>
						}
					>
						<NumberField source="id" />
						<AddressField
							showName
							sortBy="address"
							label="resources.delivery.fields.recipient"
							headerClassName={classes.fullWidth}
						/>
						<TypeField
							sortBy="address"
							label="resources.delivery.fields.type"
							headerClassName={classes.fullWidth}
							sortable={false}
						/>
						<DateField showTime={true} source="date" className={classes.nowrap} />
						<DeliveryActions headerClassName={classes.minWidth} permissions={permissions} />
					</Datagrid>
				}
			/>
		</List>
	);
};

const enhance = compose(withStyles(styles), withDataProvider);

const DeliveryList = enhance(UnconnectedDeliveryList);

export default DeliveryList;
