import { withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { SimpleForm, Toolbar, translate } from 'react-admin';

import { ToolbarContainer } from '../components';
import generalStyles from '../styles';

import UserFormFields from './UserFormFields';
import {
	ROLE_COLLECTOR_GUY,
	ROLE_CUSTOMER,
	ROLE_MAIN_CUSTOMER,
	ROLE_OFFICE_EMPLOYEE,
	ROLE_WAREHOUSE_MANAGER
} from './roles';
import httpClient from '../providers/httpClient';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';

const styles = theme => ({
	container: {
		width: '100%',
		maxWidth: theme.breakpoints.values['lg']
	},
	spacer: generalStyles(theme).spacer
});

const UserForm = ({ classes, permissions, record, ...props }) => {
	const [localTimeout, setLocalTimeout] = useState(0);

	const asyncValidation = value => {
		return new Promise((resolve, reject) => {
			if (localTimeout) clearTimeout(localTimeout);
			setLocalTimeout(
				setTimeout(async () => {
					const response = (
						await httpClient(
							`${process.env.REACT_APP_API_URL}/users?filter[username]=${value.username}`
						)
					)?.json;
					if (!response) return;
					let isUsernameInResponse;
					if (record.id) {
						const filteredArray = response.filter(
							user => user.username.toLowerCase() !== record.username.toLowerCase()
						);
						isUsernameInResponse = filteredArray.some(
							user => user.username.toLowerCase() === value.username.toLowerCase()
						);
					} else {
						isUsernameInResponse = response?.some(
							user => user.username.toLowerCase() === value.username.toLowerCase()
						);
					}
					isUsernameInResponse
						? reject({ username: props.translate('ra.validation.is_valid_username') })
						: resolve();
				}, 1000)
			);
		});
	};

	return (
		<SimpleForm
			{...props}
			redirect={record.id ? false : 'edit'}
			record={record}
			toolbar={
				<Toolbar classes={{ spacer: classes.spacer }}>
					<ToolbarContainer maxWidth="lg" />
				</Toolbar>
			}
			asyncValidate={asyncValidation}
			asyncChangeFields={['username']}
		>
			<UserFormFields
				className={classes.container}
				permissions={permissions}
				roles={
					record.id
						? []
						: permissions === ROLE_MAIN_CUSTOMER
						? [ROLE_MAIN_CUSTOMER, ROLE_CUSTOMER]
						: permissions === ROLE_OFFICE_EMPLOYEE
						? [ROLE_COLLECTOR_GUY, ROLE_WAREHOUSE_MANAGER]
						: [ROLE_OFFICE_EMPLOYEE, ROLE_COLLECTOR_GUY, ROLE_WAREHOUSE_MANAGER]
				}
				chooseAgent={true}
			/>
		</SimpleForm>
	);
};

const DecoratedForm = reduxForm({
	form: 'user-form',
	destroyOnUnmount: false
});

const enhance = compose(withStyles(styles), translate, DecoratedForm);

export default enhance(UserForm);
