import React from 'react';
import { useMovingMode } from './movingMode/context';
import { useSearch } from './searchContext';
import SectionNode from './SectionNode';

const SectionNodeWrapper = props => {
	const movingMode = useMovingMode();
	const search = useSearch();

	return <SectionNode movingMode={movingMode} search={search} {...props}></SectionNode>;
};

export default SectionNodeWrapper;
