import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { SimpleForm, Toolbar } from 'react-admin';

import { ToolbarContainer } from '../components';
import generalStyles from '../styles';
import { ROLE_COLLECTOR_GUY, ROLE_CUSTOMER, ROLE_WAREHOUSE_MANAGER } from '../users/roles';

import ProfileFormFields from './ProfileFormFields';
import { reduxForm } from 'redux-form';
import { compose } from 'recompose';
import httpClient from '../providers/httpClient';

const styles = theme => ({
	container: {
		width: '100%',
		maxWidth: theme.breakpoints.values['lg']
	},
	spacer: generalStyles(theme).spacer
});

class ProfileForm extends Component {
	constructor(props) {
		super(props);
		const {
			record: { avatar }
		} = this.props;
		this.avatar = avatar;
		this.localTimeout = null;
	}

	handleChangeAvatar = avatar => {
		this.avatar = avatar;
	};

	handleSave = ({ different_invoice_address, ...user }, redirect) => {
		const { save } = this.props;

		if (user.storagearea && user.storagearea.id) {
			user.storagearea = {
				id: user.storagearea.id
			};
		}

		user.photo = this.avatar;

		if (!different_invoice_address) {
			user.invoice_address = null;
		}

		save(user, redirect);

		const userOfLocalStorage = JSON.parse(localStorage.getItem('user'));
		if (user) {
			userOfLocalStorage.photo = user.photo;
		}
		localStorage.setItem('user', JSON.stringify(userOfLocalStorage));
		const storageEvent = new Event('storage');
		storageEvent.storageArea = localStorage;
		window.dispatchEvent(storageEvent);
	};

	asyncValidation = value => {
		return new Promise((resolve, reject) => {
			if (this.localTimeout) clearTimeout(this.localTimeout);
			this.localTimeout = setTimeout(async () => {
				try {
					const response = (
						await httpClient(
							`${process.env.REACT_APP_API_URL}/users?filter[username]=${value.username}`
						)
					)?.json;
					if (!response) return;
					let isUsernameInResponse;
					if (this.props.record.id) {
						const filteredArray = response.filter(
							user => user.username.toLowerCase() !== this.props.record.username.toLowerCase()
						);
						isUsernameInResponse = filteredArray.some(
							user => user.username.toLowerCase() === value.username.toLowerCase()
						);
					} else {
						isUsernameInResponse = response?.some(
							user => user.username.toLowerCase() === value.username.toLowerCase()
						);
					}
					isUsernameInResponse
						? reject({ username: this.props.translate('ra.validation.is_valid_username') })
						: resolve();
				} catch (error) {
					console.error(error.message);
				}
			}, 1000);
		});
	};

	render() {
		const { classes, permissions, record, ...props } = this.props;

		return (
			<SimpleForm
				{...props}
				redirect={record.id ? false : 'edit'}
				record={record}
				toolbar={
					<Toolbar classes={{ spacer: classes.spacer }}>
						<ToolbarContainer
							maxWidth="lg"
							isShowToListButton={
								permissions !== ROLE_CUSTOMER &&
								permissions !== ROLE_WAREHOUSE_MANAGER &&
								permissions !== ROLE_COLLECTOR_GUY
							}
						/>
					</Toolbar>
				}
				asyncValidate={this.asyncValidation}
				asyncChangeFields={['username']}
				save={this.handleSave}
			>
				<ProfileFormFields
					className={classes.container}
					permissions={permissions}
					onChangeAvatar={this.handleChangeAvatar}
				/>
			</SimpleForm>
		);
	}
}

const DecoratedForm = reduxForm({
	form: 'profile-form',
	destroyOnUnmount: false
});

const enhance = compose(withStyles(styles), DecoratedForm);

export default enhance(ProfileForm);
