import { uniq } from 'lodash';
import { withStyles } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import React, { Component } from 'react';
import {
	Button,
	CREATE,
	GET_LIST,
	GET_ONE,
	SimpleForm,
	TextInput,
	Toolbar,
	UPDATE,
	UPDATE_MANY,
	withDataProvider,
	WithPermissions,
	REDUX_FORM_NAME
} from 'react-admin';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import { change } from 'redux-form';
import { connect } from 'react-redux';

import { ChipsInput, Container, Measurement, ToolbarContainer } from '../components';
import { required } from '../validations';

import ArticleActions from './ArticleActions';
import generalStyles from '../styles';
import ProjectID from './ProjectID';
import WarehouseSelectInput from './WarehouseSelectInput';
import getWarehousesHelper from '../helpers/getWarehousesHelper';

// eslint-disable-next-line no-unused-vars
const Articles = ({ resource, ...props }) => (
	<ChipsInput source="articles" resource="projects" {...props} />
);

// eslint-disable-next-line no-unused-vars
const Barcodes = ({ resource, ...props }) => (
	<ChipsInput source="barcodes_ids" resource="articles" {...props} />
);

const WarehousePlace = ({
	classes,
	source = '',
	permissions,
	article,
	resource,
	warehouses,
	storageareaId,
	projectArea,
	onChangeStorageArea,
	onChangeQrCodeStorageArea
}) => (
	<div className={classes.input}>
		<WarehouseSelectInput
			className={`${classes.required} ${classes.flexEnd} ${classes.fullWidth}`}
			permissions={permissions}
			record={article}
			resource={resource}
			source={`${source}storagearea.id`}
			warehouses={warehouses}
			defaultValue={storageareaId}
			onChange={onChangeStorageArea}
			onConfirmQrCode={onChangeQrCodeStorageArea}
		/>
		<Measurement
			source={`${source}area`}
			className={classes.fullWidth}
			label="resources.project_areas.fields.area"
			measure="m²"
			defaultValue={projectArea ? projectArea.area : null}
			validate={required('projects', 'area')}
		/>
	</div>
);

const styles = theme => ({
	...generalStyles(theme),
	root: {
		width: '100%',
		maxWidth: theme.breakpoints.values['md']
	},
	required: {
		'& label': {
			fontWeight: 'bold'
		}
	},
	flexEnd: {
		display: 'flex',
		alignItems: 'flex-end'
	},
	child: {
		flex: '1 0 auto'
	},
	button: {
		marginLeft: '0.5em',
		marginTop: theme.spacing.unit * 2,
		marginBottom: theme.spacing.unit
	}
});

class UnconnectedArticleMove extends Component {
	_isMounted = false;
	state = {
		articles: [],
		selectedArticles: [],
		warehouses: []
	};

	componentDidMount = async () => {
		this._isMounted = true;

		const [article] = await Promise.all([this.fetchArticle(), this.updateWarehouses()]);
		if (this._isMounted) {
			const {
				id: articleId,
				storagearea: { id: storageAreaId }
			} = article;

			this.setState(
				{
					article,
					selectedArticles: [articleId],
					storageArea: storageAreaId
				},
				this.setDefaultState
			);
		}
	};

	componentWillUnmount() {
		this._isMounted = false;
	}

	fetchArticle = async () => {
		const {
			dataProvider,
			match: {
				params: { articleId }
			}
		} = this.props;

		const { data: article } = await dataProvider(GET_ONE, 'articles', {
			id: articleId
		});

		return article;
	};

	handleChangeQrCodeStorageArea = async value => {
		const { storagearea } = JSON.parse(value);

		await this.handleChangeStorageArea(null, storagearea);
	};

	handleChangeStorageArea = async (event, storageArea) => {
		await this.updateProjectArea(storageArea);

		this.props.change(REDUX_FORM_NAME, 'storagearea.id', storageArea);
		this.setState({ storageArea });
	};

	handleSubmit = async (
		{ prev_area: prevArea, area, measurements, transfer_tools: transferTools },
		redirect
	) => {
		const { dataProvider, dispatch } = this.props;
		const {
			article,
			prevProjectArea,
			projectArea,
			projectMeasurements,
			selectedArticles,
			storageArea
		} = this.state;
		const {
			project: projectId,
			storagearea: { id: storageareaId }
		} = article;

		const isUpdatePreviewProjectAreas =
			prevProjectArea && (!projectArea || projectArea.id !== prevProjectArea.id);
		const isUpdateProjectAreas =
			!projectArea || projectArea.area !== area || projectArea.transfer_tools !== transferTools;

		const [{ data: savedProjectArea }, { data: savedArticle }] = await Promise.all([
			isUpdateProjectAreas &&
				dataProvider(projectArea ? UPDATE : CREATE, 'projectareas', {
					id: projectArea && projectArea.id,
					data: {
						area,
						id: projectArea && projectArea.id,
						transfer_tools: transferTools,
						project: {
							id: projectId
						},
						storage_area: {
							id: storageArea
						}
					}
				}),
			storageareaId !== storageArea &&
				dataProvider(UPDATE_MANY, 'articles', {
					ids: selectedArticles,
					data: {
						storagearea: storageArea
					}
				}),
			projectMeasurements !== measurements &&
				dataProvider(UPDATE, 'projects', {
					id: projectId,
					data: {
						measurements,
						id: projectId
					}
				}),
			isUpdatePreviewProjectAreas &&
				dataProvider(UPDATE, 'projectareas', {
					id: prevProjectArea.id,
					data: {
						area: prevArea,
						id: prevProjectArea.id
					}
				})
		]);

		if (this._isMounted && (savedProjectArea || savedArticle)) {
			const state = {};
			if (savedProjectArea) {
				state.prevProjectArea = savedProjectArea;
				state.projectArea = savedProjectArea;
				state.selectedArticles = [article.id];
			}
			if (savedArticle) {
				state.article = savedArticle.find(({ id }) => id === article.id);
			}

			this.setState(state);
		}

		switch (redirect) {
			case 'next':
				localStorage.setItem('is_next', 'is_next');
				localStorage.setItem('changed_storage_area', storageArea);

				return dispatch(push(`/articles/create`));
			case 'done':
				return dispatch(push(`/projects/${projectId}`));
			default: {
				await this.updateWarehouses();

				return;
			}
		}
	};

	updateWarehouses = async () => {
		const { dataProvider } = this.props;

		const warehouses = await getWarehousesHelper(dataProvider);

		if (this._isMounted) {
			this.setState({ warehouses });
		}
	};

	handleToggleSelectArticles = () => {
		const {
			article: { id: articleId },
			articles,
			selectedArticles
		} = this.state;

		if (this._isMounted) {
			if (selectedArticles.length === articles.length) {
				this.setState({
					selectedArticles: [articleId]
				});
			} else {
				this.setState({
					selectedArticles: articles.map(({ id }) => id)
				});
			}
		}
	};

	render() {
		const { classes, permissions } = this.props;
		const {
			article,
			articles,
			prevProjectArea,
			projectArea,
			projectMeasurements,
			selectedArticles,
			warehouses,
			storageArea
		} = this.state;
		if (!article) {
			return null;
		}

		const {
			barcodes,
			project: projectId,
			storagearea: { id: storageareaId }
		} = article;
		const resource = 'articles';

		return (
			<SimpleForm
				validate={this.validate}
				resource={resource}
				redirect={article.id ? false : 'edit'}
				record={article}
				basePath="/articles"
				toolbar={
					<Toolbar classes={{ spacer: classes.spacer }}>
						<ToolbarContainer maxWidth="md" className={classes.fullWidth}>
							<ArticleActions projectId={projectId} />
						</ToolbarContainer>
					</Toolbar>
				}
				save={this.handleSubmit}
			>
				<Container className={classes.root}>
					<ProjectID projectId={projectId} />
					<Barcodes values={barcodes} />
					<div className={`${classes.flexEnd} ${classes.fullWidth}`}>
						<Articles values={articles} className={classes.child} selected={selectedArticles} />
						{articles.length > 1 && (
							<Button
								label={
									selectedArticles.length === articles.length
										? 'ra.action.unselect_all'
										: 'ra.action.select_all'
								}
								variant="contained"
								color="primary"
								className={classes.button}
								onClick={this.handleToggleSelectArticles}
							>
								<CheckIcon />
							</Button>
						)}
					</div>
					<div className={classes.container}>
						{storageareaId !== storageArea && (
							<WarehousePlace
								classes={classes}
								source="prev_"
								permissions={permissions}
								article={article}
								resource={resource}
								warehouses={warehouses}
								storageareaId={storageareaId}
								projectArea={prevProjectArea}
							/>
						)}
						<WarehousePlace
							classes={classes}
							permissions={permissions}
							article={article}
							resource={resource}
							warehouses={warehouses}
							storageareaId={storageArea}
							projectArea={projectArea}
							onChangeStorageArea={this.handleChangeStorageArea}
							onChangeQrCodeStorageArea={this.handleChangeQrCodeStorageArea}
						/>
					</div>
					<div className={classes.container}>
						<Measurement
							source="measurements"
							className={classes.input}
							measure="m³"
							defaultValue={projectMeasurements}
							validate={required('projects', 'measurements')}
						/>
						<TextInput
							source="transfer_tools"
							label="resources.general.fields.transfer_tools"
							className={classes.input}
							defaultValue={projectArea ? projectArea.transfer_tools : null}
						/>
					</div>
				</Container>
			</SimpleForm>
		);
	}

	setDefaultState = async () => {
		const { dataProvider } = this.props;
		const {
			article: {
				project: projectId,
				storagearea: { id: storageAreaId }
			}
		} = this.state;

		const [
			{ data: barcodes },
			{
				data: { measurements: projectMeasurements }
			},
			prevProjectArea
		] = await Promise.all([
			dataProvider(GET_LIST, 'barcodes', {
				filter: {
					project: projectId,
					'article.status': 'IN_WAREHOUSE',
					'article.storagearea': storageAreaId,
					'article][isNotNull': 1
				},
				pagination: {
					perPage: 3000,
					page: 1
				}
			}),
			dataProvider(GET_ONE, 'projects', {
				id: projectId
			}),
			this.updateProjectArea(storageAreaId)
		]);

		if (this._isMounted) {
			this.setState({
				articles: uniq(barcodes.map(({ article_id }) => article_id))
					.sort()
					.map(articleId => ({
						id: articleId
					})),
				projectMeasurements,
				prevProjectArea
			});
		}
	};

	updateProjectArea = async storageAreaId => {
		const { dataProvider } = this.props;
		const {
			article: { project: projectId }
		} = this.state;

		const { data: projectAreas } = await dataProvider(GET_LIST, 'projectareas', {
			filter: {
				project: projectId,
				storageArea: storageAreaId
			}
		});
		const projectArea = projectAreas.length > 0 ? projectAreas[0] : undefined;

		if (this._isMounted) {
			this.setState({ projectArea });
		}

		return projectArea;
	};
}

const enhance = compose(withStyles(styles), withDataProvider, connect(null, { change }));

const ArticleMove = enhance(UnconnectedArticleMove);

const Index = ({ location, match }) => (
	<WithPermissions
		authParams={{ key: match.path, params: match.params }}
		// location is not required but it will trigger a new permissions check if specified when it changes
		location={location}
		render={({ permissions }) => <ArticleMove permissions={permissions} match={match} />}
	/>
);

export default Index;
