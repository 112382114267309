import { isEmpty } from 'lodash';

export default ({ address, user = {} }) => {
	const firstname = (!isEmpty(user) ? user.firstname : address.firstname) || '';
	const surname = (!isEmpty(user) ? user.surname : address.surname) || '';

	let fullName = `${firstname || ''} ${surname || ''}`.trim();

	return [
		(address.company && address.company + ', ') || '',
		fullName && fullName + ',',
		address.street || '',
		address.house_number + ',' || '',
		address.postal_code || '',
		address.city || ''
	]
		.filter(Boolean)
		.filter(String)
		.join(' ');
};
