import React from 'react';
import { BarcodeView, QRCodeField } from '../../../components';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';

const styles = {
	descriptionBarcode: {
		color: '#000',
		width: '210px !important',
		height: '210px !important',
		position: 'absolute',
		top: -22,
		left: -22,
		fontSize: 10,
		lineHeight: '10px',
		wordBreak: 'break-all',
		'& > div': {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			position: 'absolute',
			width: 190
		},
		'& .snake-0': {
			transform: 'rotate(-90deg)',
			transformOrigin: '0 100%',
			bottom: 20,
			left: 10
		},
		'& .snake-1': {
			transform: 'rotate(-90deg)',
			transformOrigin: '0 100%',
			bottom: 20,
			left: 20
		},
		'& .snake-2': {
			top: 0,
			left: 20
		},
		'& .snake-3': {
			top: 10,
			left: 20
		},
		'& .snake-4': {
			right: 0,
			bottom: -10,
			transform: 'rotate(90deg)',
			transformOrigin: '100% 0'
		},
		'& .snake-5': {
			right: 10,
			bottom: -10,
			transform: 'rotate(90deg)',
			transformOrigin: '100% 0'
		},
		'& .snake-6': {
			bottom: 0,
			left: 0,
			transform: 'rotate(180deg)'
		},
		'& .snake-7': {
			bottom: 10,
			left: 0,
			transform: 'rotate(180deg)'
		}
	},
	'@media print': {
		descriptionBarcode: {
			top: -20,
			left: -20,
			width: '155px !important',
			height: '155px !important',
			lineHeight: '10px',
			fontSize: 10,
			'& > div': {
				width: 134
			}
		}
	}
};

const RenderQRCode = ({ section, classes, openOnClick, rowIndex, columnIndex }) => {
	const path = section.full_name.split('->');
	path.splice(1, 2, rowIndex?.toString(), columnIndex?.toString());

	return (
		<QRCodeField
			source="value"
			size={32}
			record={{
				value: JSON.stringify({
					storagearea: section.id,
					name: section.name
				})
			}}
			openOnClick={openOnClick ?? true}
			description={
				<div className={classes.descriptionBarcode}>
					{path.map((el, ind) => (
						<div className={`snake-${ind}`} key={ind}>
							{el}
						</div>
					))}
				</div>
			}
			view={<BarcodeView size={248} />}
		/>
	);
};

const enhance = compose(withStyles(styles));

export default enhance(RenderQRCode);
