import React from 'react';
import { SimpleShowLayout } from 'react-admin';

import { AddressField, MobileGrid as BaseMobileGrid, UpdatedAtField } from '../components';
import { headerFullNameAndCompanyHelper } from '../helpers/headerNameHelpers';

const TitleMobileGrid = ({ record }) => (
	<span>{headerFullNameAndCompanyHelper(record.id, record.address, record.user)}</span>
);

const BodyMobileGrid = ({ record, className, ...props }) => (
	<SimpleShowLayout className={className} record={record} {...props}>
		<AddressField showName={false} />
		{record.invoice_address && <AddressField source="invoice_address" />}
		<UpdatedAtField />
	</SimpleShowLayout>
);

const MobileGrid = props => (
	<BaseMobileGrid {...props} title={<TitleMobileGrid />} body={<BodyMobileGrid />} />
);

export default MobileGrid;
