import React from 'react';
import { Badge, Menu, ListItemIcon, MenuItem, Typography } from '@material-ui/core';
import { ShoppingCart as ShoppingCartIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom';

const CartsList = ({ carts, anchorEl, close, click }) => {
	const user = JSON.parse(localStorage.getItem('user'));

	return (
		<Menu
			id="cart-dropdown"
			anchorEl={anchorEl}
			keepMounted
			open={Boolean(anchorEl)}
			onClose={close}
			transformOrigin={{ vertical: -47, horizontal: 45 }}
		>
			{Array.from(carts)
				.sort(item => (item[0] === user.id ? -1 : 0))
				.map((item, key) => (
					<MenuItem
						onClick={click}
						key={key}
						component={Link}
						to={user.id === item[0] ? '/shopping-cart' : `/shopping-cart/${item[0]}/show`}
					>
						<ListItemIcon>
							<Badge color="secondary" badgeContent={item[1].length}>
								<ShoppingCartIcon />
							</Badge>
						</ListItemIcon>
						<Typography>
							{`${item[1][0].reserved_by.firstname ?? ''} ${item[1][0].reserved_by.surname ??
								''}`.trim()}
						</Typography>
					</MenuItem>
				))}
		</Menu>
	);
};

export default CartsList;
