import React from 'react';
import { Route } from 'react-router-dom';

import ArticleMove from './articles/ArticleMove';
import { Configuration } from './configuration';
import ForgotPassword from './layout/ForgotPassword';
import RecoveryPassword from './layout/RecoveryPassword';
import ProfileEdit from './profile';
import Revert from './revert/Revert';

export default [
	<Route key="/profile" exact path="/profile" component={ProfileEdit} />,
	<Route key="/configuration" exact path="/configuration" component={Configuration} />,
	<Route key="/transfer/:articleId" exact path="/transfer/:articleId" component={ArticleMove} />,
	<Route key="/revert" exact path="/revert" component={Revert} />,
	<Route
		key="/forgot-password"
		exact
		path="/forgot-password"
		render={props => <ForgotPassword {...props} />}
		noLayout
	/>,
	<Route
		key="/recovery-password/:confirmation_token"
		exact
		noLayout
		path="/recovery-password/:confirmation_token"
		render={props => <RecoveryPassword {...props} />}
	/>
];
