import { Typography } from '@material-ui/core';
import React from 'react';
import { translate } from 'react-admin';

const ProjectID = ({ projectId, translate }) => (
	<Typography variant="title">
		{translate('resources.projects.name', { smart_count: 1 })} ID: {projectId}
	</Typography>
);

export default translate(ProjectID);
