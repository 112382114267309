import { isEmpty } from 'lodash';
import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { BooleanInput, SimpleForm, Toolbar, translate, REDUX_FORM_NAME } from 'react-admin';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { AddressCard, ToolbarContainer } from '../components';
import generalStyles from '../styles';
import { showAlertHelper } from '../helpers';

import UserCard from './UserCard';
import { reduxForm } from 'redux-form';
import httpClient from '../providers/httpClient';

const styles = theme => ({
	input: {
		marginTop: theme.spacing.unit * 2,
		[theme.breakpoints.up('xs')]: {
			marginTop: theme.spacing.unit * 3
		}
	},
	spacer: generalStyles(theme).spacer
});

class CustomerForm extends Component {
	constructor(props) {
		super(props);

		const { record } = this.props;

		this.state = {
			isExistsInvoiceAddress: !!record.invoice_address
		};
	}

	componentDidUpdate() {
		showAlertHelper.saveDefaultState(this.props.initial);
		showAlertHelper.saveCurrentState(this.props.current);
	}

	handleChangeDifferentInvoiceAddress = (customer, checked) => {
		this.setState({
			isExistsInvoiceAddress: checked
		});
	};

	handleSave = ({ different_invoice_address, ...customer }, redirect) => {
		const { save } = this.props;

		if (!different_invoice_address) {
			customer.invoice_address = null;
		}

		save(customer, redirect);
	};

	handleChangeRole = role => {
		this.setState({ role });
	};

	asyncValidation = value => {
		return new Promise((resolve, reject) => {
			if (this.localTimeout) clearTimeout(this.localTimeout);
			this.localTimeout = setTimeout(async () => {
				try {
					const response = (
						await httpClient(
							`${process.env.REACT_APP_API_URL}/users?filter[username]=${value.username}`
						)
					)?.json;
					if (!response) return;
					let isUsernameInResponse;
					if (this.props.record.id) {
						const filteredArray = response.filter(
							user => user.username.toLowerCase() !== this.props.record.username.toLowerCase()
						);
						isUsernameInResponse = filteredArray.some(
							user => user.username.toLowerCase() === value.username.toLowerCase()
						);
					} else {
						isUsernameInResponse = response?.some(
							user => user.username.toLowerCase() === value.username.toLowerCase()
						);
					}
					isUsernameInResponse
						? reject({ username: this.props.translate('ra.validation.is_valid_username') })
						: resolve();
				} catch (error) {
					console.error(error.message);
				}
			}, 1000);
		});
	};

	componentDidMount() {
		const { record: customer } = this.props;

		if (!isEmpty(customer)) {
			const {
				user: { role }
			} = customer;

			this.setState({ role });
		}
	}

	render() {
		// eslint-disable-next-line no-unused-vars
		const { classes, permissions, translate, record, ...props } = this.props;
		const { isExistsInvoiceAddress } = this.state;

		return (
			<SimpleForm
				{...props}
				redirect={record.id ? false : 'edit'}
				record={record}
				toolbar={
					<Toolbar classes={{ spacer: classes.spacer }}>
						<ToolbarContainer maxWidth="lg" />
					</Toolbar>
				}
				async={this.asyncValidation}
				asyncChangeFields={['username']}
				save={this.handleSave}
			>
				<UserCard
					source="user"
					cardClass={classes.input}
					onChangeRole={this.handleChangeRole}
					permissions={permissions}
				/>
				<AddressCard cardClass={classes.input} isShowName={false} />
				<BooleanInput
					source="different_invoice_address"
					className={classes.input}
					onChange={this.handleChangeDifferentInvoiceAddress}
					defaultValue={isExistsInvoiceAddress}
				/>
				{isExistsInvoiceAddress && (
					<AddressCard
						source="invoice_address"
						cardClass={classes.input}
						disabled={!isExistsInvoiceAddress}
					/>
				)}
			</SimpleForm>
		);
	}
}

const DecoratedForm = reduxForm({
	form: 'customer-form',
	destroyOnUnmount: false
});

const mapStateToProps = state => ({
	initial: state.form[REDUX_FORM_NAME]?.initial,
	current: state.form[REDUX_FORM_NAME]?.values
});

const enhance = compose(withStyles(styles), DecoratedForm, translate, connect(mapStateToProps));

export default enhance(CustomerForm);
