import { Typography, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { CREATE, UPDATE, translate, withDataProvider } from 'react-admin';
import { compose } from 'recompose';

import { getCurrentUser } from '../../helpers';
import httpClient from '../../providers/httpClient';
import { DELETE_COMMENT } from '../../providers/actions';
import { Tree } from '../tree';

import Comment from './Comment';
import CommentInput from './CommentInput';

const styles = theme => ({
	header: {
		marginBottom: theme.spacing.unit
	}
});

class CommentList extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		const {
			record: {
				thread: { id: threadId }
			}
		} = this.props;
		this.threadId = threadId;
		this.commentsUrl = `threads/${this.threadId}/comments`;
		this.currentUser = getCurrentUser();
	}

	componentDidMount() {
		this._isMounted = true;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	fetchComments = async () => {
		const {
			json: { comments }
		} = await httpClient(`${process.env.REACT_APP_API_URL}/${this.commentsUrl}`);

		return this.filterComments(comments);
	};

	filterComments = comments => {
		return comments
			.filter(({ comment: { state } }) => state < 1)
			.map(({ comment, children }) => ({
				comment,
				children: this.filterComments(children)
			}));
	};

	handleDeleteComment = async commentId => {
		if (!this._isMounted) {
			return null;
		}

		const { dataProvider } = this.props;
		return await dataProvider(DELETE_COMMENT, `${this.commentsUrl}/${commentId}/state`, {
			data: {
				fos_comment_delete_comment: {
					state: 1
				}
			}
		});
	};

	handleEditComment = async (commentId, body) => {
		if (!this._isMounted) {
			return null;
		}

		const { dataProvider } = this.props;
		const {
			data: { comment }
		} = await dataProvider(UPDATE, this.commentsUrl, {
			id: commentId,
			data: {
				fos_comment_comment: { body }
			}
		});

		return comment;
	};

	handleReplyComment = async (commentId, body) => {
		if (!this._isMounted) {
			return null;
		}

		const { dataProvider } = this.props;
		const {
			data: { comment }
		} = await dataProvider(CREATE, `${this.commentsUrl}?parentId=${commentId}`, {
			data: {
				fos_comment_comment: { body }
			}
		});

		return comment;
	};

	handleSendComment = async body => {
		if (!this._isMounted) {
			return null;
		}

		const { dataProvider } = this.props;
		const {
			data: { comment }
		} = await dataProvider(CREATE, this.commentsUrl, {
			data: {
				fos_comment_comment: { body }
			}
		});

		this.treeCommentsNode.addNode(comment);
	};

	render() {
		const { classes, className, translate } = this.props;

		return (
			<div className={className}>
				<Typography variant="title" className={classes.header}>
					{translate('comment.name', {
						smart_count: 2
					})}
				</Typography>

				<CommentInput onSend={this.handleSendComment} />

				<Tree
					ref={this.setTreeCommentsNode}
					source="comment"
					thread={this.threadId}
					fetchTree={this.fetchComments}
					fetchDelete={this.handleDeleteComment}
					fetchEdit={this.handleEditComment}
					fetchAdd={this.handleReplyComment}
				>
					<Comment currentUser={this.currentUser} />
				</Tree>
			</div>
		);
	}

	setTreeCommentsNode = treeCommentsNode => {
		this.treeCommentsNode = treeCommentsNode;
	};
}

const enhance = compose(withStyles(styles), withDataProvider, translate);

export default enhance(CommentList);
