import germanMessages from 'ra-language-german';
import { CARTON_TYPE, COLLECTION_TYPE, OBJECT_TYPE, PALLET_TYPE } from '../articles/articleTypes';

export default {
	...germanMessages,
	ra: {
		validation: {
			required: 'Das Feld "%{field}" darf nicht leer sein',
			required_of: 'Das Feld "% {field}" von "% {target}" darf nicht leer sein',
			password_confirmation:
				'Bitte überprüfen Sie, ob Sie Ihr Passwort eingegeben und bestätigt haben',
			required_for_files: 'Das Feld darf nicht leer sein',
			is_valid_password:
				'Mindestens 6 Zeichen, mindestens ein Großbuchstabe, ein Kleinbuchstabe und eine Ziffer.',
			is_valid_username: 'Dieser Benutzername existiert bereits.'
		},
		notification: {
			upload_file_error: 'Einige Dateien konnten nicht geladen werden',
			upload_file_length_error: 'Max. Anzahl der Dateien: 5',
			outsourced: 'Ausgelagert',
			removed: 'Entfernt',
			exchanged: 'Ausgetauscht',
			warning: 'Etwas ist schief gelaufen!',
			scanning: 'Ungültiger QR-Code. Bitte scanne einen gültigen Lagerort-QR-Code.',
			sectionCreationSuccess:
				'%{count} Unterabschnitt erstellt! |||| %{count} Unterabschnitte erstellt!',
			sectionCreationFailed:
				'%{count} Unterabschnitt fehlgeschlagen! |||| %{count} Unterabschnitte fehlgeschlagen!',
			sectionCreationPartiallySuccess:
				'%{successCount} Unterabschnitt erstellt, %{failedCount} Unterabschnitt fehlgeschlagen!'
		},
		chip: {
			deleted: 'Gelöscht'
		},
		action: {
			add_and_print: 'Hinzufügen und Drucken',
			add_subsection: 'Unterabschnitt hinzufügen',
			immovable_subsection: 'Unbeweglicher Abs.',
			movable_subsection: 'Beweglicher Abs.',
			add_color: 'Farbe hinzufügen',
			restore_password: 'Passwort wiederherstellen',
			print: 'Drucken',
			print_all: 'Alle drucken',
			reply: 'Antworten',
			send: 'Senden',
			print_selected: 'Auswahl drucken',
			delete_selected: 'Auswahl löschen',
			next: 'Weiter',
			done: 'Fertig',
			select_all: 'Alles auswählen',
			unselect_all: 'Alles abwählen',
			transfer: 'Umlagern',
			create: 'Neu',
			add: 'Hinzufügen',
			save: 'Speichern',
			list: 'Liste',
			delete: 'Löschen',
			confirm: 'OK',
			cancel: 'Abbrechen',
			edit: 'Bearbeiten',
			add_to_cart: 'In den Warenkorb',
			remove_from_button: 'Aus Warenkorb entfernen',
			deliver: 'Lieferung',
			deliver_all: 'Alle liefern',
			order: 'Bestellung',
			order_now: 'Jetzt Bestellen',
			order_all: 'Alle Bestellen',
			other: 'Manuelle Eingabe',
			choice_address: 'Wahl Adresse',
			delivery: 'Lieferung',
			disposal: 'Entsorgung',
			approve: 'Freigabe',
			outsource: 'Auslagern',
			revert: 'Rücknahme',
			pickup: 'Abholung',
			hide_articles: 'Artikel ausblenden',
			show_articles: 'Artikel anzeigen',
			restore: 'Wiederherstellen',
			group_by_sn: 'Gruppieren nach SN',
			upcoming: 'ausstehend',
			past: 'erledigt'
		},
		auth: {
			forgot_password: 'Passwort vergessen?',
			email: 'Email',
			username: 'Benutzername oder Email'
		},
		message: {
			to_list: 'Bist du sicher?',
			to_list_content:
				'Möchten Sie diese Seite wirklich verlassen? Sie verlieren alle nicht gespeicherten Daten.',
			error_add_barcode_to_article: 'Ein anderer Artikel hat diesen QR code.',
			remove_file: 'Möchten Sie diese Datei wirklich löschen?',
			cancel_content: 'Möchten Sie die %{name} stornieren?',
			cancel_title: '%{name} stornieren',
			equal_barcodes: 'Gescannte QR codes müssen mit den QR codes des Artikels übereinstimmen',
			restore_title: 'Wiederherstellen',
			restore_content: 'Möchten Sie diese %{name} wirklich wiederherstellen?',
			error_long_name: 'Lagerort Bezeichnung, max. 255 Zeichen',
			error_empty_name: 'Name darf nicht leer sein',
			loadingPrimary: 'Erstellt %{count}/%{countOfAll}...',
			deleteAvatarTitle: 'Foto löschen',
			deleteAvatarContent: 'Sind Sie sicher, dass Sie dieses Bild löschen möchten?'
		}
	},
	pos: {
		configuration: 'Einstellungen',
		profile: 'Profil',
		language: 'Sprache',
		default_country: 'Standard Land',
		add_address: 'Adresse hinzufügen',
		file: 'Datei',
		search: {
			title: 'Suche',
			container: 'Container',
			section: 'Unterreihe',
			customer: 'Kunde',
			comment: 'Kommentar',
			order_number: 'Bestellnummer/ Notiz'
		},
		theme: {
			name: 'Theme',
			light: 'Light',
			dark: 'Dark'
		},
		dashboard: {
			welcome: {
				title: 'Welcome to react-admin demo',
				subtitle:
					"This is the admin of an imaginary poster shop. Fell free to explore and modify the data - it's local to your computer, and will reset each time you reload.",
				aor_button: 'react-admin site',
				demo_button: 'Source for this demo'
			},
			storage_space: {
				title: 'Deine Lagerflächen',
				total_space: 'Gesamtfläche',
				free_space: 'Freifläche'
			},
			articles: {
				title: 'Artikel'
			},
			warehouses: {
				title: 'Lagerhäuser',
				total_area: 'Gesamtfläche',
				free_area: 'Freifläche'
			},
			upcomingDeliveries: {
				title: 'Bestellungen'
			}
		}
	},
	resources: {
		general: {
			fields: {
				name: 'Name',
				updated_at: 'Aktualisiert am',
				created_at: 'Erstellt am',
				search: 'Suche',
				transfer_tools: 'Transportgeräte'
			},
			data: {
				set_measurements: 'Fläche und Volumen definieren'
			}
		},
		customers: {
			name: 'Kunde |||| Kunden',
			fields: {
				address: 'Adresse',
				subsidiary: 'Tochtergesellschaft der',
				different_invoice_address: 'Abweichende Rechnungsanschrift',
				invoice_address: 'Rechnungsanschrift',
				name: 'Name',
				user: 'Benutzer'
			},
			page: {
				delete: 'Kunden löschen'
			},
			action: {
				add_loading_point: 'Neue Adresse hinzufügen',
				apply_customer_address: 'Kundenadresse übernehmen'
			}
		},
		address: {
			fields: {
				company: 'Unternehmen',
				firstname: 'Vorname',
				surname: 'Nachname',
				street: 'Straße',
				house_number: 'Hausnummer',
				postal_code: 'Postleitzahl',
				city: 'Stadt',
				province: 'Provinz',
				country: 'Land',
				additional_information: 'Zusätzliche Information'
			},
			placeholders: {
				company: 'Beispiel GmbH',
				firstname: 'John',
				surname: 'Doe',
				street: 'Beispielstraße',
				house_number: '1a',
				postal_code: '12345',
				city: 'Berlin',
				province: 'Berlin',
				additional_information: '2. Etage'
			}
		},
		projects: {
			name: 'Projekt |||| Projekte',
			fields: {
				customer: 'Kunde',
				loading_points: 'Ladestellen',
				unloading_points: 'Entladestellen',
				order_number: 'Bestellnummer/ Notiz',
				document_urls: 'Unterlagen',
				comment: 'Kommentar',
				articles_number: 'Artikelanzahl',
				barcodes: 'QR codes',
				unused_barcodes: 'Unbenutzte QR codes',
				used_barcodes: 'Verwendete QR codes',
				measurements: 'Maße',
				area: 'Fläche',
				warehouse_place_area: 'Letzter bearbeiteter Lagerort',
				total_area: 'Gesamtfläche',
				articles: 'Artikel',
				id: 'Projekt ID'
			},
			articleinfos: {
				of: 'von',
				scanned: 'gescannt'
			}
		},
		barcodes: {
			name: 'QR code |||| QR codes',
			fields: {
				project_id: 'Projekt ID',
				barcodes_number: 'QR code Anzahl'
			},
			errors: {
				delete_selected: 'Auswahl löschen'
			},
			action: {
				add_barcodes: 'QR codes hinzufügen'
			}
		},
		warehouses: {
			name: 'Lagerstruktur |||| Lagerstruktur',
			data: {
				structure: 'Struktur',
				name: 'Name'
			},
			action: {
				add_subsection: 'Unterabschnitt "%{name}" hinzufügen',
				edit: '"%{name}" Bearbeiten',
				view_comment: 'Kommentar für "%{name}"',
				add_comment: 'Kommentar hinzufügen für "%{name}"',
				moving_mode: 'Bewegender Modus',
				edit_mode: 'Bearbeitungsmodus'
			},
			fields: {
				name: 'Name des Lagerortes',
				movable_subsections: 'Movable Subsections'
			},
			tooltips: {
				add_new_row: 'Neue Zeile hinzufügen',
				add_new_column: 'Neue Spalte hinzufügen',
				add_new_container: 'Neuen Container hinzufügen'
			}
		},
		storageareas: {
			fields: {
				name: 'Name',
				quantity: 'Menge',
				area: 'Fläche',
				first: 'Beginnt bei',
				length: 'Tiefe',
				width: 'Breite',
				total_area: 'Gesamtfläche',
				filled_area: 'Belegte Fläche',
				free_area: 'Freie Fläche'
			}
		},
		users: {
			name: 'Benutzer |||| Benutzer',
			fields: {
				firstname: 'Vorname',
				phone_number: 'Telefonnummer',
				mobile_phone_number: 'Handynummer',
				surname: 'Nachname',
				gender: 'Geschlecht',
				language: 'Sprache',
				email: 'Email',
				username: 'Benutzername',
				role: 'Rolle',
				plain_password: 'Passwort',
				confirm_password: 'Passwort bestätigen',
				enabled: 'Aktiviert',
				moving_mode: 'Bewegender Modus',
				created_by: 'Erstellt von',
				agent: 'Agent',
				'agent.id': 'Agent'
			}
		},
		articles: {
			name: 'Artikel |||| Artikel', //to do
			check_free_space_error: 'Größer als freie Fläche',
			integer_error: 'Nur Ganzzahl',
			fields: {
				name: 'Beschreibung',
				photos: 'Fotos',
				photo: 'Foto',
				comments: 'Bemerkungen',
				type: 'Art',
				barcodes: 'QR codes',
				barcodes_ids: 'QR codes',
				other_type: 'Gegenstand',
				is_scratch: 'Vorschäden',
				colors: 'Farben',
				manufacturer: 'Hersteller',
				category: 'Kategorie',
				category_name: 'Kategorie',
				damages: 'Schäden',
				damages_photos: 'Schadenfotos',
				warehouse_place: 'Lagerort',
				width: 'Breite',
				height: 'Höhe',
				length: 'Tiefe',
				project: 'Projekt',
				serial_number: 'Serial Number'
			},
			action: {
				add_other_category: 'Kategorie hinzufügen',
				scan_barcode: 'QR-CODE SCANNEN'
			},
			data: {
				add_category: 'Kategorie hinzufügen',
				created_category: 'Kategorie erfolgreich erstellt'
			},
			validations: {
				count_photos: 'Sie müssen mindestens ein Foto hinzufügen.'
			},
			type: {
				[CARTON_TYPE]: 'Karton',
				[PALLET_TYPE]: 'Palette',
				[OBJECT_TYPE]: 'Gegenstand',
				[COLLECTION_TYPE]: 'Sammlung'
			}
		},
		'article-categories': {
			name: 'Artikelkategorie |||| Artikelkategorien',
			fields: {
				name: 'Name'
			}
		},
		agents: {
			name: 'Agent |||| Agenten',
			fields: {
				address: 'Adresse',
				user: 'Benutzer'
			}
		},
		project_areas: {
			fields: {
				area: 'Fläche'
			}
		},
		delivery: {
			fields: {
				address: 'Adresse',
				date: 'Datum',
				recipient: 'Empfänger',
				type: 'Typ'
			}
		},
		deliveries: {
			multiply_name: 'Lieferungen',
			name: 'Lieferung |||| Lieferungen',
			fields: {
				address: 'Adresse',
				date: 'Datum',
				surname: 'Nachname'
			},
			actions: {
				remove_article: 'Entfernen',
				exchange_article: 'Austausch'
			},
			messages: {
				remove_article: 'Artikel #%{articleId} aus Lieferung #%{deliveryId} entfernen',
				remove_article_content:
					'Möchten Sie den Artikel #%{articleId} wirklich aus der Lieferung #%{deliveryId} entfernen?',
				wrong_date_or_time: 'Sie haben ein falsches Datum/Uhrzeit eingestellt, bitte ändern Sie es.'
			}
		},
		outsourcing: {
			name: 'Auslagerung',
			fields: {
				recipient: 'Empfänger',
				address: 'Adresse',
				date: 'Datum',
				type: 'Typ'
			},
			type: {
				pickup: 'Abholung',
				delivery: 'Lieferung',
				disposal: 'Entsorgung'
			},
			no_results: 'Keine Ergebnisse gefunden',
			just_scanned: 'Neulich gescannte Artikel'
		},
		shopping_cart: {
			name: 'Einkaufswagen'
		},
		acceptance: {
			fields: {
				scanned_barcodes: 'Gescannte QR codes',
				articles: 'Artikel'
			}
		}
	},
	resetting: {
		check_email:
			'Eine E-Mail wurde gesendet. Es enthält einen Link, auf den Sie klicken müssen, um Ihr Passwort zurückzusetzen. ' +
			'Sie können ein neues Passwort nur einmal innerhalb von% {token_lifetime} anfordern. ' +
			'Wenn Sie keine E-Mail erhalten, überprüfen Sie Ihren Spam-Ordner oder versuchen Sie es erneut.'
	},
	qr_reader: {
		open: 'QR-CODE SCANNEN',
		title: 'QR-Code-Leser',
		webcam_not_supported: 'Webcam wird nicht unterstützt',
		submit_an_image: 'Senden Sie ein Bild',
		decoded: 'Entschlüsselter QR-Code'
	},
	comment: {
		name: 'Kommentar |||| Bemerkungen',
		action: {
			send: 'Kommentar senden'
		}
	},
	barcode: {
		value: 'Wert'
	},
	time: {
		second: 'zwei |||| sekunden',
		minute: 'minute |||| protokoll',
		hour: 'stunde |||| std',
		day: 'Tag |||| Tage',
		week: 'Woche |||| Wochen',
		month: 'Monat |||| Monate',
		year: 'Jahr |||| Jahre'
	},
	export: {
		from: 'Export aus',
		to: 'zu',
		head: 'Exportieren Sie die Projektdatensätze'
	},
	countries: {
		AF: 'Afghanistan',
		AX: '\u00c5land Inseln',
		AL: 'Albanien',
		DZ: 'Algerien',
		AS: 'Amerikanisch Samoa',
		AD: 'Andorra',
		AO: 'Angola',
		AI: 'Anguilla',
		AQ: 'Antarktis',
		AG: 'Antigua and Barbuda',
		AR: 'Argentina',
		AM: 'Armenien',
		AW: 'Aruba',
		AU: 'Australien',
		AT: '\u00d6sterreich',
		AZ: 'Azerbaijan',
		BS: 'Bahamas',
		BH: 'Bahrain',
		BD: 'Bangladesh',
		BB: 'Barbados',
		BY: 'Wei\u00dfrussland',
		BE: 'Belgien',
		BZ: 'Belize',
		BJ: 'Benin',
		BM: 'Bermuda',
		BT: 'Bhutan',
		BO: 'Bolivien',
		BQ: 'Bonaire, Sint Eustatius and Saba',
		BA: 'Bosnien und Herzegovina',
		BW: 'Botswana',
		BV: 'Bouvet Inseln',
		BR: 'Brasilien',
		IO: 'British Indian Ocean Territory',
		BN: 'Brunei Darussalam',
		BG: 'Bulgarien',
		BF: 'Burkina Faso',
		BI: 'Burundi',
		KH: 'Cambodia',
		CM: 'Kamerun',
		CA: 'Kanada',
		CV: 'Cape Verde',
		KY: 'Cayman Inseln',
		CF: 'Zentral Afrikanische Republik',
		TD: 'Chad',
		CL: 'Chile',
		CN: 'China',
		CX: 'Weihnachtsinseln',
		CC: 'Cocos (keeling) Islands',
		CO: 'Kolumbien',
		KM: 'Comoros',
		CG: 'Kongo',
		CD: 'Demokratische Republik Kongo',
		CK: 'Cook Islands',
		CR: 'Costa Rica',
		CI: 'Elfenbeink\u00fcste',
		HR: 'Kroatien',
		CU: 'Kuba',
		CW: 'Curaçao',
		CY: 'Zypern',
		CZ: 'Tschechische Republik',
		DK: 'D\u00e4nemark',
		DJ: 'Djibouti',
		DM: 'Dominica',
		DO: 'Dominikanische Republik',
		EC: 'Ecuador',
		EG: '\u00c4gypten',
		SV: 'El Salvador',
		GQ: 'Equatorial Guinea',
		ER: 'Eritrea',
		EE: 'Estland',
		ET: 'Ethiopia',
		FK: 'Falkland Inseln',
		FO: 'F\u00e4r\u00f6er Inseln',
		FJ: 'Fiji',
		FI: 'Finnland',
		FR: 'Frankreich',
		GF: 'Franz\u00f6sisch Guiana',
		PF: 'Franz\u00f6sisch Polynesien',
		TF: 'Franz\u00f6sisch Southern Territories',
		GA: 'Gabon',
		GM: 'Gambia',
		GE: 'Georgien',
		DE: 'Deutschland',
		GH: 'Ghana',
		GI: 'Gibraltar',
		GR: 'Griechenland',
		GL: 'Gr\u00f6nland',
		GD: 'Grenada',
		GP: 'Guadeloupe',
		GU: 'Guam',
		GT: 'Guatemala',
		GG: 'Guernsey',
		GN: 'Guinea',
		GW: 'Guinea-bissau',
		GY: 'Guyana',
		HT: 'Haiti',
		HM: 'Heard Island and Mcdonald Islands',
		VA: 'Holy See (vatican City State)',
		HN: 'Honduras',
		HK: 'Hong Kong',
		HU: 'Ungarn',
		IS: 'Island',
		IN: 'Indien',
		ID: 'Indonesien',
		IR: 'Iran',
		IQ: 'Irak',
		IE: 'Irland',
		IM: 'Isle of Man',
		IL: 'Israel',
		IT: 'Italien',
		JM: 'Jamaika',
		JP: 'Japan',
		JE: 'Jersey',
		JO: 'Jordan',
		KZ: 'Kasachstan',
		KE: 'Kenia',
		KI: 'Kiribati',
		KP: "Korea, Democratic People's Republic of",
		KR: 'Korea, Republic of',
		KW: 'Kuwait',
		KG: 'Kyrgyzstan',
		LA: "Lao People's Democratic Republic",
		LV: 'Lettland',
		LB: 'Lebanon',
		LS: 'Lesotho',
		LR: 'Liberia',
		LY: 'Lybien',
		LI: 'Liechtenstein',
		LT: 'Lithuania',
		LU: 'Luxembourg',
		MO: 'Macao',
		MK: 'Mazedonien',
		MG: 'Madagaskar',
		MW: 'Malawi',
		MY: 'Malaysien',
		MV: 'Malediven',
		ML: 'Mali',
		MT: 'Malta',
		MH: 'Marshall Inseln',
		MQ: 'Martinique',
		MR: 'Mauritanien',
		MU: 'Mauritius',
		YT: 'Mayotte',
		MX: 'Mexiko',
		FM: 'Micronesien',
		MD: 'Moldavien',
		MC: 'Monakko',
		MN: 'Mongolei',
		ME: 'Montenegro',
		MS: 'Montserrat',
		MA: 'Morocco',
		MZ: 'Mozambique',
		MM: 'Myanmar',
		NA: 'Namibien',
		NR: 'Nauru',
		NP: 'Nepal',
		NL: 'Niederlande',
		NC: 'Neu Kaledonien',
		NZ: 'Neuseeland',
		NI: 'Nicaragua',
		NE: 'Niger',
		NG: 'Nigerien',
		NU: 'Niue',
		NF: 'Norfolk Inseln',
		MP: 'Northern Mariana Islands',
		NO: 'Norwegen',
		OM: 'Oman',
		PK: 'Pakistan',
		PW: 'Palau',
		PS: 'Palestine, State of',
		PA: 'Panama',
		PG: 'Papua New Guinea',
		PY: 'Paraguay',
		PE: 'Peru',
		PH: 'Philippinen',
		PN: 'Pitcairn',
		PL: 'Polen',
		PT: 'Portugal',
		PR: 'Puerto Rico',
		QA: 'Qatar',
		RE: 'Réunion',
		RO: 'Rum\u00e4nien',
		RU: 'Russland',
		RW: 'Ruanda',
		BL: 'Saint Barthélemy',
		SH: 'Saint Helena, Ascension and Tristan Da Cunha',
		KN: 'Saint Kitts and Nevis',
		LC: 'Saint Lucia',
		MF: 'Saint Martin (French Part)',
		PM: 'Saint Pierre and Miquelon',
		VC: 'Saint Vincent and The Grenadines',
		WS: 'Samoa',
		SM: 'San Marino',
		ST: 'Sao Tome and Principe',
		SA: 'Saudi Arabien',
		SN: 'Senegal',
		RS: 'Serbien',
		SC: 'Seychellen',
		SL: 'Sierra Leone',
		SG: 'Singapur',
		SX: 'Sint Maarten (Dutch Part)',
		SK: 'Slowakei',
		SI: 'Slowenien',
		SB: 'Solomon Inseln',
		SO: 'Somalien',
		ZA: 'S\u00fcdafrika',
		GS: 'South Georgia and The South Sandwich Islands',
		SS: 'S\u00fcdsudan',
		ES: 'Spanien',
		LK: 'Sri Lanka',
		SD: 'Sudan',
		SR: 'Suriname',
		SJ: 'Svalbard and Jan Mayen',
		SZ: 'Swaziland',
		SE: 'Schweden',
		CH: 'Schweiz',
		SY: 'Syrien',
		TW: 'Taiwan',
		TJ: 'Tajikistan',
		TZ: 'Tanzania, United Republic of',
		TH: 'Thailand',
		TL: 'Timor-leste',
		TG: 'Togo',
		TK: 'Tokelau',
		TO: 'Tonga',
		TT: 'Trinidad und Tobago',
		TN: 'Tunesien',
		TR: 'T\u00fcrkei',
		TM: 'Turkmenistan',
		TC: 'Turks and Caicos Islands',
		TV: 'Tuvalu',
		UG: 'Uganda',
		UA: 'Ukraine',
		AE: 'Vereinigte Arabische Emirate',
		GB: 'Vereinigtes K\u00f6nigreich',
		US: 'Vereinigte Staaten',
		UM: 'United States Minor Outlying Islands',
		UY: 'Uruguay',
		UZ: 'Uzbekistan',
		VU: 'Vanuatu',
		VE: 'Venezuela',
		VN: 'Vietnam',
		VG: 'Virgin Islands, British',
		VI: 'Virgin Islands, U.s.',
		WF: 'Wallis and Futuna',
		EH: 'Westsahara',
		YE: 'Yemen',
		ZM: 'Zambia',
		ZW: 'Zimbabwe'
	}
};
