import { TextField } from '@material-ui/core';
import React from 'react';

const Input = ({ meta: { touched, error } = {}, input: { ...inputProps }, ...props }) => (
	<TextField
		error={!!(touched && error)}
		helperText={touched && error}
		{...inputProps}
		{...props}
		fullWidth
	/>
);

export default Input;
