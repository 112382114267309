import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	ReferenceField,
	TextField,
	UPDATE,
	withDataProvider,
	CREATE,
	TextInput
} from 'react-admin';
import { compose } from 'recompose';

import { DialogForm, Measurement } from '../components';
import { required } from '../validations';

const StorageArea = ({ className, storageArea, resource, source }) => (
	<ReferenceField
		source={source}
		resource={resource}
		basePath="/storageareas"
		reference="storageareas"
		linkType={false}
		record={{
			[source]: storageArea
		}}
		className={className}
	>
		<TextField source="name" />
	</ReferenceField>
);

StorageArea.defaultProps = {
	addLabel: true
};

const styles = {
	fieldWarning: {
		color: 'red'
	},
	measurementsField: {
		width: '100%'
	},
	warehousePlaceArea: {
		borderBottom: '1px solid #0000006b'
	}
};

class DoneDialog extends Component {
	state = {
		area: null
	};

	_isMounted = false;

	componentDidMount() {
		this._isMounted = true;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleChangeArea = area => {
		this.setState({ area });
	};

	handleSubmit = async ({ area, measurements, transfer_tools }) => {
		const { projectId, dataProvider, projectAreas, storageArea, onAdd } = this.props;
		const foundProjectArea = projectAreas && projectAreas.length > 0 ? projectAreas[0] : undefined;

		if (this._isMounted) {
			const [{ data: project }, { data: projectArea }] = await Promise.all([
				dataProvider(UPDATE, 'projects', {
					id: projectId,
					data: {
						measurements,
						id: projectId
					}
				}),
				dataProvider(foundProjectArea ? UPDATE : CREATE, 'projectareas', {
					id: foundProjectArea && foundProjectArea.id,
					data: {
						area,
						transfer_tools,
						id: foundProjectArea && foundProjectArea.id,
						project: {
							id: projectId
						},
						storage_area: {
							id: storageArea
						}
					}
				})
			]);

			if (onAdd) onAdd(project, projectArea);
		}
	};

	render() {
		const {
			classes,
			projectId,
			showDialog,
			storageArea,
			onClose,
			projectAreas,
			projectMeasurements,
			totalArea,
			filledInOtherProjects,
			filledInStorageArea,
			filledInCurrentProject
		} = this.props;

		const areaFilled =
			this.state.area !== null ? filledInOtherProjects + this.state.area : filledInStorageArea;

		return (
			<DialogForm
				showDialog={showDialog}
				title="resources.general.data.set_measurements"
				titleParams={{
					project_id: projectId
				}}
				onSubmit={this.handleSubmit}
				onClose={onClose}
				size="xs"
				resource="projects"
				form="set-measurements-to-project"
			>
				<StorageArea
					source="warehouse_place_area"
					storageArea={storageArea}
					className={`${classes.measurementsField} ${classes.warehousePlaceArea}`}
				/>
				<Measurement
					source="area"
					className={`${classes.measurementsField}`}
					inputClasses={areaFilled > totalArea ? `${classes.fieldWarning}` : ''}
					measure="m²"
					label={`Area (${areaFilled} of ${totalArea})`}
					defaultValue={filledInCurrentProject}
					validate={required('projects', 'area')}
					onChangeHandler={this.handleChangeArea}
				/>
				<Measurement
					source="measurements"
					className={classes.measurementsField}
					measure="m³"
					defaultValue={projectMeasurements}
					validate={required('projects', 'measurements')}
				/>
				<TextInput
					source="transfer_tools"
					label="resources.general.fields.transfer_tools"
					defaultValue={
						projectAreas && projectAreas.length > 0 ? projectAreas[0].transfer_tools : undefined
					}
					className={classes.measurementsField}
				/>
			</DialogForm>
		);
	}
}

DoneDialog.propTypes = {
	projectId: PropTypes.number,
	filledInCurrentProject: PropTypes.number,
	filledInOtherProjects: PropTypes.number,
	filledInStorageArea: PropTypes.number,
	onClose: PropTypes.func,
	projectMeasurements: PropTypes.number,
	storageArea: PropTypes.number,
	totalArea: PropTypes.number,
	projectAreas: PropTypes.arrayOf(PropTypes.object),
	showDialog: PropTypes.bool.isRequired,

	// from withDataProvider
	dataProvider: PropTypes.func.isRequired,
	dispatch: PropTypes.func.isRequired,

	// from withStyles
	classes: PropTypes.objectOf(PropTypes.string).isRequired
};

const enhance = compose(withStyles(styles), withDataProvider);

export default enhance(DoneDialog);
