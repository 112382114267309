import React from 'react';
import { CardActions } from 'react-admin';

import ArticleList from './ArticleList';
import DeliveryButton from './DeliveryButton';
import RemoveFromCartButton from './RemoveFromCartButton';
import { IN_CART } from '../articles/statuses';

const ShoppingCartBulkActionButtons = props => (
	<>
		<DeliveryButton {...props} />
		<RemoveFromCartButton {...props} />
	</>
);

const ShoppingCartActions = props => (
	<CardActions>
		<DeliveryButton {...props} deliverAll />
	</CardActions>
);

const ShoppingCartShow = props => (
	<ArticleList
		{...props}
		title="resources.shopping_cart.name"
		filter={{
			'status][in][': IN_CART,
			...(props.id ? { 'reservedBy][in][': props.id } : {})
		}}
		filters={{
			q: true
		}}
		resource="articles"
		bulkActionButtons={<ShoppingCartBulkActionButtons userId={props.id} />}
		customActions={<ShoppingCartBulkActionButtons userId={props.id} />}
		actions={<ShoppingCartActions userId={props.id} />}
	/>
);

export default ShoppingCartShow;
