import { keyBy } from 'lodash';
import { CircularProgress, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import ArticleDatagrid from '../articles/ArticleDatagrid';
import generalStyles from '../styles';
import RemoveArticleButton from './RemoveArticleButton';
import { GET_LIST, Pagination, withDataProvider } from 'react-admin';
import { compose } from 'recompose';

const styles = theme => {
	const { nowrap } = generalStyles(theme);

	return {
		nowrap,
		lastLine: {
			'& > tbody > tr:last-child > td': {
				border: 'none'
			}
		}
	};
};

const ExpandDelivery = ({ classes, permissions, basePath, record, dataProvider }) => {
	const [articles, setArticles] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [totalCount, setTotalCount] = useState(0);
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);

	useEffect(() => {
		setIsLoading(true);
		dataProvider(GET_LIST, 'articles', {
			filter: {
				delivery: record.id
			},
			pagination: { page, perPage }
		})
			.then(data => {
				if (data && data.total) {
					setTotalCount(data.total);
				}
				if (!data?.data) return;
				record.articles = data.data;
				setArticles(data.data);
			})
			.catch(error => {
				console.error(error);
			})
			.finally(() => {
				setIsLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, perPage]);

	if (isLoading) {
		return (
			<div style={{ width: '40px', margin: '20px auto' }}>
				<CircularProgress size={40} />
			</div>
		);
	}

	return (
		<>
			{totalCount > 0 && (
				<Pagination
					page={page}
					perPage={perPage}
					setPage={setPage}
					setPerPage={setPerPage}
					total={totalCount}
					rowsPerPageOptions={[5, 10, 25, 100, 250, 500]}
				/>
			)}
			<ArticleDatagrid
				ids={articles.map(({ id }) => id) ?? []}
				data={keyBy(articles, 'id')}
				currentSort={{
					field: 'id',
					order: 'DESC'
				}}
				isSortingBanned={true}
				basePath={basePath}
				permissions={permissions}
				className={classes.lastLine}
				deliveryPage={true}
				customActions={<RemoveArticleButton className={classes.nowrap} deliveryId={record.id} />}
			/>
			{totalCount > 0 && (
				<Pagination
					page={page}
					perPage={perPage}
					setPage={setPage}
					setPerPage={setPerPage}
					total={totalCount}
					rowsPerPageOptions={[5, 10, 25, 100, 250, 500]}
				/>
			)}
		</>
	);
};

const enhance = compose(withStyles(styles), withDataProvider);

export default enhance(ExpandDelivery);
