import { ViewQuilt as ArticleIcon } from '@material-ui/icons';
import React, { Component, Fragment } from 'react';
import { Button, SimpleShowLayout, DateField } from 'react-admin';
import { ROLE_AGENT } from '../users/roles';
import OutsourceButton from './OutsourceButton';

import { AddressField, FullNameField, MobileGrid as BaseMobileGrid } from '../components';
import TypeField from './TypeField';
import DeliveryArticle from './DeliveryArticle';

const TitleMobileGrid = ({ record }) => (
	<span>
		#{record.id}. {record.address ? <FullNameField record={record.address} /> : record.surname}
	</span>
);

const BodyMobileGrid = ({
	record,
	className,
	isShownArticles,
	permissions,
	exchange,
	handleConfirmRemove,
	scannedBarcodes,
	deliveryList,
	...props
}) => {
	const articles = deliveryList.find(delivery => delivery.id === record.id)?.articles ?? [];

	return (
		<SimpleShowLayout className={className} record={record} permissions={permissions} {...props}>
			<AddressField showName={false} label="resources.outsourcing.fields.recipient" />
			<TypeField sortBy="address" label="resources.outsourcing.fields.type" sortable={false} />
			<DateField
				showTime={true}
				source="date"
				label="resources.outsourcing.fields.date"
				sortable={false}
			/>
			{isShownArticles &&
				articles.map(article => (
					<DeliveryArticle
						key={article.id}
						deliveryId={record.id}
						article={article}
						permissions={permissions}
						exchange={exchange}
						handleConfirmRemove={handleConfirmRemove}
						scannedBarcodes={scannedBarcodes}
					/>
				))}
		</SimpleShowLayout>
	);
};

class DeliveryActions extends Component {
	handleToggleArticles = async () => {
		const { isShownArticles, setState, deliveryList, record, fetchArticlesToDelivery } = this.props;

		const articles = deliveryList.find(delivery => delivery.id === record.id)?.articles;

		if (!articles && !isShownArticles) {
			await fetchArticlesToDelivery(record);
		}

		setState({
			isShownArticles: !isShownArticles
		});
	};

	render() {
		const {
			isShownArticles,
			scannedBarcodes,
			handleOutsource,
			record,
			deliveryList,
			permissions,
			isOutsourcingButtonShown,
			fetchArticlesToDelivery
		} = this.props;
		return (
			<Fragment>
				{(isOutsourcingButtonShown || permissions === ROLE_AGENT) && (
					<OutsourceButton
						scannedBarcodes={scannedBarcodes}
						record={record}
						handleOutsource={handleOutsource}
						deliveryList={deliveryList}
						permissions={permissions}
						fetchArticlesToDelivery={fetchArticlesToDelivery}
					/>
				)}
				<Button
					label={isShownArticles ? 'ra.action.hide_articles' : 'ra.action.show_articles'}
					onClick={this.handleToggleArticles}
				>
					<ArticleIcon />
				</Button>
			</Fragment>
		);
	}
}

const MobileGrid = props => (
	<BaseMobileGrid
		{...props}
		title={<TitleMobileGrid />}
		body={<BodyMobileGrid {...props} />}
		deletable={false}
		editable={false}
		customButtons={<DeliveryActions {...props} />}
	/>
);

export default MobileGrid;
