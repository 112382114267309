import { connect } from 'react-redux';

import { RECOVERY_PASSWORD } from './actions';

const mapDispatchToProps = dispatch => ({
	recoveryPasswordProvider: (type, payload) =>
		new Promise(function(resolve, reject) {
			const action = {
				type: RECOVERY_PASSWORD,
				payload: payload,
				meta: {
					fetch: type,
					onSuccess: url => resolve(url),
					onFailure: ({ message }) => reject(message)
				}
			};

			return dispatch(action);
		}),
	dispatch: dispatch
});

const mapStateToProps = state => {
	return { isLoading: state.admin.loading > 0 };
};

export default Component => {
	return connect(mapStateToProps, mapDispatchToProps)(Component);
};
