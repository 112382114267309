import React, { useEffect } from 'react';
import { useContainerHallMode } from '../context';
import checkAndSetHeight from '../helpers/setButtonAndColumnsHeight';
import { compose } from 'recompose';
import { Typography, withStyles } from '@material-ui/core';
import RenderQRCode from './RenderQRCode';
import { useSearch } from '../../searchContext';

const styles = {
	container: {
		minHeight: '75px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		border: '2px solid black',
		borderRadius: '10px',
		margin: '5px 0',
		backgroundColor: 'white',
		cursor: 'pointer',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		'& button': {
			padding: 0,
			minWidth: 0,
			marginBottom: '5px'
		}
	},
	selectedContainer: {
		minHeight: '75px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		border: '2px solid #4eabfc',
		borderRadius: '10px',
		margin: '5px 0',
		backgroundColor: 'white',
		color: '#4eabfc',
		cursor: 'pointer',
		'& button': {
			padding: 0,
			minWidth: 0,
			marginBottom: '5px'
		}
	},
	searchedContainer: {
		background: 'yellow'
	}
};

const QR_CODE_ELEMENT = 'SPAN';

const Containers = ({ children, classes, isEditMode, rowIndex, columnIndex }) => {
	const {
		isContainerChosen,
		selectedContainerId,
		chooseContainer,
		isContainerHallMovingMode,
		setSectionIdForNotes
	} = useContainerHallMode();

	const { idsSearch } = useSearch();

	useEffect(() => {
		checkAndSetHeight(isEditMode);
	}, [children.length]);

	const getClassName = id => {
		if (isContainerChosen && selectedContainerId === id) {
			return classes.selectedContainer;
		}
		if (idsSearch?.includes(id)) {
			return [classes.searchedContainer, classes.container].join(' ');
		}
		return classes.container;
	};

	const handleClick = (event, child, index) => {
		if (isContainerHallMovingMode && index === 0) {
			chooseContainer(child);
			return;
		}
		// This condition was added for cases, when you click on qr-code in container. We don't need to open modal with note in this case
		if (event.target.nodeName !== QR_CODE_ELEMENT) {
			setSectionIdForNotes(child.id);
		}
	};

	return children.map((child, index) => {
		return (
			<div
				key={child.id}
				data-container
				onClick={event => handleClick(event, child, index)}
				className={getClassName(child.id)}
			>
				<RenderQRCode
					openOnClick={!isContainerHallMovingMode}
					section={child}
					columnIndex={columnIndex}
					rowIndex={rowIndex}
				/>
				<Typography>{child.name}</Typography>
			</div>
		);
	});
};

const enhance = compose(withStyles(styles));

export default enhance(Containers);
