import ProjectIcon from '@material-ui/icons/Folder';

import ProjectList from './ProjectList';
import ProjectCreate from './ProjectCreate';
import ProjectEdit from './ProjectEdit';
import ProjectShow from './ProjectShow';

export default {
	list: ProjectList,
	create: ProjectCreate,
	edit: ProjectEdit,
	show: ProjectShow,
	icon: ProjectIcon
};
