import { Card, CardContent, CardHeader, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { translate } from 'react-admin';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { marginGutters } from '../helpers';

const hasNumberFormat = !!(
	typeof Intl === 'object' &&
	Intl &&
	typeof Intl.NumberFormat === 'function'
);

const optionsNumberFormat = {
	minimumFractionDigits: 2,
	maximumFractionDigits: 2
};

const styles = theme => {
	const margin = marginGutters(theme);
	const smBreakpoint = theme.breakpoints.up('sm');

	return {
		root: {
			display: 'inline-flex',
			flexDirection: 'column'
		},
		container: {
			display: 'inline-flex',
			flexWrap: 'wrap',
			paddingBottom: 0,
			paddingLeft: 0,
			paddingRight: 0,
			'&:last-child': {
				paddingBottom: 0
			}
		},
		column: {
			...margin,
			minWidth: 50,
			whiteSpace: 'nowrap',
			marginBottom: theme.spacing.unit * 2,
			[smBreakpoint]: {
				...margin[smBreakpoint],
				marginBottom: theme.spacing.unit * 3
			}
		}
	};
};

const UnconnectedStorageSpace = ({ classes, className, translate, locale, total, free }) => (
	<Card className={`${classes.root} ${className}`}>
		<CardHeader title={translate('pos.dashboard.storage_space.title')} />
		<CardContent className={classes.container}>
			<div className={classes.column}>
				<Typography variant="display2">
					{hasNumberFormat ? total.toLocaleString(locale, optionsNumberFormat) : total} m²
				</Typography>
				<Typography variant="body2">
					{translate('pos.dashboard.storage_space.total_space')}
				</Typography>
			</div>
			<div className={classes.column}>
				<Typography variant="display2">
					{hasNumberFormat ? free.toLocaleString(locale, optionsNumberFormat) : total} m²
				</Typography>
				<Typography variant="body2">
					{translate('pos.dashboard.storage_space.free_space')}
				</Typography>
			</div>
		</CardContent>
	</Card>
);

const mapStateToProps = state => ({
	locale: state.i18n.locale
});

const enhance = compose(withStyles(styles), translate, connect(mapStateToProps, null));

const StorageSpace = enhance(UnconnectedStorageSpace);

StorageSpace.defaultProps = {
	total: 0,
	free: 0
};

export default StorageSpace;
