import { IconButton, Tooltip, withStyles } from '@material-ui/core';
import { Button, CREATE, DELETE, translate, withDataProvider } from 'react-admin';
import { Add, Close } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { DialogForm } from '../../../components';
import { formUniqueKey } from '../../../helpers';
import FieldsAddSection from '../../FieldsAddSection';
import RenderQRCode from './RenderQRCode';
import EmptyContainer from './EmptyContainer';
import Containers from './Containers';
import checkAndSetHeight from '../helpers/setButtonAndColumnsHeight';
import { compose } from 'recompose';
import { useContainerHallMode } from '../context';
import findStorage from '../helpers/findStorage';
import { addStorageArea, removeStorageArea } from '../../helpers';
import { useSections } from '../../sectionsContext';

const styles = {
	columnWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: '0 20px',
		minWidth: '150px',
		width: '100%'
	},
	column: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '160px',
		backgroundColor: '#eae9e9',
		borderRadius: '10px',
		position: 'relative',
		marginTop: '20px',
		padding: '10px 0'
	},
	deleteColumnButton: {
		position: 'absolute',
		right: '10px',
		border: '1px solid',
		width: '25px',
		height: '25px'
	},
	containersWrapper: {
		width: '90%'
	},
	addContainerButtonWrapper: {
		width: '100%',
		height: '40px'
	},
	addContainerButton: {
		width: '100%',
		padding: '10px',
		backgroundColor: '#eae9e9',
		borderRadius: '10px',
		color: 'black',
		'&:hover': {
			backgroundColor: '#4eabfc'
		}
	}
};

const Columns = ({ children, classes, dataProvider, translate, isEditMode, rowIndex }) => {
	const { sections, updateSections } = useSections();

	const { setChosenSubsection, chosenSubsection } = useContainerHallMode();

	useEffect(() => {
		checkAndSetHeight(isEditMode);
	}, [children.length]);

	const handleAddNewContainer = child => {
		setChosenSubsection(child);
	};

	const handleDeleteEmptyColumn = async id => {
		try {
			const { data: deletedSection } = await dataProvider(DELETE, 'storageareas', {
				id: id
			});
			if (deletedSection) {
				const currentRow = findStorage({ children: sections }, deletedSection.parent_id);

				if (currentRow.children.length < 2) {
					await dataProvider(DELETE, 'storageareas', {
						id: currentRow.id
					});
					removeStorageArea(sections, currentRow.id);
				} else {
					removeStorageArea(sections, id);
				}
				updateSections();
			}
		} catch (error) {
			console.error('[warehouse]: deleting section failed', error);
		}
	};

	const handleConfirmDialog = async content => {
		const { area, quantity, first, name } = content;

		try {
			for (let i = first; i < quantity + first; i++) {
				const singleSectionName = quantity > 1 ? name.concat(' ' + i) : name;
				const changedName = name.indexOf('#') > -1 ? name.replace('#', i) : singleSectionName;
				const { data: createdContainer } = await dataProvider(CREATE, 'storageareas', {
					id: chosenSubsection?.id,
					data: {
						area,
						name: changedName,
						movable: true,
						storage_order: chosenSubsection?.children.length + 1,
						parent: {
							id: chosenSubsection?.id
						}
					}
				});
				addStorageArea(sections, createdContainer);
				updateSections();
			}
		} catch (error) {
			console.error('[warehouse]: subsection creation failed', error);
		}
	};

	const handleDialogClose = () => {
		setChosenSubsection(null);
	};
	return children.map((child, index) => {
		const reversedChildren = child.children.slice().reverse();
		return (
			<div key={child.id} className={classes.columnWrapper}>
				{isEditMode && (
					<Tooltip title={translate('resources.warehouses.tooltips.add_new_container')}>
						<span className={classes.addContainerButtonWrapper}>
							<Button
								aria-label="Add"
								className={classes.addContainerButton}
								onClick={() => handleAddNewContainer(child)}
							>
								<Add />
							</Button>
						</span>
					</Tooltip>
				)}
				<div data-column className={classes.column}>
					{!child.children.length && isEditMode && (
						<IconButton
							className={classes.deleteColumnButton}
							aria-label="Delete"
							onClick={() => handleDeleteEmptyColumn(child.id)}
						>
							<Close />
						</IconButton>
					)}
					<RenderQRCode section={child} rowIndex={rowIndex} columnIndex={index + 1} />
					<div className={classes.containersWrapper}>
						<EmptyContainer column={child} />
						<Containers isEditMode={isEditMode} rowIndex={rowIndex} columnIndex={index + 1}>
							{reversedChildren}
						</Containers>
					</div>
				</div>
				<DialogForm
					form={`add-section-form-${formUniqueKey(chosenSubsection)}`}
					onClose={() => handleDialogClose()}
					onSubmit={content => handleConfirmDialog(content)}
					size="sm"
					showDialog={chosenSubsection}
					title="resources.warehouses.action.add_subsection"
					titleParams={{ name: chosenSubsection?.name }}
				>
					<FieldsAddSection movableSubsections={true} />
				</DialogForm>
			</div>
		);
	});
};

const enhance = compose(translate, withStyles(styles), withDataProvider);

export default enhance(Columns);
