import { debounce } from 'lodash';
import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { NumberInput, TextInput, minValue } from 'react-admin';

import { Measurement } from '../components';
import { guttersContainer, marginGutters } from '../helpers';
import { required } from '../validations';

const styles = theme => {
	const gutters = marginGutters(theme);
	const container = guttersContainer(theme);
	const upSmBreakpoint = theme.breakpoints.up('sm');

	const guttersSize = gutters.marginLeft + gutters.marginRight;
	const upSmGuttersSize = gutters[upSmBreakpoint].marginLeft + gutters[upSmBreakpoint].marginRight;

	return {
		container: {
			...container,
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'wrap'
		},
		name: {
			marginTop: 0
		},
		input: {
			...gutters,
			flex: `1 0 calc(100% - ${guttersSize}px)`,
			[upSmBreakpoint]: {
				...gutters[upSmBreakpoint],
				flexBasis: `calc(50% - ${upSmGuttersSize}px)`
			}
		},
		fullWidth: {
			flexBasis: `calc(100% - ${guttersSize}px)`,
			[upSmBreakpoint]: {
				flexBasis: `calc(100% - ${upSmGuttersSize}px)`
			}
		}
	};
};

class UnconnectedFieldsAddSection extends Component {
	state = {
		quantity: 1
	};

	constructor(props) {
		super(props);

		this.handleQuantityChange = debounce(this.handleQuantityChange, 200);
	}

	handleQuantityChange = (event, quantity) => {
		this.setState({ quantity });
	};

	render() {
		const { classes, onChangeName, ...props } = this.props;
		const { quantity } = this.state;

		return (
			<div className={classes.container}>
				<TextInput
					{...props}
					className={[classes.input, classes.fullWidth, classes.name].join(' ')}
					onChange={onChangeName}
					source="name"
					validate={required('storageareas', 'name')}
				/>
				<NumberInput
					className={classes.input}
					defaultValue={1}
					inputProps={{
						min: 1
					}}
					source="quantity"
					validate={[required('storageareas', 'quantity'), minValue(1)]}
					onChange={this.handleQuantityChange}
				/>
				<NumberInput
					className={classes.input}
					defaultValue={1}
					inputProps={{
						min: 1
					}}
					source="first"
					validate={[required('storageareas', 'first'), minValue(1)]}
					disabled={quantity === 1}
				/>
				<Measurement
					source="area"
					className={`${classes.input} ${classes.fullWidth}`}
					placeholder="1000"
					validate={minValue(0)}
					measure="m²"
					defaultValue={0}
				/>
			</div>
		);
	}
}

const FieldsAddSection = withStyles(styles)(UnconnectedFieldsAddSection);

FieldsAddSection.defaultProps = {
	onChangeName: () => ''
};

export default FieldsAddSection;
