let globalUniqueKey = 0;
const uniqueKeys = {};

export const nextUniqueKey = () => ++globalUniqueKey;

export const formUniqueKey = child => {
	const childKey = JSON.stringify(child);

	if (!uniqueKeys[childKey]) {
		uniqueKeys[childKey] = nextUniqueKey();
	}

	return uniqueKeys[childKey];
};
