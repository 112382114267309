import { withStyles, Avatar } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { Confirm, ImageInput, showNotification } from 'react-admin';
import { compose } from 'recompose';

import withUploadProvider from '../providers/withUploadProvider';

const styles = theme => ({
	root: {
		'& .previews': {
			marginLeft: '-0.5rem',
			marginRight: '-0.5rem'
		}
	},
	input: {
		marginLeft: 24,
		marginRight: 24,
		'& div > span > div:first-child': {
			padding: 0,
			background: 'none',
			color: 'black',
			'& p': {
				color: theme.palette.primary.special,
				margin: 0,
				marginBottom: 20,
				textAlign: 'left',
				fontSize: 14
			},
			'& p:hover': {
				textDecoration: 'underline',
				opacity: 0.7,
				transitionDuration: '0.5s'
			}
		},
		'& .previews > div': {
			float: 'none',
			'& button': {
				display: 'none',
				right: 0,
				top: 0,
				zIndex: 100000
			}
		}
	},
	ava: {
		width: 200,
		height: 200
	}
});

const Ava = ({ record, classes, handleDeleteClick }) => {
	return record.src ? (
		<Avatar
			alt="ava"
			src={record.src}
			className={classes.ava}
			onClick={() => handleDeleteClick()}
		/>
	) : null;
};

class UnconnectedFileInput extends Component {
	state = {
		url: '',
		file: {},
		isOpenDialog: false
	};

	componentDidMount() {
		const { record, onChange } = this.props;
		const url = record.photo || '';

		const file = {
			src: url,
			title: url.split('/').pop()
		};

		this.setState({
			url,
			file,
			avatar: !!record.photo
		});

		onChange(url);
	}

	componentWillUnmount() {
		this.setState({
			url: '',
			file: {}
		});
	}

	handleAdd = file => {
		const { onBeforeUpload, onAfterUpload, onChange, dispatch } = this.props;

		onBeforeUpload();
		this.uploadFile(file)
			.then(uploaded => {
				this.setState({
					url: uploaded.src,
					file: uploaded,
					avatar: true
				});

				onAfterUpload();
				onChange(uploaded.src);
			})
			.catch(() => dispatch(showNotification('ra.notification.upload_file_error', 'warning')));
	};

	handleBlur = file => {
		if (file) {
			this.handleAdd(file);
		}
	};

	handleConfirmDeleteDialog = () => {
		const { onChange } = this.props;

		onChange('');
		this.setState({
			file: {}
		});
		this.handleDialogClose();
	};

	handleDeleteClick = () => {
		this.setState({
			isOpenDialog: true
		});
	};

	handleDialogClose = () => {
		this.setState({
			isOpenDialog: false
		});
	};

	render() {
		const { accept, classes, source, label, isRequired, disabled } = this.props;
		const { file: stateFile } = this.state;

		return (
			<Fragment>
				<ImageInput
					className={classes.input}
					source={source}
					label={label}
					accept={accept}
					input={{
						value: [stateFile],
						onBlur: this.handleBlur
					}}
					isRequired={isRequired}
					options={{
						inputProps: {
							disabled: disabled
						}
					}}
				>
					<Ava classes={classes} handleDeleteClick={this.handleDeleteClick} />
				</ImageInput>
				<Confirm
					isOpen={this.state.isOpenDialog}
					title="ra.message.deleteAvatarTitle"
					content="ra.message.deleteAvatarContent"
					onConfirm={this.handleConfirmDeleteDialog}
					onClose={this.handleDialogClose}
				/>
			</Fragment>
		);
	}

	uploadFile = file => {
		const { uploadProvider, resource } = this.props;

		return uploadProvider(resource, file);
	};
}

const enhance = compose(withStyles(styles), withUploadProvider);

const ImageInputPro = enhance(UnconnectedFileInput);

ImageInputPro.defaultProps = {
	source: 'file',
	label: 'pos.file',
	addLabel: false,
	accept: 'application/pdf',
	disabled: false
};

export default ImageInputPro;
