import { Divider, Typography, withStyles } from '@material-ui/core';
import React, { Fragment } from 'react';
import { translate } from 'react-admin';
import { compose } from 'recompose';

import Article from './Article';

const styles = theme => ({
	root: {
		'&:not(:first-child)': {
			marginTop: theme.spacing.unit * 3
		}
	},
	header: {
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'space-between',
		paddingTop: 12,
		paddingBottom: 12
	},
	container: {
		paddingLeft: theme.spacing.unit * 3,
		paddingRight: theme.spacing.unit * 3
	}
});

const Project = ({
	classes,
	translate,
	project: { id: projectId, scannedBarcodes, totalBarcodes, articles }
}) => (
	<div className={classes.root}>
		<div className={`${classes.container} ${classes.header}`}>
			<Typography variant="title">Project ID: #{projectId}</Typography>
			<Typography variant="caption">
				{translate('resources.acceptance.fields.scanned_barcodes')}: {scannedBarcodes}/
				{totalBarcodes}
			</Typography>
		</div>
		<div className={classes.container}>
			{articles.map((article, index) => (
				<Fragment key={article.id}>
					<Article article={article} />
					{index !== articles.length - 1 && <Divider />}
				</Fragment>
			))}
		</div>
		<Divider />
	</div>
);

const enhance = compose(withStyles(styles), translate);

export default enhance(Project);
