import { withStyles } from '@material-ui/core';
import React from 'react';
import { BooleanField, List, Responsive, TextField } from 'react-admin';

import { Datagrid, ListActions as UserActions, ListFilter as UserFilter } from '../components';
import styles from '../styles';

import MobileGrid from './MobileGrid';

const UserList = ({ classes, ...props }) => (
	<List {...props} filters={<UserFilter />} perPage={25} bulkActionButtons={false} exporter={false}>
		<Responsive
			medium={<MobileGrid />}
			large={
				<Datagrid>
					<TextField source="firstname" />
					<TextField source="surname" />
					<TextField source="email" />
					<TextField source="username" />
					<TextField source="role" sortable={false} />
					<TextField
						source="created_by.email"
						label="resources.users.fields.created_by"
						sortBy="createdBy"
					/>
					<TextField source="mobile_phone_number" sortable={false} />
					<TextField source="phone_number" sortable={false} />
					<BooleanField source="enabled" />
					<UserActions entityName={'users'} headerClassName={classes.minWidth} />
				</Datagrid>
			}
		/>
	</List>
);

export default withStyles(styles)(UserList);
