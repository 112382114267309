import { get } from 'lodash';
import { Component } from 'react';

class BaseTree extends Component {
	state = {
		tree: []
	};

	addNode(node) {
		const { source } = this.props;
		const { tree } = this.state;

		tree.splice(0, 0, {
			[source]: node,
			children: []
		});

		this.setState({ tree });
	}

	handleAdd = async (id, value) => {
		const { fetchAdd, source } = this.props;
		const { tree } = this.state;
		const parentIndex = tree.findIndex(node => {
			const nodeId = get(node, source ? `${source}.id` : 'id');

			return id === nodeId;
		});

		const node = await fetchAdd(id, value);

		if (parentIndex > -1) {
			tree[parentIndex].children.splice(0, 0, {
				[source]: node,
				children: []
			});

			this.setState({ tree });
		}
	};

	handleDelete = async id => {
		const { fetchDelete, source } = this.props;
		await fetchDelete(id);

		const tree = this.state.tree.filter(node => {
			const nodeId = get(node, source ? `${source}.id` : 'id');

			return id !== nodeId;
		});

		if (tree.length !== this.state.tree.length) {
			this.setState({ tree });
		}
	};

	handleEdit = async (commentId, body) => {
		const { fetchEdit } = this.props;

		return await fetchEdit(commentId, body);
	};
}

export default BaseTree;
