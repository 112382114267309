import {
	Avatar,
	Button,
	Card,
	CardActions,
	CircularProgress,
	MuiThemeProvider,
	createMuiTheme,
	withStyles
} from '@material-ui/core';
import { Lock as LockIcon } from '@material-ui/icons';
import React, { Component } from 'react';
import { Notification, translate, userLogin } from 'react-admin';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'recompose';

import { RESET_PASSWORD } from '../providers/actions';
import withRecoveryPasswordProvider from '../providers/withRecoveryPasswordProvider';

import { styles } from './Login';
import renderInput from './renderInput';
import { lightTheme } from './themes';

class RecoveryPassword extends Component {
	restore = ({ plain_password }) => {
		const {
			recoveryPasswordProvider,
			userLogin,
			location,
			match: { params: confirmation_token }
		} = this.props;

		recoveryPasswordProvider(RESET_PASSWORD, {
			confirmation_token,
			plain_password
		}).then(({ email }) =>
			userLogin(
				{
					email,
					password: plain_password
				},
				location.state ? location.state.nextPathname : '/'
			)
		);
	};

	render() {
		const { classes, handleSubmit, isLoading, translate } = this.props;

		return (
			<div className={classes.main}>
				<Card className={classes.card}>
					<div className={classes.avatar}>
						<Avatar className={classes.icon}>
							<LockIcon />
						</Avatar>
					</div>
					<form onSubmit={handleSubmit(this.restore)}>
						<div className={classes.form}>
							<div className={classes.input}>
								<Field
									autoFocus
									name="plain_password"
									type="password"
									component={renderInput}
									label={translate('resources.users.fields.plain_password')}
									disabled={isLoading}
								/>
							</div>
							<div className={classes.input}>
								<Field
									autoFocus
									name="confirm_password"
									type="password"
									component={renderInput}
									label={translate('resources.users.fields.confirm_password')}
									disabled={isLoading}
								/>
							</div>
						</div>
						<CardActions className={classes.actions}>
							<Button
								variant="contained"
								type="submit"
								color="primary"
								disabled={isLoading}
								className={classes.button}
								fullWidth
							>
								{isLoading && <CircularProgress size={20} thickness={2} />}
								{translate('ra.action.restore_password')}
							</Button>
						</CardActions>
					</form>
				</Card>
				<Notification />
			</div>
		);
	}
}

const enhance = compose(
	translate,
	reduxForm({
		form: 'recoveryPassword',
		validate: (values, props) => {
			const { translate } = props;
			const errors = {};

			if (!values.plain_password) {
				const translateField = translate('resources.users.fields.plain_password');
				errors.plain_password = translate('ra.validation.required', { field: translateField });
			}

			if (!values.confirm_password) {
				const translateField = translate('resources.users.fields.confirm_password');
				errors.confirm_password = translate('ra.validation.required', { field: translateField });
			}

			if (values.plain_password !== values.confirm_password) {
				errors.confirm_password = translate('ra.validation.password_confirmation');
			}

			return errors;
		}
	}),
	withRecoveryPasswordProvider,
	connect(null, { userLogin }),
	withStyles(styles)
);

const EnhancedRecoveryPassword = enhance(RecoveryPassword);

const RecoveryPasswordWithTheme = props => (
	<MuiThemeProvider theme={createMuiTheme(lightTheme)}>
		<EnhancedRecoveryPassword {...props} />
	</MuiThemeProvider>
);

export default RecoveryPasswordWithTheme;
