import { fetchError, fetchEnd, fetchStart } from 'react-admin';
import { call, put, takeEvery } from 'redux-saga/effects';

import { UPLOAD_FILE } from './actions';
import uploadProvider from './uploadProvider';

export default function* uploadFileSaga() {
	yield takeEvery(UPLOAD_FILE, function* uploadFile({
		payload,
		meta: { resource, onSuccess, onFailure }
	}) {
		try {
			yield put(fetchStart());
			const file = yield call(uploadProvider, resource, payload);
			yield put(fetchEnd());

			yield onSuccess(file);
		} catch (error) {
			yield put(fetchError());

			yield onFailure(error);
		}
	});
}
