import { withStyles } from '@material-ui/core';
import { RemoveShoppingCart } from '@material-ui/icons';
import React, { Component } from 'react';
import { Button, crudUpdateMany } from 'react-admin';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import styles from '../styles';

import { IN_CART, IN_WAREHOUSE } from '../articles/statuses';

class RemoveFromCartButton extends Component {
	handleRemoveFromCart = event => {
		event.stopPropagation();

		const { basePath, crudUpdateMany, selectedIds, record } = this.props;

		if (Array.isArray(selectedIds) && selectedIds.length > 0) {
			crudUpdateMany('articles', selectedIds, { status: IN_WAREHOUSE }, basePath);
		} else if (record && record.id) {
			crudUpdateMany('articles', [record.id], { status: IN_WAREHOUSE }, basePath);
		}
	};

	render() {
		const { classes, record } = this.props;

		return !record || record.status === IN_CART ? (
			<Button
				label="ra.action.remove_from_button"
				className={classes.nowrap}
				onClick={this.handleRemoveFromCart}
			>
				<RemoveShoppingCart />
			</Button>
		) : null;
	}
}

const enhance = compose(withStyles(styles), connect(undefined, { crudUpdateMany }));

export default enhance(RemoveFromCartButton);
