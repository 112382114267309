const findStorage = (obj, storageId) => {
	if (
		typeof obj === 'object' &&
		obj.children &&
		Array.isArray(obj.children) &&
		obj.children.length > 0
	) {
		const child = obj.children.find(item => item.id === storageId);

		if (child) {
			return child;
		} else {
			let result;
			for (let child of obj.children) {
				result = findStorage(child, storageId);
				if (result) {
					break;
				}
			}
			return result;
		}
	}
};

export default findStorage;
