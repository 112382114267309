import { withStyles, IconButton, Menu, Avatar } from '@material-ui/core';
import { Settings as SettingsIcon, AccountCircle } from '@material-ui/icons';
import React, { Component, Fragment } from 'react';
import { MenuItemLink, WithPermissions, translate } from 'react-admin';
import { compose } from 'recompose';

import articleCategories from '../articleCategories';
import { ROLE_CUSTOMER, ROLE_MAIN_CUSTOMER } from '../users/roles';

import ShoppingCart from './ShoppingCart';

const styles = {
	userPhoto: {
		margin: 0,
		width: 30,
		height: 30
	},
	circleUserPhoto: {
		color: 'white'
	},
	dropDownArrow: {
		color: 'white',
		marginTop: '10px'
	},
	dropDownCart: {
		padding: '0 0 0 10px',
		'&:hover': {
			borderRadius: '30px'
		}
	}
};

class CustomUserMenu extends Component {
	state = {
		userPhoto: ''
	};

	componentDidMount() {
		const user = JSON.parse(localStorage.getItem('user'));
		window.addEventListener('storage', this.subscribeToStorage);

		if (user) {
			this.setState({
				userPhoto: user.photo
			});
		}
	}

	subscribeToStorage = event => {
		if (event.storageArea === localStorage) {
			const user = JSON.parse(localStorage.getItem('user'));
			if (user) {
				this.setState({
					userPhoto: user.photo
				});
			}
		}
	};

	componentWillUnmount() {
		window.removeEventListener('storage', this.subscribeToStorage);
	}

	handleClick(event) {
		localStorage.removeItem('changed_storage_area');
		localStorage.removeItem('is_create');
		localStorage.removeItem('is_edit');

		this.setState({
			anchorEl: event.currentTarget
		});
	}

	handleClose() {
		this.setState({
			anchorEl: null
		});
	}

	render() {
		const { classes, translate, logout } = this.props;
		const { anchorEl, userPhoto } = this.state;

		return (
			<Fragment>
				<ShoppingCart classes={classes} />

				<IconButton
					aria-label="more"
					aria-controls="long-menu"
					aria-haspopup="true"
					onClick={e => this.handleClick(e)}
				>
					{userPhoto ? (
						<Avatar className={classes.userPhoto} src={userPhoto} alt="avatar" />
					) : (
						<AccountCircle className={classes.circleUserPhoto} />
					)}
				</IconButton>

				<Menu
					id="long-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={() => this.handleClose()}
				>
					<MenuItemLink
						onClick={() => this.handleClose()}
						to="/profile"
						primaryText={translate('pos.profile')}
						leftIcon={<AccountCircle />}
					/>
					<MenuItemLink
						onClick={() => this.handleClose()}
						to="/configuration"
						primaryText={translate('pos.configuration')}
						leftIcon={<SettingsIcon />}
					/>
					<WithPermissions
						match={null}
						location={null}
						render={({ permissions: role }) =>
							role !== ROLE_MAIN_CUSTOMER && role !== ROLE_CUSTOMER ? (
								<MenuItemLink
									onClick={() => this.handleClose()}
									to="/article-categories"
									primaryText={translate('resources.article-categories.name', {
										smart_count: 2
									})}
									leftIcon={<articleCategories.icon />}
								/>
							) : null
						}
					/>
					{logout}
				</Menu>
			</Fragment>
		);
	}
}

const enhance = compose(withStyles(styles), translate);

export default React.memo(enhance(CustomUserMenu));
