import { fetchError, fetchEnd, fetchStart } from 'react-admin';
import { call, put, takeEvery } from 'redux-saga/effects';

import { RECOVERY_PASSWORD } from './actions';
import recoveryPasswordProvider from './recoveryPasswordProvider';

export default function* recoveryPasswordSaga() {
	yield takeEvery(RECOVERY_PASSWORD, function* uploadFile({
		payload,
		meta: { fetch, onSuccess, onFailure }
	}) {
		try {
			yield put(fetchStart());
			const result = yield call(recoveryPasswordProvider, fetch, payload);
			yield put(fetchEnd());

			yield onSuccess(result);
		} catch (error) {
			yield put(fetchError());

			yield onFailure(error);
		}
	});
}
