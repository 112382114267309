import { ViewQuilt as ArticleIcon } from '@material-ui/icons';
import React, { Component, Fragment, useState } from 'react';
import { Button, SimpleShowLayout, DateField, GET_LIST } from 'react-admin';

import { AddressField, FullNameField, MobileGrid as BaseMobileGrid } from '../components';
import CancelButton from './CancelButton';
import { ROLE_CUSTOMER } from '../users/roles';

import { CONFIRMED, OUTSOURCED } from './statuses';
import ApproveButton from './ApproveButton';
import DeliveryArticle from './DeliveryArticle';
import TypeField from './TypeField';
import { dataProvider } from '../providers';

const TitleMobileGrid = ({ record }) => (
	<span>
		#{record.id}. {record.address ? <FullNameField record={record.address} /> : record.surname}
	</span>
);

export const BodyMobileGrid = ({
	record,
	basePath,
	className,
	isShownArticles,
	permissions,
	articlesToDelivery,
	...props
}) => {
	return (
		<SimpleShowLayout
			className={className}
			record={record}
			permissions={permissions}
			basePath={basePath}
			{...props}
		>
			<AddressField showName={false} label="resources.delivery.fields.recipient" />
			<TypeField sortBy="address" label="resources.delivery.fields.type" sortable={false} />
			<DateField showTime={true} source="date" />
			{isShownArticles &&
				articlesToDelivery &&
				articlesToDelivery[record.id]?.map(article => (
					<DeliveryArticle
						key={article.id}
						deliveryId={record.id}
						article={article}
						permissions={permissions}
						basePath={basePath}
					/>
				))}
		</SimpleShowLayout>
	);
};

class DeliveryActions extends Component {
	state = {
		isLoading: false
	};

	handleToggleArticles = async () => {
		const {
			isShownArticles,
			setState,
			addArticlesToDelivery,
			articlesToDelivery,
			record
		} = this.props;

		if (!isShownArticles && !articlesToDelivery[record.id]) {
			this.setState({
				isLoading: true
			});
			await addArticlesToDelivery(record.id);
			this.setState({
				isLoading: false
			});
		}

		setState({
			isShownArticles: !isShownArticles
		});
	};

	render() {
		const { permissions, record, isShownArticles, ...props } = this.props;
		const { isLoading } = this.state;

		return (
			<Fragment>
				{(permissions !== ROLE_CUSTOMER || record.status !== CONFIRMED) &&
					record.status !== OUTSOURCED && <CancelButton record={record} {...props} />}
				{permissions !== ROLE_CUSTOMER &&
					record.status !== CONFIRMED &&
					record.status !== OUTSOURCED && <ApproveButton record={record} {...props} />}
				<Button
					label={isShownArticles ? 'ra.action.hide_articles' : 'ra.action.show_articles'}
					onClick={this.handleToggleArticles}
					disabled={isLoading}
				>
					<ArticleIcon />
				</Button>
			</Fragment>
		);
	}
}

const MobileGrid = props => {
	const [articlesToDelivery, setArticlesToDelivery] = useState({});

	const addArticlesToDelivery = async deliveryId => {
		const data = await dataProvider(GET_LIST, 'articles', {
			filter: {
				delivery: deliveryId
			}
		});

		if (!data?.data) return;
		setArticlesToDelivery({
			...articlesToDelivery,
			[deliveryId]: data.data
		});
	};

	return (
		<BaseMobileGrid
			{...props}
			title={<TitleMobileGrid />}
			body={<BodyMobileGrid {...props} articlesToDelivery={articlesToDelivery} />}
			deletable={false}
			customButtons={
				<DeliveryActions
					{...props}
					addArticlesToDelivery={addArticlesToDelivery}
					articlesToDelivery={articlesToDelivery}
				/>
			}
		/>
	);
};

export default MobileGrid;
