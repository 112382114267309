import { isEmpty } from 'lodash';

export default ({ address, user = {}, idProject }) => {
	const firstname = (!isEmpty(user) ? user.firstname : address.firstname) || '';
	const surname = (!isEmpty(user) ? user.surname : address.surname) || '';

	let name = address.company || (firstname + ' ' + surname).trim() || '';

	return [idProject && '#' + idProject + '. ', name && name]
		.filter(Boolean)
		.filter(String)
		.join(' ');
};
