import { debounce, get } from 'lodash';
import { MenuItem, Paper, Popper, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { addField, TextInput, translate } from 'react-admin';
import BaseAutosuggestion from 'react-autosuggest';
import { compose } from 'recompose';

class AutosuggestEvent {
	constructor(inputValue, suggestion) {
		this.event = new Event('change');

		this.target = {
			type: 'text',
			value: inputValue
		};
		this.suggestion = suggestion;
	}

	stopPropagation() {
		this.event.stopPropagation();
	}

	preventDefault() {
		this.event.preventDefault();
	}
}

const styles = theme => ({
	container: {
		flexGrow: 1,
		position: 'relative'
	},
	input: {
		width: '100%',
		marginBottom: 0
	},
	suggestionsContainer: {
		// maxWidth: '100%',
	},
	suggestionsContainerOpen: {
		position: 'absolute',
		marginBottom: theme.spacing.unit * 3,
		zIndex: 2
	},
	suggestionsPaper: {
		maxHeight: '50vh',
		overflowY: 'auto'
	},
	suggestion: {
		display: 'block',
		fontFamily: theme.typography.fontFamily
	},
	suggestionText: {
		fontWeight: 300,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap'
	},
	suggestionsList: {
		margin: 0,
		padding: 0,
		listStyleType: 'none'
	}
});

class Autosuggest extends Component {
	state = {
		suggestions: [],
		inputValue: '',
		cancel: false
	};
	anchorEl = null;

	constructor(props) {
		super(props);

		this.debouncedHandleSuggestionsFetchRequested = debounce(
			this.handleSuggestionsFetchRequested,
			500
		);
		this.debouncedHandleChange = debounce(this.handleChange, 500);
	}

	getSelectedItem = ({ choices }, inputValue) =>
		choices && inputValue
			? choices.find(choice => this.getSuggestionValue(choice) === inputValue)
			: null;

	getSuggestionText = suggestion => {
		if (!suggestion) {
			return '';
		}

		const { optionText, translate, translateChoice } = this.props;
		const suggestionLabel =
			typeof optionText === 'function' ? optionText(suggestion) : get(suggestion, optionText, '');

		// We explicitly call toString here because AutoSuggest expect a string
		return translateChoice
			? translate(suggestionLabel, {
					_: suggestionLabel
			  }).toString()
			: suggestionLabel.toString();
	};

	getSuggestionValue = suggestion => get(suggestion, this.props.optionValue);

	handleChange = (event, { newValue, method }) => {
		if (['type', 'click', 'escape'].includes(method)) {
			this.setState({
				inputValue: newValue
			});
		}
	};

	handleSuggestionSelected = (event, { suggestion, method }) => {
		const { input } = this.props;
		const inputValue = this.getSuggestionValue(suggestion);

		this.setState({ inputValue }, () => {
			const event = new AutosuggestEvent(inputValue, suggestion);

			input.onChange(event);
		});

		if (method === 'enter') {
			event.preventDefault();
		}
	};

	handleSuggestionsClearRequested = async () => {
		this.setState({
			suggestions: [],
			inputValue: '',
			cancel: true
		});
	};

	handleSuggestionsFetchRequested = async event => {
		const { fetchOptions } = this.props;
		const suggestions = await fetchOptions(event);
		const { cancel } = this.state;
		if (suggestions && !cancel) {
			this.setState({ suggestions });
		}
		this.setState({ cancel: !cancel });
	};

	// eslint-disable-next-line no-unused-vars
	renderSuggestionComponent = ({ suggestion, query, isHighlighted, ...props }) => (
		<div {...props} />
	);

	render() {
		const {
			classes,
			className,
			isRequired,
			label,
			resource,
			source,
			options,
			...props
		} = this.props;
		const { suggestions, inputValue } = this.state;

		return (
			<div className={className}>
				<BaseAutosuggestion
					theme={{
						container: classes.container,
						suggestionsContainerOpen: classes.suggestionsContainerOpen,
						suggestionsList: classes.suggestionsList,
						suggestion: classes.suggestion
					}}
					renderInputComponent={this.renderInput}
					suggestions={suggestions}
					alwaysRenderSuggestions={true}
					onSuggestionSelected={this.handleSuggestionSelected}
					onSuggestionsFetchRequested={this.debouncedHandleSuggestionsFetchRequested}
					onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
					renderSuggestionsContainer={this.renderSuggestionsContainer}
					getSuggestionValue={this.getSuggestionText}
					renderSuggestion={this.renderSuggestion}
					inputProps={{
						classes,
						isRequired,
						label,
						resource,
						source,
						value: inputValue,
						onChange: this.debouncedHandleChange,
						options
					}}
					{...props}
				/>
			</div>
		);
	}

	renderInput = inputProps => {
		const {
			// eslint-disable-next-line no-unused-vars
			autoFocus,
			classes,
			label,
			// eslint-disable-next-line no-unused-vars
			meta,
			onChange,
			resource,
			source,
			value,
			ref,
			isRequired,
			validate,
			// eslint-disable-next-line no-unused-vars
			options: { InputProps, suggestionsContainerProps, ...options },
			...other
		} = inputProps;

		const storeInputRef = input => {
			if (!input) {
				return;
			}

			this.anchorEl = input;
			ref(input);
		};

		return (
			<TextInput
				source={source}
				resource={resource}
				label={label}
				value={value}
				onChange={onChange}
				inputRef={storeInputRef}
				className={classes.input}
				validate={validate}
				required={isRequired}
				{...options}
				InputProps={{
					classes: {
						input: classes.input
					},
					...InputProps,
					...other
				}}
			/>
		);
	};

	renderSuggestion = (suggestion, { query, isHighlighted }) => {
		const label = this.getSuggestionText(suggestion);
		const { classes } = this.props;

		return (
			<MenuItem
				selected={isHighlighted}
				component={this.renderSuggestionComponent}
				suggestion={suggestion}
				query={query}
				isHighlighted={isHighlighted}
			>
				<div className={classes.suggestionText}>
					<span>{label}</span>
				</div>
			</MenuItem>
		);
	};

	renderSuggestionsContainer = ({ containerProps: { className, ...containerProps }, children }) => {
		const { classes } = this.props;
		const anchorBoundingClientRect = this.anchorEl && this.anchorEl.getBoundingClientRect();
		const width = anchorBoundingClientRect && anchorBoundingClientRect.width;

		return (
			<Popper
				className={`${className} ${classes.suggestionsContainer}`}
				open={Boolean(children)}
				anchorEl={this.anchorEl}
				placement="bottom-start"
				style={{ width }}
			>
				<Paper square className={classes.suggestionsPaper} {...containerProps}>
					{children}
				</Paper>
			</Popper>
		);
	};
}

Autosuggest.defaultProps = {
	options: {},
	optionText: 'name',
	optionValue: 'id',
	translateChoice: true
};

const enhance = compose(addField, withStyles(styles), translate);

export default enhance(Autosuggest);
