import { withStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Cancel as CancelIcon } from '@material-ui/icons';
import React, { Component, Fragment } from 'react';
import { Button, Confirm, crudDelete, translate } from 'react-admin';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

const styles = theme => ({
	cancelButton: {
		color: theme.palette.error.main,
		'&:hover': {
			backgroundColor: fade(theme.palette.error.main, 0.12),
			// Reset on mouse devices
			'@media (hover: none)': {
				backgroundColor: 'transparent'
			}
		}
	}
});

class UnconnectedCancelButton extends Component {
	state = {
		isOpen: false
	};

	handleCancel = () => {
		const { crudDelete, resource, record, basePath, redirect } = this.props;

		crudDelete(resource, record.id, record, basePath, redirect);
	};

	handleClick = event => {
		event.stopPropagation();

		this.setState({
			isOpen: true
		});
	};

	handleDialogClose = event => {
		event.stopPropagation();

		this.setState({
			isOpen: false
		});
	};

	render() {
		const { classes, className, icon, label, record, resource, translate } = this.props;
		const { isOpen } = this.state;

		return (
			<Fragment>
				<Button
					label={label || 'ra.action.cancel'}
					onClick={this.handleClick}
					className={`ra-cancel-button ${classes.deleteButton} ${className}`}
				>
					{icon}
				</Button>
				<Confirm
					isOpen={isOpen}
					title="ra.message.cancel_title"
					content="ra.message.cancel_content"
					translateOptions={{
						name: translate('resources.' + resource + '.name', {
							smart_count: 1
						}),
						id: record.id
					}}
					onConfirm={this.handleCancel}
					onClose={this.handleDialogClose}
				/>
			</Fragment>
		);
	}
}

const enhance = compose(connect(null, { crudDelete }), translate, withStyles(styles));

const CancelButton = enhance(UnconnectedCancelButton);

CancelButton.defaultProps = {
	redirect: 'list',
	icon: <CancelIcon />
};

export default CancelButton;
