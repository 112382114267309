import { keyBy } from 'lodash';
import { CircularProgress, Divider } from '@material-ui/core';
import React, { Component, Fragment } from 'react';

import ArticleDatagrid from './ArticleDatagrid';
import httpClient from '../providers/httpClient';
import { stringify } from 'querystring';
import { crudUpdateMany, Pagination } from 'react-admin';
import { compose } from 'recompose';
import { connect } from 'react-redux';

class ExpandArticles extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			ids: props.record?.serial_number_info.id,
			articles: null,
			page: 1,
			perPage: 5,
			total: props.record?.serial_number_info.id.length
		};
		this.filter = {};
		this.filter['filter[id][in][]'] = this.getFilter(
			this.state.ids,
			this.state.page,
			this.state.perPage
		);
		this.filter[`sort[status]`] = 'DESC';
	}

	componentDidMount() {
		if (this.state.ids.length > 0) {
			this.getArticles(this.filter, this.state.perPage).then(res => {
				this.setState({ articles: res, isLoading: false });
			});
		}
	}

	getFilter = (idsArray, page, perPage) => {
		const firstElement = page * perPage - perPage;
		const lastElement = page * perPage;
		return idsArray.slice(firstElement, lastElement);
	};

	getArticles = async (filter, limit) =>
		await httpClient(
			`${process.env.REACT_APP_API_URL}/articles?${stringify(filter)}&limit=${limit}`
		).then(res => res.json);

	handlePageChange = async page => {
		const { perPage } = this.state;
		await this.updateExpandedArticles(page, perPage);
	};

	handlePerPageChange = async perPage => {
		await this.updateExpandedArticles(1, perPage);
	};

	updateExpandedArticles = async (page, perPage) => {
		const localFilter = {};
		localFilter['filter[id][in][]'] = this.getFilter(this.state.ids, page, perPage);
		localFilter['sort[status]'] = 'DESC';
		this.getArticles(localFilter, perPage).then(res => {
			this.setState({
				articles: res,
				isLoading: false,
				total: this.state.ids.length,
				page,
				perPage
			});
		});
	};

	render() {
		if (this.state.isLoading) {
			return <CircularProgress size={20} thickness={2} />;
		} else {
			return (
				<Fragment>
					<Pagination
						page={this.state.page}
						perPage={this.state.perPage}
						setPage={this.handlePageChange}
						setPerPage={this.handlePerPageChange}
						total={this.state.total}
						rowsPerPageOptions={[5, 25, 100, 250, 500]}
					/>
					<ArticleDatagrid
						ids={this.state.articles?.map(({ id }) => id)}
						data={keyBy(this.state.articles, 'id')}
						currentSort={{
							field: ''
						}}
						basePath={this.props.basePath}
						className={`${this.props.classes.lastLine} ${this.props.classes.expandBackground}`}
						hideExpand={true}
						hideExpandCell={true}
						onToggleItem={this.props.onToggleItem}
						selectedIds={this.props.selectedIds}
						customActions={this.props.customActions}
					/>
					<Pagination
						page={this.state.page}
						perPage={this.state.perPage}
						setPage={this.handlePageChange}
						setPerPage={this.handlePerPageChange}
						total={this.state.total}
						rowsPerPageOptions={[5, 25, 100, 250, 500]}
					/>
					<Divider />
				</Fragment>
			);
		}
	}
}

const enhance = compose(connect(undefined, { crudUpdateMany }));

export default enhance(ExpandArticles);
