import recoveryPasswordSaga from './recoveryPasswordSaga';
import uploadFileSaga from './uploadFileSaga';

export { default as authProvider } from './authProvider';
export { default as i18nProvider } from './i18nProvider';
export { default as recoveryPasswordProvider } from './recoveryPasswordProvider';
export { default as dataProvider } from './restProvider';
export { default as uploadProvider } from './uploadProvider';

export const sagas = [recoveryPasswordSaga, uploadFileSaga];
