import React, { Fragment } from 'react';
import { Title } from 'react-admin';

import ScanDeliveryBarcodes from './ScanDeliveryBarcodes';

const Outsourcing = ({ location, ...props }) => {
	return (
		<Fragment>
			<Title title="Outsourcing" />
			<ScanDeliveryBarcodes location={location} {...props} />
		</Fragment>
	);
};

export default Outsourcing;
