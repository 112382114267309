import { IconButton, Snackbar, SnackbarContent, withStyles } from '@material-ui/core';
import { CameraAlt as CameraIcon, Close as CloseIcon } from '@material-ui/icons';
import { translate } from 'react-admin';
import React, { Component } from 'react';
import { compose } from 'recompose';

const styles = theme => ({
	closeContainerStyle: {
		width: theme.spacing.unit * 4,
		height: theme.spacing.unit * 4
	},
	snackbarContainerStyle: {
		backgroundColor: theme.palette.error.dark
	}
});

class UnconnectedSnackbarNotification extends Component {
	render() {
		const { classes, translate, isOpen, onClose } = this.props;

		return (
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				open={isOpen}
				autoHideDuration={6000}
				onClose={onClose}
				ContentProps={{
					'aria-describedby': 'message-id'
				}}
			>
				<SnackbarContent
					className={classes.snackbarContainerStyle}
					message={<span id="message-id">{translate('ra.notification.scanning')}</span>}
					action={[
						<IconButton
							key="close"
							aria-label="Close"
							color="inherit"
							className={classes.close}
							onClick={onClose}
						>
							<CloseIcon className={classes.icon} />
						</IconButton>
					]}
				/>
			</Snackbar>
		);
	}
}

const enhance = compose(withStyles(styles), translate);

const SnackbarNotification = enhance(UnconnectedSnackbarNotification);

SnackbarNotification.defaultProps = {
	icon: <CameraIcon />,
	alignIcon: 'left',
	size: 'small',
	isOpenDefault: false,
	variant: 'contained'
};

export default SnackbarNotification;
