import React from 'react';
import outsourcing from './index';
import { Button } from 'react-admin';
import { ROLE_AGENT } from '../users/roles';

const OutsourceButton = ({
	record,
	scannedBarcodes,
	deliveryList,
	handleOutsource,
	permissions,
	fetchArticlesToDelivery
}) => {
	const articles = deliveryList.find(delivery => delivery.id === record.id)?.articles ?? [];

	const outsource = articles.filter(
		({ barcodes }) => !barcodes.filter(({ id }) => !scannedBarcodes.includes(id)).length
	);
	// for user's role Agent we make Outsource button available in any case
	const isButtonDisabled =
		permissions !== ROLE_AGENT && (outsource.length === 0 || outsource.length !== articles.length);

	const handleOutsourceWrapper = async deliveryId => {
		if (permissions === ROLE_AGENT) {
			const chosenDelivery = deliveryList.find(delivery => delivery.id === deliveryId);
			if (!chosenDelivery) return;
			// if user's role is Agent, we need to get articles for chosen delivery
			const articles = await fetchArticlesToDelivery(chosenDelivery);
			if (articles?.length > 0) {
				await handleOutsource(deliveryId, articles);
			}
			return;
		}
		if (outsource.length) {
			await handleOutsource(deliveryId, outsource);
		}
	};

	return (
		<Button
			variant="contained"
			label="ra.action.outsource"
			disabled={isButtonDisabled}
			onClick={() => handleOutsourceWrapper(record.id)}
		>
			<outsourcing.icon />
		</Button>
	);
};

export default React.memo(OutsourceButton);
