import { withStyles } from '@material-ui/core';
import { addField, translate } from 'ra-core';
import { AutocompleteInput as BaseAutocompleteInput } from 'ra-ui-materialui/esm/input/AutocompleteInput';
import compose from 'recompose/compose';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const styles = theme => ({
	container: {
		flexGrow: 1,
		position: 'relative'
	},
	root: {},
	suggestionsContainerOpen: {
		position: 'absolute',
		marginBottom: theme.spacing.unit * 3,
		zIndex: 2
	},
	suggestionsPaper: {
		maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
		overflowY: 'auto'
	},
	suggestion: {
		display: 'block',
		fontFamily: theme.typography.fontFamily
	},
	suggestionText: {
		fontWeight: 300
	},
	highlightedSuggestionText: {
		fontWeight: 500
	},
	suggestionsList: {
		margin: 0,
		padding: 0,
		listStyleType: 'none'
	}
});

class AutocompleteInput extends BaseAutocompleteInput {
	updateAnchorEl = function() {
		if (!this.inputEl) {
			return;
		}
		this.anchorEl = this.inputEl;
	};
}

export default compose(addField, translate, withStyles(styles))(AutocompleteInput);
