import { isEmpty } from 'lodash';

const headerFullNameAndCompanyHelper = (id, address, user) => {
	const firstname = !isEmpty(user) ? user.firstname : '';
	const surname = !isEmpty(user) ? user.surname : '';
	const company = address && address.company ? `(${address.company})` : '';
	let fullName = `#${id}. ${firstname || ''} ${surname || ''} ${company}`.trim();
	if (fullName.length > 35) {
		fullName = fullName.slice(0, 35) + '...';
	}

	return fullName;
};

const headerNameHelper = (record, name) => {
	if (record && name) {
		let fullName = `#${record.id}. ${name}`;
		if (fullName.length > 35) {
			fullName = fullName.slice(0, 35) + '...';
		}
		return fullName;
	}
};

const deliveryHeader = record => {
	if (record) {
		const address = record.address ? record.address : '';
		let firstname = '';
		let surname = '';
		let fullName = '';
		if (address) {
			firstname = record.address.firstname || '';
			surname = record.address.surname || '';
			fullName = `#${record.id}. ${firstname || ''} ${surname || ''}`.trim();
		} else {
			fullName = `#${record.id}. ${record.surname || ''}`.trim();
		}

		if (fullName.length > 35) {
			fullName = fullName.slice(0, 35) + '...';
		}
		return fullName;
	}
};

export { headerNameHelper, headerFullNameAndCompanyHelper, deliveryHeader };
