import { Button, Card, CardContent, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { SimpleForm, Title, translate } from 'react-admin';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { AutocompleteInput } from '../components';
import { withCountries } from '../helpers';

import { changeTheme } from '../AC';

const styles = theme => ({
	cardContent: {
		display: 'flex',
		alignItems: 'center',
		'&:last-child': {
			paddingBottom: 16
		}
	},
	containerTextInput: {
		alignItems: 'flex-end',
		paddingTop: 0,
		'&:last-child': {
			paddingBottom: 8
		}
	},
	label: {
		minWidth: '10em',
		color: theme.palette.primary.special
	},
	button: {
		marginRight: '0.5em'
	},
	labelTextInput: {
		marginTop: 16,
		marginBottom: 8,
		paddingTop: 6,
		paddingBottom: 7
	},
	simpleForm: {
		'& > div': {
			padding: '0!important'
		}
	}
});

class Configuration extends Component {
	handleChangeCountry = (event, defaultCountry) => {
		localStorage.setItem('defaultCountry', defaultCountry);
	};

	handleChangeTheme = theme => {
		const { changeTheme } = this.props;

		localStorage.setItem('theme', theme);
		changeTheme(theme);
	};

	render() {
		const { classes, countries, theme, translate } = this.props;
		const defaultCountry = localStorage.getItem('defaultCountry') || 'DE';

		return (
			<Card>
				<Title title={translate('pos.configuration')} />
				<CardContent className={classes.cardContent}>
					<div className={classes.label}>{translate('pos.theme.name')}</div>
					<Button
						variant="contained"
						className={classes.button}
						color={theme === 'light' ? 'primary' : 'default'}
						onClick={() => this.handleChangeTheme('light')}
					>
						{translate('pos.theme.light')}
					</Button>
					<Button
						variant="contained"
						className={classes.button}
						color={theme === 'dark' ? 'primary' : 'default'}
						onClick={() => this.handleChangeTheme('dark')}
					>
						{translate('pos.theme.dark')}
					</Button>
				</CardContent>
				<CardContent className={`${classes.cardContent} ${classes.containerTextInput}`}>
					<div className={`${classes.label} ${classes.labelTextInput}`}>
						{translate('pos.default_country')}
					</div>
					<SimpleForm toolbar={null} className={classes.simpleForm}>
						<AutocompleteInput
							source="country"
							choices={countries}
							optionValue="code"
							defaultValue={defaultCountry}
							onChange={this.handleChangeCountry}
						/>
					</SimpleForm>
				</CardContent>
			</Card>
		);
	}
}

const mapStateToProps = state => ({
	theme: state.theme
});

const enhance = compose(
	connect(mapStateToProps, {
		changeTheme
	}),
	translate,
	withStyles(styles),
	withCountries
);

export default enhance(Configuration);
