import { withStyles } from '@material-ui/core';
import { GET_LIST, translate, withDataProvider, maxLength } from 'react-admin';
import React, { useEffect, useRef, useState } from 'react';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';
import { findStorageArea } from './helpers';
import { useSearch } from './searchContext';
import { debounce } from 'lodash';
import { CONTAINER, CUSTOMER, NOTE, ORDER_NUMBER } from './searchContext/constants';
import Autosuggest from '../../src/components/Autosuggest';

const styles = {
	form: {
		display: 'flex'
	},
	searchBlock: {
		display: 'flex',
		flexDirection: 'column',
		'& input': {
			marginBottom: 0
		}
	},
	searchInputBlock: {
		display: 'flex',
		alignItems: 'center'
	},
	searchIcon: {
		color: 'gray'
	}
};

const INPUT_CHANGED = 'input-changed';

export const findContainers = async (dataProvider, searchBy, fields, rootId, additionalParams) => {
	const localResult = new Set();
	const filter = {};

	if (fields[CONTAINER]) {
		filter['name][like'] = fields[CONTAINER];
	}
	if (fields[CUSTOMER]) {
		filter['projectArea][project][customer][name'] = fields[CUSTOMER];
	}
	if (fields[NOTE]) {
		filter['storagearea][storageNote'] = fields[NOTE];
	}
	if (fields[ORDER_NUMBER]) {
		filter['projectArea][project][orderNumber'] = fields[ORDER_NUMBER];
	}

	if (!Object.keys(filter).length) return [];

	filter.root = rootId;

	let { data: warehouses } = await dataProvider(GET_LIST, 'storageareas', {
		filter,
		...additionalParams
	});
	if (warehouses.length) {
		warehouses.forEach(warehouse => {
			localResult.add(warehouse.id);
		});
	}

	return [...localResult.values()];
};

export const findAllSubsections = (children, ids) => {
	let result = new Set();

	ids.forEach(id => {
		let storage = findStorageArea(children, id);
		if (!storage) return;
		storage.path.split(',').forEach(elem => {
			if (elem) result.add(Number(elem));
		});
	});

	return [...result.values()];
};

const Search = ({
	sections,
	translate,
	classes,
	searchBy,
	dataProvider,
	additionalParams,
	rootId,
	dispatch,
	reset
}) => {
	const { setIdsSearch, searchedFields, setSearchedFields } = useSearch();
	const searchInputRef = useRef(null);
	const [containers, setContainers] = useState([]);

	useEffect(() => {
		const handleMouseDown = event => {
			if (searchInputRef.current && !searchInputRef.current.contains(event.target)) {
				setContainers([]);
			}
		};

		document.addEventListener('click', handleMouseDown);

		return () => {
			document.removeEventListener('click', handleMouseDown);
		};
	}, []);

	const getCustomersName = async value => {
		const result = [];

		if (!value) return [];
		let { data: customers } = await dataProvider(GET_LIST, 'customers', {
			filter: { q: value }
		});
		customers.forEach(customer => {
			result.push({
				[CUSTOMER]: `${customer.user.firstname} ${customer.user.surname}`,
				id: customer.user.id
			});
		});

		return result;
	};

	const getContainersName = async value => {
		const result = [];
		if (!value) return [];
		const filter = { q: value, root: rootId };

		let { data: warehouses } = await dataProvider(GET_LIST, 'storageareas', {
			filter,
			...additionalParams
		});
		warehouses.forEach(container => {
			result.push({ [CONTAINER]: container.name, id: container.id });
		});

		return result;
	};

	const localSearchedFields = {
		[CUSTOMER]: getCustomersName,
		[CONTAINER]: getContainersName
	};

	const handleSearchContainer = async event => {
		if (!event?.suggestion[searchBy]) return;
		setSearchedFields({ ...searchedFields, [searchBy]: event.suggestion[searchBy] });
		setContainers([]);
		await findElement({ ...searchedFields, [searchBy]: event.suggestion[searchBy] });
	};

	const findElement = async fields => {
		const containersIds = await findContainers(
			dataProvider,
			searchBy,
			fields,
			rootId,
			additionalParams
		);
		if (!containersIds) return;
		const ids = findAllSubsections(sections, containersIds);
		setIdsSearch(ids);
	};

	const getChoicesForAutosuggestion = debounce(async event => {
		if (event.reason !== INPUT_CHANGED) return;
		setSearchedFields({ ...searchedFields, [searchBy]: event.value });
		const result = await localSearchedFields[searchBy]?.(event.value);
		if (result?.length) {
			setContainers(result);
		} else {
			setContainers([]);
		}
		await findElement({ ...searchedFields, [searchBy]: event.value });
	}, 1000);

	const getLabel = searchBy => {
		if (searchBy === CONTAINER) {
			return translate('pos.search.container');
		}
		if (searchBy === CUSTOMER) {
			return translate('pos.search.customer');
		}
		if (searchBy === NOTE) {
			return translate('pos.search.comment');
		}
		if (searchBy === ORDER_NUMBER) {
			return translate('pos.search.order_number');
		}
		return translate('pos.search.title');
	};

	useEffect(() => {
		// use it for resetting inputs value of search-form on onmount component
		return () => {
			dispatch(reset('search-form'));
		};
	}, []);

	return (
		<div className={classes.searchBlock}>
			<div className={classes.searchInputBlock} ref={searchInputRef}>
				<div ref={searchInputRef}>
					<Autosuggest
						fetchOptions={getChoicesForAutosuggestion}
						label={getLabel(searchBy)}
						placeholder={getLabel(searchBy)}
						validate={maxLength(255)}
						suggestions={containers}
						optionValue={searchBy}
						optionText={searchBy}
						onChange={handleSearchContainer}
						source={searchBy}
					/>
				</div>
			</div>
		</div>
	);
};

const DecoratedForm = reduxForm({
	form: 'search-form',
	destroyOnUnmount: false
});

const enhance = compose(translate, withStyles(styles), withDataProvider, DecoratedForm);

export default enhance(Search);
