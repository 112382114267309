import { get, debounce } from 'lodash';
import { withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import {
	SimpleForm,
	TextInput,
	Toolbar,
	maxLength,
	translate,
	withDataProvider,
	GET_ONE,
	UPDATE
} from 'react-admin';
import { compose } from 'recompose';

import { ToolbarContainer } from '../components';
import generalStyles from '../styles';
import { required } from '../validations';
import Search from './Search';

import Structure from './Structure';
import { nextUniqueKey } from '../helpers';
import { findStorageArea } from './helpers';
import { CONTAINER, CUSTOMER, NOTE, ORDER_NUMBER } from './searchContext/constants';

const styles = theme => ({
	searchBlock: {
		display: 'flex',
		gap: '30px'
	},
	inputs: {
		display: 'flex',
		flexDirection: 'column',
		width: '50%',
		alignItems: 'flex-start'
	},
	nameField: {
		margin: '0 20px 0 0'
	},
	container: {
		width: '100%',
		maxWidth: theme.breakpoints.values['md']
	},
	structure: {
		marginTop: theme.spacing.unit * 3
	},
	spacer: generalStyles(theme).spacer
});

// eslint-disable-next-line no-unused-vars
const WarehouseForm = ({ record, dataProvider, save, classes, resource, isEdit, ...props }) => {
	const [state, setState] = useState({
		name: get(record, 'name'),
		children: get(record, 'children', []).map(child => ({
			...child,
			uniqueKey: nextUniqueKey()
		})),
		opened: []
	});

	const handleOpenSection = sectionId => {
		const newOpened = state.opened.includes(sectionId)
			? state.opened.filter(item => item !== sectionId)
			: state.opened.concat(sectionId);

		setState({
			opened: newOpened
		});
	};

	const handleChangeName = async ({ target: { value } }) => {
		await dataProvider(UPDATE, 'storageareas', {
			id: record.id,
			data: {
				id: record.id,
				name: value
			}
		});

		setState(prev => ({ ...prev, name: value }));
	};

	const debounceHandleChangeName = debounce(handleChangeName, 2000);

	const handlePreload = async storageId => {
		const stateCopy = JSON.parse(JSON.stringify(state));

		const storageArea = findStorageArea(stateCopy, storageId);

		if (storageArea && storageArea.children && storageArea.children.length > 0) {
			storageArea.children = await Promise.all(
				storageArea.children.map(async child => {
					if (!child.id) return { ...child };

					const { data } = await dataProvider(GET_ONE, 'storageareas', {
						id: child.id
					});

					return {
						...child,
						children: data.children
					};
				})
			);

			handleChangeStructure(stateCopy.children);
		}
	};

	const handleChangeStructure = children => {
		setState(prev => ({ ...prev, children }));
	};

	const modifiedName = state.name?.length > 35 ? state.name?.slice(0, 35) + '...' : state.name;

	return (
		<SimpleForm
			{...props}
			redirect={record.id ? false : 'edit'}
			record={record}
			resource={resource}
			toolbar={
				<Toolbar>
					<ToolbarContainer maxWidth="md" hideSaveButton={true} />
				</Toolbar>
			}
		>
			<div className={classes.inputs}>
				<TextInput
					source="name"
					onChange={debounceHandleChangeName}
					validate={[required(resource, 'name'), maxLength(255)]}
					className={classes.nameField}
				/>
				<div className={classes.searchBlock}>
					<Search sections={record?.children} searchBy={CONTAINER} rootId={record?.id} />
					<Search sections={record?.children} searchBy={CUSTOMER} rootId={record?.id} />
					<Search sections={record?.children} searchBy={ORDER_NUMBER} rootId={record?.id} />
					<Search sections={record?.children} searchBy={NOTE} rootId={record?.id} />
				</div>
			</div>
			<Structure
				formSection={record}
				className={`${classes.container} ${classes.structure}`}
				name={modifiedName}
				onChange={handleChangeStructure}
				onExpand={handlePreload}
				openSection={handleOpenSection}
				openedSections={state.opened}
				isEdit={isEdit}
			>
				{state.children}
			</Structure>
		</SimpleForm>
	);
};

export default compose(withStyles(styles), translate, withDataProvider)(WarehouseForm);
