import { useContext } from 'react';
import Context from './Context';

const useContainerHallMode = () => {
	const context = useContext(Context);

	if (!context) {
		throw new Error('useContainerHallMode must be used within an ContainerHallModeProvider');
	}

	return context;
};

export default useContainerHallMode;
