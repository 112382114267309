import { useContext } from 'react';
import Context from './Context';

const useSearch = () => {
	const context = useContext(Context);

	if (!context) {
		throw new Error('useSearch must be used within an SearchProvider');
	}

	return context;
};

export default useSearch;
