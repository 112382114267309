import { isEqual } from 'lodash';

const DEFAULT_STATE = 'defaultState';
const CURRENT_STATE = 'currentState';

export const showAlertHelper = {
	saveDefaultState(state) {
		localStorage.setItem(DEFAULT_STATE, JSON.stringify(state));
	},

	saveCurrentState(state) {
		localStorage.setItem(CURRENT_STATE, JSON.stringify(state));
	},

	compareStates() {
		const defaultState = JSON.parse(localStorage.getItem(DEFAULT_STATE));
		const currentState = JSON.parse(localStorage.getItem(CURRENT_STATE));

		if (defaultState && currentState) {
			if (!isEqual(defaultState, currentState)) return true;
			return false;
		}

		return false;
	},

	purgeState() {
		localStorage.removeItem(CURRENT_STATE);
		localStorage.removeItem(DEFAULT_STATE);
	}
};
