import { createContext } from 'react';

const Context = createContext({
	isMovingModeActive: false,
	toggleMovingMode: () => {},
	isDragging: false,
	setDragging: () => {},
	isConfirmationModalShown: false,
	setConfirmationModalShown: () => {}
});

export default Context;
