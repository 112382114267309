import { SIDEBAR_SIZE } from '../../../helpers';

export default (card, headerWidth, cardContent) => {
	//this numbers { 30, 220, 70, 200 } hand-picked and don't mean anything
	if (window.innerWidth >= 600) {
		card.style.width = headerWidth - SIDEBAR_SIZE - 30 + 'px';
		card.style.height = window.innerHeight + 'px';
		cardContent.style.height = card.clientHeight - 220 + 'px';
	} else {
		card.style.width = headerWidth + 'px';
		card.style.height = window.innerHeight - 70 + 'px';
		cardContent.style.height = card.clientHeight - 200 + 'px';
	}
};
