import React, { useState } from 'react';
import Context from './Context';

const Provider = ({ children }) => {
	const [idsSearch, setIdsSearch] = useState([]);
	const [searchedFields, setSearchedFields] = useState({});

	return (
		<Context.Provider
			value={{
				idsSearch,
				setIdsSearch,
				searchedFields,
				setSearchedFields
			}}
		>
			{children}
		</Context.Provider>
	);
};

export default Provider;
