import { Remove as RemoveIcon } from '@material-ui/icons';
import React, { Component } from 'react';
import { crudDelete, translate } from 'react-admin';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { ConfirmButton } from '../components';
import { OUTSOURCED } from '../articles/statuses';

class RemoveArticleButton extends Component {
	handleConfirmRemove = () => {
		const {
			crudDelete,
			deliveryId,
			record: { id: articleId }
		} = this.props;

		crudDelete(`deliveries/${deliveryId}/articles`, articleId);
	};

	render() {
		const {
			className,
			translate,
			deliveryId,
			record: { id: articleId, status }
		} = this.props;

		return (
			<ConfirmButton
				label="resources.deliveries.actions.remove_article"
				disabled={status === OUTSOURCED}
				onConfirm={this.handleConfirmRemove}
				className={className}
				icon={<RemoveIcon />}
				title="resources.deliveries.messages.remove_article"
				translateOptions={{
					articleId,
					deliveryId
				}}
				variant="text"
			>
				{translate('resources.deliveries.messages.remove_article_content', {
					articleId,
					deliveryId
				})}
			</ConfirmButton>
		);
	}
}

const enhance = compose(translate, connect(undefined, { crudDelete }));

export default enhance(RemoveArticleButton);
