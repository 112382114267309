import WarehouseIcon from '@material-ui/icons/Home';

import WarehouseList from './WarehouseList';
import WarehouseEdit from './WarehouseEdit';
import WarehouseShow from './WarehouseShow';

export default {
	list: WarehouseList,
	edit: WarehouseEdit,
	icon: WarehouseIcon,
	show: WarehouseShow
};
