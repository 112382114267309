export const CHANGE_THEME = 'CHANGE_THEME';
export const EXPAND_PANEL = 'EXPAND_PANEL';
export const UPDATE_CARTS = 'UPDATE_CARTS';
export const GROUP_BY_SN = 'GROUP_BY_SN';

export const changeTheme = theme => ({
	type: CHANGE_THEME,
	payload: theme
});

export const expandPanel = id => ({
	type: EXPAND_PANEL,
	payload: id
});

export const updateCarts = carts => ({
	type: UPDATE_CARTS,
	payload: carts
});

export const groupBySN = isGroupedBYSn => ({
	type: GROUP_BY_SN,
	payload: isGroupedBYSn
});
