import React from 'react';
import { Edit } from 'react-admin';

import UserForm from './UserForm';
import { headerNameHelper } from '../helpers/headerNameHelpers';

const UserTitle = ({ record }) => <span>{headerNameHelper(record, record.surname)}</span>;

const UserEdit = ({ permissions, ...props }) => (
	<Edit title={<UserTitle />} undoable={false} {...props}>
		<UserForm permissions={permissions} />
	</Edit>
);

export default UserEdit;
