import React, { useState } from 'react';
import Context from './Context';

const Provider = ({ children }) => {
	const [sections, setSections] = useState([]);

	const handleUpdateSections = () => {
		setSections([...sections]);
	};

	return (
		<Context.Provider
			value={{
				sections,
				setSections,
				updateSections: handleUpdateSections
			}}
		>
			{children}
		</Context.Provider>
	);
};

export default Provider;
