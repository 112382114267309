import { get } from 'lodash';
import React from 'react';
import { translate } from 'react-admin';

import { addressToString } from '../../helpers';
import { DELIVERY, PICKUP, DISPOSAL } from '../../deliveries/deliveryTypes';
import filterAddressHelpers from '../../helpers/filterAddressHelpers';
import { Tooltip } from '@material-ui/core';

const DEFAULT_SOURCE = 'address';

const UnconnectedAddressField = ({ record, showName, source }) => {
	const address = get(record, source);
	const user = get(record, source);
	const name = get(record, 'surname');

	let type;

	if (address || user) {
		type = DELIVERY;
	} else if (name) {
		type = PICKUP;
	} else {
		type = DISPOSAL;
	}

	const renderText = () => {
		switch (type) {
			case DELIVERY:
				return source === 'customer.address'
					? filterAddressHelpers({ address, user, idProject: record.id })
					: addressToString({ address, user, showName });
			case PICKUP:
				return name;
			case DISPOSAL:
				return '-';
			default:
				return addressToString({ address, user, showName });
		}
	};

	const titleForArticlesProject = () => {
		if (type === DELIVERY && source === 'customer.address') {
			return addressToString({ address, user, showName });
		}
	};

	return (
		<Tooltip title={titleForArticlesProject() || ''}>
			<span>{renderText()}</span>
		</Tooltip>
	);
};

const AddressField = translate(UnconnectedAddressField);

AddressField.defaultProps = {
	record: {},
	source: DEFAULT_SOURCE,
	addLabel: true,
	showName: true
};

export default AddressField;
