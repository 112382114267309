export default ({ isCreate, isEdit }, { list, create, edit, icon, show }) => {
	const result = {
		list,
		icon,
		show
	};

	if (isCreate) {
		result.create = create;
	}

	if (isEdit) {
		result.edit = edit;
	}

	return result;
};
