import { useContext } from 'react';
import Context from './Context';

const useSections = () => {
	const context = useContext(Context);

	if (!context) {
		throw new Error('useSection must be used within an SectionsProvider');
	}

	return context;
};

export default useSections;
