import React from 'react';

import Card from '../Card';

import AddressInput from './AddressInput';

const AddressCard = ({ source, resource, disabled, cardClass, isShowName }) => (
	<Card cardClass={cardClass} source={source} resource={resource}>
		<AddressInput source={source} disabled={disabled} isShowName={isShowName} />
	</Card>
);

AddressCard.defaultProps = {
	source: 'address',
	disabled: false
};

export default AddressCard;
