import { createContext } from 'react';

const Context = createContext({
	isContainerHallMovingMode: false,
	toggleContainerHallMovingMode: () => {},
	isContainerChosen: false,
	selectedContainerId: null,
	setSelectedContainerId: () => null,
	setIsContainerChosen: () => false,
	chooseContainer: () => {},
	sectionIdForNotes: null,
	setSectionIdForNotes: () => null,
	chosenSubsection: {},
	setChosenSubsection: () => {}
});

export default Context;
