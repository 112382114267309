import httpClient from './httpClient';

export default (resource, params) => {
	const form = new FormData();
	form.append('file', params.file);

	const url = `${process.env.REACT_APP_API_URL}/${resource}`;
	const options = {
		method: 'POST',
		body: form
	};

	return httpClient(url, options)
		.then(({ json: url }) => url)
		.catch(() => '');
};
