import { Card as BaseCard, CardHeader, withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import React from 'react';
import { CardContentInner, translate } from 'react-admin';

const styles = theme => ({
	card: {
		maxWidth: theme.breakpoints.values['lg']
	},
	content: {
		'&:last-child': {
			paddingBottom: theme.spacing.unit * 2,
			[theme.breakpoints.up('xs')]: {
				paddingBottom: theme.spacing.unit * 3
			}
		}
	}
});

const Card = ({ source, resource, cardClass, children, classes, translate }) => {
	const label = `resources.${resource}.fields.${source}`;

	return (
		<BaseCard className={`${classes.card} ${cardClass}`}>
			<CardHeader title={translate(label)} />
			<CardContentInner className={classes.content}>{children}</CardContentInner>
		</BaseCard>
	);
};

const enhance = compose(withStyles(styles), translate);

export default enhance(Card);
