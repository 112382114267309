import { isEqual } from 'lodash';
import {
	Typography,
	withStyles,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	CircularProgress,
	SnackbarContent,
	Snackbar
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { Component, Fragment } from 'react';
import {
	withDataProvider,
	DELETE,
	CREATE,
	GET_ONE,
	translate,
	showNotification
} from 'react-admin';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { expandPanel } from '../AC';

import { nextUniqueKey, ConditionalWrapper } from '../helpers';
import { BarcodeView, QRCodeField } from '../components';

import AddSectionButton from './AddSectionButton';
import DeleteSectionButton from './DeleteSectionButton';
import SectionTree from './SectionTree';
import EditSectionButton from './EditSectionButton';
import NoteForm from '../components/NoteForm';

//TODO style bottom border for expanded node
const styles = () => ({
	wrapper: {
		borderTop: '1px #ccc solid',
		'&:first-child': {
			border: 'none'
		}
	},
	detailsRoot: {
		paddingRight: '0px',
		paddingTop: '0px',
		paddingBottom: '0px'
	},
	rootPanel: {
		boxShadow: 'none',
		'&>div:last-child': {
			transition: 'none!important'
		}
	},
	expandToggle: {
		paddingLeft: '0px',
		paddingRight: '0px',
		'&$expanded': {
			borderBottom: '1px #ccc solid',
			minHeight: '48px'
		},
		'&:hover:not(.Mui-disabled)': {
			cursor: 'default'
		}
	},
	hoverToggle: {
		'&:hover:not(.Mui-disabled)': {
			backgroundColor: 'rgba(0, 0, 0, 0.07)',
			cursor: 'pointer'
		}
	},
	expandIcon: {
		right: 'initial',
		left: '0',
		transform: 'translateY(-50%) rotate(-90deg)',
		'&$expanded': {
			transform: 'translateY(-50%) rotate(0deg)'
		}
	},
	expanded: {},
	summary: {
		alignItems: 'center',
		margin: '0',
		'&$expanded': {
			margin: '0'
		}
	},
	children: {
		boxSizing: 'border-box',
		paddingLeft: 0,
		width: '100%'
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
		paddingLeft: '48px',
		paddingRight: '0'
	},
	heading: {
		cursor: 'pointer',
		minWidth: '30px'
	},
	searchedSection: {
		background: 'yellow',
		width: '30px'
	},
	title: {
		cursor: 'pointer',
		width: '40%'
	},
	descriptionBarcode: {
		color: '#000',
		width: '210px !important',
		height: '210px !important',
		position: 'absolute',
		top: -22,
		left: -22,
		fontSize: 10,
		lineHeight: '10px',
		wordBreak: 'break-all',
		'& > div': {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			position: 'absolute',
			width: 190
		},
		'& .snake-0': {
			transform: 'rotate(-90deg)',
			transformOrigin: '0 100%',
			bottom: 20,
			left: 10
		},
		'& .snake-1': {
			transform: 'rotate(-90deg)',
			transformOrigin: '0 100%',
			bottom: 20,
			left: 20
		},
		'& .snake-2': {
			top: 0,
			left: 20
		},
		'& .snake-3': {
			top: 10,
			left: 20
		},
		'& .snake-4': {
			right: 0,
			bottom: -10,
			transform: 'rotate(90deg)',
			transformOrigin: '100% 0'
		},
		'& .snake-5': {
			right: 10,
			bottom: -10,
			transform: 'rotate(90deg)',
			transformOrigin: '100% 0'
		},
		'& .snake-6': {
			bottom: 0,
			left: 0,
			transform: 'rotate(180deg)'
		},
		'& .snake-7': {
			bottom: 10,
			left: 0,
			transform: 'rotate(180deg)'
		}
	},
	'@media print': {
		descriptionBarcode: {
			top: -20,
			left: -20,
			width: '155px !important',
			height: '155px !important',
			lineHeight: '10px',
			fontSize: 10,
			'& > div': {
				width: 134
			}
		}
	},
	descriptionParagraph: {
		margin: 0
	},
	loading: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center'
	},
	loadingSignature: {
		fontSize: '30px',
		marginTop: '20px'
	},
	successNotification: {
		backgroundColor: '#43a047'
	},
	errorNotification: {
		backgroundColor: '#d32f2f'
	},
	infoNotification: {
		backgroundColor: '#ffa000'
	}
});

const MOVABLE = 'movable';
const IMMOVABLE = 'immovable';

const CustomSnackbar = props => {
	const { classes, message, classNameForNotification } = props;

	return (
		<SnackbarContent
			className={classNameForNotification(classes)}
			aria-describedby="client-snackbar"
			message={message}
		/>
	);
};

class SectionNode extends Component {
	section = {};

	constructor(props) {
		super(props);

		const { section: sectionProps } = this.props;
		const section = this.setSection(sectionProps);
		this.state = {
			section,
			sectionIdForNotes: null,
			isLoadingSections: false,
			createdSectionsCount: 0,
			allNewSectionsCount: 0,
			isNotificationShown: false,
			showNoteDialog: false
		};
	}

	UNSAFE_componentWillReceiveProps = ({ section }) => {
		if (!isEqual(this.section, section)) {
			const newSection = this.setSection(section);

			this.setState({
				section: newSection
			});
		}
	};

	setSection(section) {
		const newSection = {
			...section,
			children: section.children.map(child => ({
				...child,
				uniqueKey: nextUniqueKey()
			}))
		};
		this.section = section;

		return newSection;
	}

	cloneSection(section) {
		return {
			...section,
			children: [...section.children]
		};
	}

	messageForNotification = () => {
		const { translate } = this.props;
		if (this.state.createdSectionsCount === this.state.allNewSectionsCount) {
			return translate('ra.notification.sectionCreationSuccess', {
				count: this.state.createdSectionsCount,
				smart_count: this.state.createdSectionsCount === 1 ? 1 : 2
			});
		}
		if (this.state.createdSectionsCount === 0) {
			return translate('ra.notification.sectionCreationFailed', {
				count: this.state.allNewSectionsCount,
				smart_count: this.state.allNewSectionsCount === 1 ? 1 : 2
			});
		}
		const difference = this.state.allNewSectionsCount - this.state.createdSectionsCount;
		return translate('ra.notification.sectionCreationPartiallySuccess', {
			successCount: this.state.createdSectionsCount,
			failedCount: difference
		});
	};

	classNameForNotification = classes => {
		if (this.state.createdSectionsCount === this.state.allNewSectionsCount) {
			return classes.successNotification;
		}
		if (this.state.createdSectionsCount === 0) {
			return classes.errorNotification;
		}
		return classes.infoNotification;
	};

	handleAddSection = async ({ quantity, first, isMovable, ...resource }) => {
		const { section } = this.state;
		const { openedSections, expandPanel, dataProvider, formSection } = this.props;
		const prevSection = this.cloneSection(section);

		/**
		 * If we try to create subsection for main warehouse, we use need to useformSection property.
		 * If we need to create subsection for warehouse's subsection (or subsection for subsection),
		 * we need ti use section property.
		 */

		const parentSection = formSection ? this.cloneSection(formSection) : this.cloneSection(section);

		const { name, area } = resource;

		this.setState({ isLoadingSections: true, allNewSectionsCount: quantity });
		for (let i = first; i < quantity + first; i++) {
			const singleSectionName = quantity > 1 ? name.concat(' ' + i) : name;
			const changedName = name.indexOf('#') > -1 ? name.replace('#', i) : singleSectionName;
			try {
				await dataProvider(CREATE, 'storageareas', {
					data: {
						area,
						name: changedName,
						movable: isMovable,
						storage_order: i - first,
						parent: {
							id: parentSection.id
						}
					}
				});

				this.setState({ createdSectionsCount: this.state.createdSectionsCount + 1 });
			} catch (error) {
				console.error('[warehouse]: subsection creation failed', error);
			}
		}

		try {
			const { data: updatedParentSection } = await dataProvider(GET_ONE, 'storageareas', {
				id: parentSection.id
			});
			this.onChange(prevSection, updatedParentSection);
			if (!openedSections.includes(updatedParentSection.id)) expandPanel(updatedParentSection.id);
		} catch (error) {
			console.error('[warehouse]: getting parent section failed', error);
		}

		this.setState({ isLoadingSections: false, isNotificationShown: true });
	};

	handleChange = (changedChild, changedChildIndex) => {
		const { section } = this.state;
		const prevSection = this.cloneSection(section);

		section.children[changedChildIndex] = changedChild;

		this.onChange(prevSection, section);
	};

	handleDeleteSection = async sectionIndex => {
		const { section } = this.state;
		const { dataProvider } = this.props;
		const prevSection = this.cloneSection(section);
		try {
			await dataProvider(DELETE, 'storageareas', {
				id: section.children[sectionIndex].id
			});
			section.children.splice(sectionIndex, 1);

			const updatedSection = this.cloneSection(section);

			this.onChange(prevSection, updatedSection);
		} catch (error) {
			console.error('[warehouse]: section deletion failed', error);
		}
	};

	handleEditClick = async section => {
		const { section: prevSection } = this.state;
		const { dataProvider } = this.props;

		try {
			const { data: updatedSection } = await dataProvider(CREATE, 'storageareas', {
				data: {
					...section,
					children: prevSection.children
				}
			});

			if (updatedSection) {
				this.onChange(prevSection, updatedSection);
			}
		} catch (error) {
			console.error('[warehouse]: section editing failed', error);
		}
	};

	onChange = (prevSection, section) => {
		const { sectionIndex, onChange } = this.props;

		this.prevSection = prevSection;
		this.setState({ section });

		onChange(section, sectionIndex);
	};

	handleExpand = section => {
		if (section.id) {
			const { expandPanel } = this.props;

			expandPanel(section.id);

			// if (!onExpand) return;

			if (!section.loaded) {
				// onExpand(section.id);

				this.setState({
					section: {
						...section,
						loaded: true
					}
				});
			}
		}
	};

	handleClose = () => {
		this.setState({ isNotificationShown: false, createdSectionsCount: 0, allNewSectionsCount: 0 });
	};

	handleShowNoteDialog = isShown => {
		this.setState({
			showNoteDialog: isShown,
			sectionIdForNotes: isShown ? this.state.section.id : null
		});
	};

	isNewSectionButtonDisabled = (section, type) => {
		if (section.movable || (type === IMMOVABLE && !!section.customers?.length)) return true;

		let isDisabled = false;
		section.children.forEach(child => {
			isDisabled = (child.movable && type === IMMOVABLE) || (!child.movable && type === MOVABLE);
		});
		return isDisabled;
	};

	render() {
		const {
			sectionIndex,
			classes,
			isRoot,
			onDelete,
			path: pathProps,
			disabled,
			onExpand,
			openedSections,
			openSection,
			visible,
			translate,
			search,
			isEdit,
			section: sectionProps
		} = this.props;

		const {
			section,
			createdSectionsCount,
			allNewSectionsCount,
			isLoadingSections,
			isNotificationShown,
			sectionIdForNotes,
			showNoteDialog
		} = this.state;
		const area = section.area ? `(${section.area}m²)` : '';
		const path = !isRoot ? [...pathProps, `${section.name} ${area}`] : [...pathProps];

		const opened = () => {
			if (openedSections === undefined) return;
			if (!section.id) return true;
			return openedSections.includes(section.id);
		};

		const isExpansion = !isRoot && section.children.length > 0;

		const isQRShown =
			section.id &&
			(section.children.length === 0 || !!section.children.every(child => child.movable));

		if (isLoadingSections) {
			return (
				<div className={classes.loading}>
					<CircularProgress size={80} />
					<div className={classes.loadingSignature}>
						{translate('ra.message.loadingPrimary', {
							count: createdSectionsCount,
							countOfAll: allNewSectionsCount
						})}
					</div>
				</div>
			);
		}

		if (!section || !visible) return null;

		const subheadingClass = search?.idsSearch?.includes(section?.id)
			? classes.searchedSection
			: classes.heading;

		return (
			<div className={!isRoot ? classes.wrapper : ''}>
				{
					<ConditionalWrapper
						condition={isExpansion}
						wrapper={children => (
							<ExpansionPanel
								onChange={() => this.handleExpand(section)}
								expanded={opened()}
								defaultExpanded={!section.id}
								classes={{ root: classes.rootPanel }}
							>
								{children}
							</ExpansionPanel>
						)}
					>
						<ExpansionPanelSummary
							expandIcon={isExpansion && <ExpandMoreIcon />}
							classes={{
								content: classes.summary,
								expandIcon: classes.expandIcon,
								root: isExpansion
									? classes.expandToggle + ' ' + classes.hoverToggle
									: classes.expandToggle,
								expanded: classes.expanded
							}}
						>
							{!isRoot && (
								<div className={classes.row}>
									<Typography
										variant="subheading"
										className={subheadingClass}
										onClick={() => {
											if (section?.children?.length) return;
											this.setState({
												sectionIdForNotes: section?.id
											});
											this.handleShowNoteDialog(true);
										}}
									>
										{section.name} {!!section.area && <span>({section.area}m²)</span>}
									</Typography>
									<div>
										{isQRShown && (
											<QRCodeField
												source="value"
												size={32}
												record={{
													value: JSON.stringify({
														storagearea: section.id,
														name: section.name
													})
												}}
												description={
													<div className={classes.descriptionBarcode}>
														{path.map((el, ind) => {
															return (
																<div className={`snake-${ind}`} key={ind}>
																	{el}
																</div>
															);
														})}
													</div>
												}
												view={<BarcodeView size={248} />}
											/>
										)}
										{!disabled && (
											<Fragment>
												<EditSectionButton section={section} onEdit={this.handleEditClick} />
												<AddSectionButton
													section={section}
													onAdd={this.handleAddSection}
													label="ra.action.immovable_subsection"
													disabled={this.isNewSectionButtonDisabled(section, IMMOVABLE)}
												/>
												<AddSectionButton
													section={section}
													onAdd={resource =>
														this.handleAddSection({ ...resource, isMovable: true })
													}
													label="ra.action.movable_subsection"
													isMovable={true}
													disabled={this.isNewSectionButtonDisabled(section, MOVABLE)}
												/>
												<DeleteSectionButton
													section={section}
													onConfirm={() => onDelete(sectionIndex)}
												/>
											</Fragment>
										)}
									</div>
								</div>
							)}
						</ExpansionPanelSummary>
						<ConditionalWrapper
							condition={isExpansion}
							wrapper={children => (
								<ExpansionPanelDetails classes={{ root: classes.detailsRoot }}>
									{children}
								</ExpansionPanelDetails>
							)}
						>
							{section.children.length > 0 && (
								<SectionTree
									parent={section}
									className={!isRoot && classes.children}
									visible={section.level === 2 || opened()}
									classes={classes}
									onDeleteSection={this.handleDeleteSection}
									onExpand={onExpand}
									onChange={this.handleChange}
									path={path}
									disabled={disabled}
									openSection={openSection}
									isLoadingSections={isLoadingSections}
									createdSectionsCount={createdSectionsCount}
									allNewSectionsCount={allNewSectionsCount}
									isEdit={isEdit}
								>
									{section.children}
								</SectionTree>
							)}
						</ConditionalWrapper>
					</ConditionalWrapper>
				}
				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center'
					}}
					open={isNotificationShown}
					autoHideDuration={5000}
					onClose={this.handleClose}
				>
					<CustomSnackbar
						classes={classes}
						classNameForNotification={this.classNameForNotification}
						message={this.messageForNotification()}
					/>
				</Snackbar>
				<NoteForm
					size="md"
					title="resources.warehouses.action.add_comment"
					showDialog={showNoteDialog}
					setSectionIdForNotes={this.handleShowNoteDialog}
					sectionIdForNotes={sectionIdForNotes}
					sections={[sectionProps]}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	openedSections: state.openedSections
});

const enhance = compose(withDataProvider, translate, withStyles(styles));

export default enhance(
	connect(
		mapStateToProps,
		{
			expandPanel,
			showNotification
		},
		null,
		{ withRef: true }
	)(SectionNode)
);
