import {
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Typography,
	withStyles
} from '@material-ui/core';
import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { expandPanel } from '../../AC';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import DragIcon from '@material-ui/icons/DragHandle';
import { BarcodeView, QRCodeField } from '../../components';
import { useMovingMode } from './context';
import { useSearch } from '../searchContext';

const styles = () => ({
	wrapper: {
		'&+$wrapper': {
			borderTop: '1px #ccc solid'
		}
	},
	detailsRoot: {
		paddingRight: '0px',
		paddingTop: '0px',
		paddingBottom: '0px'
	},
	rootPanel: {
		boxShadow: 'none',
		'&>div:last-child': {
			transition: 'none!important',
			overflow: 'visible'
		}
	},
	expandToggle: {
		paddingLeft: '0px',
		paddingRight: '0px',
		'&$expanded': {
			borderBottom: '1px #ccc solid',
			minHeight: '48px'
		},
		'&:hover:not(.Mui-disabled)': {
			cursor: 'default'
		}
	},
	hoverToggle: {
		'&:hover:not(.Mui-disabled)': {
			backgroundColor: 'rgba(0, 0, 0, 0.07)',
			cursor: 'pointer'
		}
	},
	expandIcon: {
		right: 'initial',
		left: '0',
		transform: 'translateY(-50%) rotate(-90deg)',
		'&$expanded': {
			transform: 'translateY(-50%) rotate(0deg)'
		}
	},
	expanded: {},
	summary: {
		alignItems: 'center',
		margin: '0',
		'&$expanded': {
			margin: '0'
		},
		'& > :last-child': {
			paddingRight: '48px'
		}
	},
	children: {
		boxSizing: 'border-box',
		paddingLeft: 0,
		width: '100%'
	},
	disabled: {},
	row: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '0 48px',
		width: '100%',
		minHeight: '48px',
		boxSizing: 'border-box',

		'&$disabled': {
			opacity: 0.2,
			pointerEvents: 'none'
		}
	},
	descriptionParagraph: {
		margin: 0
	},
	draggableWrapper: {
		position: 'relative'
	},
	draggableIcon: {
		width: '48px',
		height: '48px',
		position: 'absolute',
		top: 0,
		right: 0,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	searchedSection: {
		background: 'yellow'
	},
	searchedSectionH3: {
		'& h3': {
			background: 'yellow'
		}
	},
	descriptionBarcode: {
		color: '#000',
		width: '210px !important',
		height: '210px !important',
		position: 'absolute',
		top: -22,
		left: -22,
		fontSize: 10,
		lineHeight: '10px',
		wordBreak: 'break-all',
		'& > div': {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			position: 'absolute',
			width: 190
		},
		'& .snake-0': {
			transform: 'rotate(-90deg)',
			transformOrigin: '0 100%',
			bottom: 20,
			left: 10
		},
		'& .snake-1': {
			transform: 'rotate(-90deg)',
			transformOrigin: '0 100%',
			bottom: 20,
			left: 20
		},
		'& .snake-2': {
			top: 0,
			left: 20
		},
		'& .snake-3': {
			top: 10,
			left: 20
		},
		'& .snake-4': {
			right: 0,
			bottom: -10,
			transform: 'rotate(90deg)',
			transformOrigin: '100% 0'
		},
		'& .snake-5': {
			right: 10,
			bottom: -10,
			transform: 'rotate(90deg)',
			transformOrigin: '100% 0'
		},
		'& .snake-6': {
			bottom: 0,
			left: 0,
			transform: 'rotate(180deg)'
		},
		'& .snake-7': {
			bottom: 10,
			left: 0,
			transform: 'rotate(180deg)'
		}
	},
	'@media print': {
		descriptionBarcode: {
			top: -20,
			left: -20,
			width: '155px !important',
			height: '155px !important',
			lineHeight: '10px',
			fontSize: 10,
			'& > div': {
				width: 134
			}
		}
	}
});

const generatePath = (path, section) => {
	const area = section.area ? `(${section.area}m²)` : '';
	return [...path, `${section.name} ${area}`];
};

const CommonSection = ({ section, classes, path, isDisabled }) => {
	const showQRCode =
		section.children.length === 0 || section.children.every(child => child.movable);
	const { idsSearch } = useSearch();

	const renderQRCode = () => (
		<QRCodeField
			source="value"
			size={32}
			record={{
				value: JSON.stringify({
					storagearea: section.id,
					name: section.name
				})
			}}
			description={
				<div className={classes.descriptionBarcode}>
					{path.map((el, ind) => (
						<div className={`snake-${ind}`} key={ind}>
							{el}
						</div>
					))}
				</div>
			}
			view={<BarcodeView size={248} />}
		/>
	);

	const className = [classes.row, ...(isDisabled ? [classes.disabled] : [])].join(' ');

	const subheadingClass = idsSearch?.includes(section.id) ? classes.searchedSection : '';

	return (
		<div className={className}>
			<Typography variant="subheading" className={subheadingClass}>
				{section.name} {!!section.area && <span>({section.area}m²)</span>}
			</Typography>
			{showQRCode && renderQRCode()}
		</div>
	);
};

const DraggableSection = ({ section, classes, index, path }) => {
	const { idsSearch } = useSearch();

	const subheadingClass = idsSearch?.includes(section.id) ? classes.searchedSectionH3 : '';

	return (
		<Draggable draggableId={section.id.toString()} index={index}>
			{(provided, snapshot) => (
				<div
					className={[
						classes.wrapper,
						subheadingClass,
						classes.draggableWrapper,
						...(snapshot.isDragging ? [classes.sectionActive] : [])
					].join(' ')}
					ref={provided.innerRef}
					{...provided.draggableProps}
				>
					<CommonSection section={section} classes={classes} path={path} isDisabled={false} />
					<div className={classes?.draggableIcon ?? ''} {...provided.dragHandleProps}>
						<DragIcon color={snapshot.isDragging ? 'action' : 'default'} />
					</div>
				</div>
			)}
		</Draggable>
	);
};

const ExpandableSection = ({ section, classes, openedSections, expandPanel, path, isDisabled }) => {
	const isExpanded = openedSections.includes(section.id);

	return (
		<ExpansionPanel
			expanded={isExpanded}
			onChange={() => expandPanel(section.id)}
			defaultExpanded={false}
			classes={{ root: classes.rootPanel }}
		>
			<ExpansionPanelSummary
				expandIcon={<ExpandMoreIcon />}
				classes={{
					content: classes.summary,
					expandIcon: classes.expandIcon,
					root: [classes.expandToggle, classes.hoverToggle].join(' '),
					expanded: classes.expanded
				}}
			>
				<CommonSection section={section} classes={classes} path={path} isDisabled={isDisabled} />
			</ExpansionPanelSummary>
			{!!isExpanded && (
				<ExpansionPanelDetails classes={{ root: classes.detailsRoot }}>
					<div className={classes.children}>
						{section.children.map((child, index) => (
							<ConnectedSection
								section={child}
								classes={classes}
								key={child.id}
								parent={section}
								index={index}
								path={path}
							/>
						))}
					</div>
				</ExpansionPanelDetails>
			)}
		</ExpansionPanel>
	);
};

const Section = ({ section, classes, openedSections, expandPanel, parent, index, path }) => {
	const { isDragging } = useMovingMode();
	const isExpandable = section.children?.length > 0;
	const isDraggable = !!section?.movable;
	const isDroppable = !!section.children?.every(element => element.movable);
	const updatedPath = generatePath(path, section);
	const isDisabled = isDragging && !isDraggable && !isDroppable;

	if (isDraggable) {
		return (
			<DraggableSection section={section} classes={classes} index={index} path={updatedPath} />
		);
	}
	const sectionCore = isExpandable ? (
		<ExpandableSection
			section={section}
			classes={classes}
			openedSections={openedSections}
			expandPanel={expandPanel}
			parent={parent}
			path={updatedPath}
			isDisabled={isDisabled}
		/>
	) : (
		<CommonSection section={section} classes={classes} path={updatedPath} isDisabled={isDisabled} />
	);

	if (isDroppable) {
		return (
			<Droppable droppableId={section.id.toString()}>
				{provided => (
					<div ref={provided.innerRef} {...provided.droppableProps} className={classes.wrapper}>
						{sectionCore}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		);
	}
	return <div className={classes.wrapper}>{sectionCore}</div>;
};

const mapStateToProps = state => ({ openedSections: state.openedSections });

const enhance = compose(withStyles(styles), connect(mapStateToProps, { expandPanel }));

const ConnectedSection = enhance(Section);

export default ConnectedSection;
