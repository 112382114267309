import React from 'react';
import { Show } from 'react-admin';

import { BodyMobileGrid } from './MobileGrid';
import ProjectTitle from './ProjectTitle';

const ProjectShow = ({ permissions, ...props }) => (
	<Show title={<ProjectTitle />} actions={null} {...props}>
		<BodyMobileGrid isEmbedComments={true} isShowProjectArea={true} permissions={permissions} />
	</Show>
);

export default ProjectShow;
