import { withStyles } from '@material-ui/core';
import React from 'react';
import { SimpleForm, Toolbar } from 'react-admin';

import { AddressCard, ToolbarContainer } from '../components';
import generalStyles from '../styles';

import UserCard from './UserCard';

const styles = theme => ({
	container: {
		width: '100%',
		maxWidth: theme.breakpoints.values['lg']
	},
	input: {
		marginTop: theme.spacing.unit * 2,
		[theme.breakpoints.up('xs')]: {
			marginTop: theme.spacing.unit * 3
		}
	},
	spacer: generalStyles(theme).spacer
});

const AgentForm = ({ classes, permissions, record, ...props }) => (
	<SimpleForm
		{...props}
		redirect={record.id ? false : 'edit'}
		record={record}
		toolbar={
			<Toolbar classes={{ spacer: classes.spacer }}>
				<ToolbarContainer maxWidth="lg" />
			</Toolbar>
		}
	>
		<UserCard source="user" cardClass={classes.input} permissions={permissions} isAgent={true} />
		<AddressCard cardClass={classes.input} isShowName={false} />
	</SimpleForm>
);

export default withStyles(styles)(AgentForm);
