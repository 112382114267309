import { createContext } from 'react';

const Context = createContext({
	idsSearch: [],
	setIdsSearch: () => [],
	searchedFields: {},
	setSearchedFields: () => {}
});

export default Context;
