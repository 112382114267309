import { keyBy } from 'lodash';
import React, { useState, useEffect } from 'react';
import {
	DateField,
	NumberField,
	Responsive,
	DELETE,
	UPDATE_MANY,
	withDataProvider,
	Pagination,
	translate,
	showNotification,
	CREATE
} from 'react-admin';
import { connect } from 'react-redux';
import OutsourceButton from './OutsourceButton';
import { AddressField, Datagrid } from '../components';
import styles from '../styles';
import ExpandDelivery from './ExpandDelivery';
import MobileGrid from './MobileGrid';
import TypeField from './TypeField';
import { Toolbar, withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { lightTheme } from '../layout/themes';
import httpClient from '../providers/httpClient';

import { OUTSOURCED, CONFIRMED } from '../deliveries/statuses';
import { push } from 'react-router-redux';
import { ROLE_AGENT } from '../users/roles';

const DeliveryList = ({
	classes,
	permissions,
	scannedBarcodes,
	dataProvider,
	dispatch,
	showNotification,
	fetchArticleByBarcode,
	fetchDeliveryByArticle,
	fetchArticlesToDelivery,
	setScannedBarcodes,
	location,
	updateDeliveries,
	deliveries,
	justScannedDeliveries,
	perPage,
	translate,
	page,
	total
}) => {
	const [state, setState] = useState({
		deliveries: [],
		page: 1,
		perPage: 10
	});
	const [barcodeHere, setBarcodeHere] = useState(undefined);

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const barcodeId = parseInt(searchParams.get('barcode'));
		if (!barcodeId) {
			const { page, perPage } = state;
			updateDeliveries(page, perPage);
			return;
		}
		fetchArticleByBarcode(barcodeId)
			.then(async article => {
				if (article === null) return;
				const delivery = await fetchDeliveryByArticle(article.id);
				if (!delivery) return;
				const response = await httpClient(
					`${process.env.REACT_APP_API_URL}/deliveries/${delivery.id}/page?limit=${state.perPage}&sort[id]=DESC&filter[status][ne]=${CONFIRMED}`,
					{
						method: 'GET'
					}
				);
				if (!response) return;
				setBarcodeHere(delivery.id);
				handlePageChange(response.json.page);
			})
			.catch(() => showNotification('ra.notification.warning', 'warning'));
	}, []);

	useEffect(() => {
		setState({ deliveries: [...deliveries], page, perPage, total });
	}, [deliveries]);

	const handlePageChange = async page => {
		const { perPage } = state;
		await updateDeliveries(page, perPage);
	};

	const handlePerPageChange = async perPage => {
		await updateDeliveries(1, perPage);
	};

	const handleConfirmRemove = async (deliveryId, articleId) => {
		dataProvider(DELETE, `deliveries/${deliveryId}/articles`, {
			id: articleId
		}).then(() => {
			showNotification('ra.notification.removed', 'success');
			const { perPage, page } = state;
			updateDeliveries(page, perPage);
		});
	};

	const handleOutsource = (deliveryId, articles) => {
		const { perPage, page } = state;
		const articlesIds = articles.map(({ id }) => id);

		dataProvider(UPDATE_MANY, 'deliveries', {
			ids: [deliveryId],
			data: {
				status: OUTSOURCED,
				articles: [...articlesIds]
			}
		}).then(() => {
			const outsourcedBarcodes = articles
				.map(({ barcodes }) => barcodes.map(({ id }) => id))
				.reduce((a, b) => [...a, ...b], []);
			const newScannedBarcodes = scannedBarcodes.filter(id => !outsourcedBarcodes.includes(id));
			const projectIds = articles.map(({ project }) => project);
			const projectId = projectIds[0];
			setScannedBarcodes(newScannedBarcodes);
			showNotification('ra.notification.outsourced', 'success');
			updateDeliveries(page, perPage);
			dispatch(push(`/projects/${projectId}`));
		});
	};

	const exchange = async (value, deliveryId, oldArticleId) => {
		const [, search] = value.split('?');

		const searchParams = new URLSearchParams(search);
		const barcodeId = parseInt(searchParams.get('barcode'));

		const article = await fetchArticleByBarcode(barcodeId);
		if (article !== null) {
			dataProvider(
				CREATE,
				`deliveries/${deliveryId}/articles/${oldArticleId}/exchanges/${article.id}`,
				{}
			)
				.then(() => {
					showNotification('ra.notification.exchanged', 'success');
					const { perPage, page } = state;
					updateDeliveries(page, perPage);
				})
				.catch(err => {
					showNotification(err.message, 'warning');
				});
		}
	};

	const deliveryRowStyle = ({ id }) => {
		if (id === barcodeHere) {
			return {
				backgroundColor: lightTheme.palette.primary.barcodeHere
			};
		}
	};

	return (
		<>
			{!state.total ? (
				<div style={{ marginLeft: '30px' }}>{translate('resources.outsourcing.no_results')}</div>
			) : (
				<>
					{!!justScannedDeliveries.length && (
						<div style={{ marginBottom: '60px' }}>
							<div style={{ marginLeft: '30px' }}>
								{translate('resources.outsourcing.just_scanned')}
							</div>
							<Responsive
								medium={
									<MobileGrid
										permissions={permissions}
										ids={justScannedDeliveries.map(({ id }) => id)}
										data={keyBy(justScannedDeliveries, 'id')}
										selectedIds={[]}
										currentSort={{ field: 'date', order: 'ASC' }}
										handleOutsource={handleOutsource}
										scannedBarcodes={scannedBarcodes}
										handleConfirmRemove={handleConfirmRemove}
										exchange={exchange}
										barcodeHere={barcodeHere}
										deliveryList={state.deliveries}
										fetchArticlesToDelivery={fetchArticlesToDelivery}
										isOutsourcingButtonShown={true}
									/>
								}
								large={
									<Datagrid
										rowStyle={deliveryRowStyle}
										ids={justScannedDeliveries.map(({ id }) => id)}
										data={keyBy(justScannedDeliveries, 'id')}
										currentSort={{ field: 'date', order: 'ASC' }}
										expand={
											<ExpandDelivery
												permissions={permissions}
												scannedBarcodes={scannedBarcodes}
												handleConfirmRemove={handleConfirmRemove}
												exchange={exchange}
												parentState={state}
											/>
										}
									>
										<NumberField source="id" sortable={false} />
										<AddressField
											showName={true}
											sortBy="address"
											label="resources.outsourcing.fields.recipient"
											headerClassName={classes.fullWidth}
											sortable={false}
										/>
										<TypeField
											sortBy="address"
											label="resources.outsourcing.fields.type"
											headerClassName={classes.fullWidth}
											sortable={false}
										/>
										<DateField
											showTime={true}
											source="date"
											label="resources.outsourcing.fields.date"
											className={classes.nowrap}
											sortable={false}
										/>
										<OutsourceButton
											handleOutsource={handleOutsource}
											scannedBarcodes={scannedBarcodes}
											permissions={permissions}
											deliveryList={justScannedDeliveries}
											fetchArticlesToDelivery={fetchArticlesToDelivery}
										/>
									</Datagrid>
								}
							/>
						</div>
					)}
					{!!state.deliveries.length && (
						<div>
							<div style={{ marginLeft: '30px' }}>{translate('resources.outsourcing.name')}</div>
							<Responsive
								medium={
									<MobileGrid
										permissions={permissions}
										ids={state.deliveries.map(({ id }) => id)}
										data={keyBy(state.deliveries, 'id')}
										selectedIds={[]}
										currentSort={{ field: 'date', order: 'ASC' }}
										handleOutsource={handleOutsource}
										scannedBarcodes={scannedBarcodes}
										handleConfirmRemove={handleConfirmRemove}
										exchange={exchange}
										barcodeHere={barcodeHere}
										deliveryList={state.deliveries}
										fetchArticlesToDelivery={fetchArticlesToDelivery}
									/>
								}
								large={
									<Datagrid
										rowStyle={deliveryRowStyle}
										ids={state.deliveries.map(({ id }) => id)}
										data={keyBy(state.deliveries, 'id')}
										currentSort={{ field: 'date', order: 'ASC' }}
										expand={
											<ExpandDelivery
												permissions={permissions}
												scannedBarcodes={scannedBarcodes}
												handleConfirmRemove={handleConfirmRemove}
												exchange={exchange}
												parentState={state}
											/>
										}
									>
										<NumberField source="id" sortable={false} />
										<AddressField
											showName={true}
											sortBy="address"
											label="resources.outsourcing.fields.recipient"
											headerClassName={classes.fullWidth}
											sortable={false}
										/>
										<TypeField
											sortBy="address"
											label="resources.outsourcing.fields.type"
											headerClassName={classes.fullWidth}
											sortable={false}
										/>
										<DateField
											showTime={true}
											source="date"
											label="resources.outsourcing.fields.date"
											className={classes.nowrap}
											sortable={false}
										/>
										{permissions === ROLE_AGENT && (
											<OutsourceButton
												handleOutsource={handleOutsource}
												scannedBarcodes={scannedBarcodes}
												permissions={permissions}
												deliveryList={state.deliveries}
												fetchArticlesToDelivery={fetchArticlesToDelivery}
											/>
										)}
									</Datagrid>
								}
							/>
						</div>
					)}
					{state.total > 0 && (
						<Toolbar className={classes.toolbar}>
							<Pagination
								total={state.total}
								page={state.page}
								perPage={state.perPage}
								setPage={handlePageChange}
								setPerPage={handlePerPageChange}
							/>
						</Toolbar>
					)}
				</>
			)}
		</>
	);
};

const enhance = compose(
	withStyles(styles),
	translate,
	withDataProvider,
	connect(undefined, { showNotification })
);

export default enhance(DeliveryList);
