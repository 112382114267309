import { CircularProgress, Tooltip, withStyles } from '@material-ui/core';
import { Button, translate, withDataProvider } from 'react-admin';
import { Add } from '@material-ui/icons';
import React, { useEffect } from 'react';
import Columns from './Columns';
import { compose } from 'recompose';
import { useSections } from '../../sectionsContext';

const styles = {
	row: {
		display: 'flex',
		margin: '20px 0',
		alignItems: 'flex-end'
	},
	titleWrapper: {
		width: '100px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: '3',
		position: 'relative',
		background: 'white',
		borderRadius: '10px'
	},
	title: {
		width: '100%',
		fontSize: '50px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute'
	},
	columnsBlock: {
		display: 'flex'
	},
	column: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
		backgroundColor: '#eae9e9',
		borderRadius: '10px',
		position: 'relative',
		marginTop: '20px',
		padding: '10px 0'
	},
	addColumnButton: {
		width: '40px',
		minWidth: '40px',
		height: '100%',
		backgroundColor: '#eae9e9',
		color: 'black',
		borderRadius: '10px',
		'&:hover': {
			backgroundColor: '#4eabfc'
		}
	}
};

const Rows = ({
	classes,
	translate,
	isEditMode,
	record,
	addNewRow,
	handleAddNewColumn,
	isAddNewSectionDisabled
}) => {
	const { sections } = useSections();

	useEffect(() => {
		// Workaround for cases when there is children in record but no rows yet
		if (!sections.length && !record.children.length) {
			addNewRow();
		}
	}, []);

	return sections.map((child, index) => {
		return (
			<div key={child.id} data-row className={classes.row}>
				<div data-title-wrapper className={classes.titleWrapper}>
					<span className={classes.title}>{index + 1}</span>
				</div>
				<div className={classes.columnsBlock}>
					<Columns isEditMode={isEditMode} rowIndex={index + 1}>
						{child.children}
					</Columns>
				</div>
				{isEditMode && (
					<Tooltip title={translate('resources.warehouses.tooltips.add_new_column')}>
						<span className="add-column-button-wrapper">
							<Button
								aria-label="Add"
								className={classes.addColumnButton}
								disabled={isAddNewSectionDisabled}
								onClick={() => {
									if (!isAddNewSectionDisabled) handleAddNewColumn(child);
								}}
							>
								{isAddNewSectionDisabled ? <CircularProgress size={20} thickness={2} /> : <Add />}
							</Button>
						</span>
					</Tooltip>
				)}
			</div>
		);
	});
};

const enhance = compose(translate, withStyles(styles), withDataProvider);

export default enhance(Rows);
