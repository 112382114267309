import { get } from 'lodash';
import { Card, CardHeader, withStyles } from '@material-ui/core';
import React from 'react';
import { CardContentInner, TextInput, translate } from 'react-admin';
import { compose } from 'recompose';
import { required } from '../validations';

import { Measurement } from '../components';
import { configStyles } from '../helpers';
import generalStyles from '../styles';

export const styles = theme => {
	const { gutters, upSmBreakpoint, guttersSize, upSmGuttersSize, mediaMinWidth } = configStyles(
		theme
	);

	return {
		...generalStyles(theme),
		root: {
			...gutters,
			width: `calc(100% - ${guttersSize}px)`,
			[upSmBreakpoint]: {
				...gutters[upSmBreakpoint],
				width: `calc(100% - ${upSmGuttersSize}px)`
			},
			[theme.breakpoints.down(mediaMinWidth)]: {
				maxWidth: theme.breakpoints.values['md']
			}
		},
		totalArea: {
			fontSize: '1.2rem',
			lineHeight: '1.35417em'
		},
		header: {
			paddingBottom: 0
		},
		projectAreas: {
			marginTop: 16
		},
		titleProjectAreas: {
			color: theme.palette.primary.special,
			fontSize: '0.8rem'
		},
		contentProjectAreas: {
			paddingLeft: 24
		}
	};
};

const ProjectAreas = ({ translate, classes, record, className, resource }) => {
	const areas = get(record, 'project_areas', []);
	const totalArea = get(record, 'area', 0);

	return (
		<Card source="area" resource={resource} className={`${classes.root} ${className}`}>
			<CardHeader
				className={classes.header}
				title={
					<span>
						{translate('resources.projects.fields.area')}{' '}
						<span className={classes.totalArea}>
							({translate('resources.projects.fields.total_area')}: {totalArea}m²)
						</span>
					</span>
				}
			/>
			<CardContentInner>
				{areas.length > 0 && (
					<div className={classes.container}>
						{areas.map(({ id, storage_area: { full_name }, area, transfer_tools }, index) => (
							<div key={id} className={`${classes.input} ${classes.projectAreas}`}>
								<div className={classes.titleProjectAreas}>{full_name}</div>
								<div className={classes.contentProjectAreas}>
									<Measurement
										source={`areas[${index}]`}
										record={record}
										resource={resource}
										label="resources.project_areas.fields.area"
										className={classes.fullWidth}
										measure="m²"
										defaultValue={area}
										validate={required(resource, 'area')}
									/>
									<TextInput
										source={`transferTools[${index}]`}
										resource={resource}
										record={record}
										label="resources.general.fields.transfer_tools"
										className={classes.fullWidth}
										defaultValue={transfer_tools}
									/>
								</div>
							</div>
						))}
					</div>
				)}

				<Measurement
					source="measurements"
					resource={resource}
					className={classes.fullWidth}
					measure="m³"
				/>
			</CardContentInner>
		</Card>
	);
};

const enhance = compose(withStyles(styles), translate);

export default enhance(ProjectAreas);
