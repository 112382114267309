import { withStyles } from '@material-ui/core';
import React from 'react';
import { TextInput, translate, withDataProvider } from 'react-admin';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';
import { CONTAINER, CUSTOMER, NOTE, ORDER_NUMBER } from './searchContext/constants';

const styles = {
	inputsBlock: {
		display: 'flex',
		width: '100%',
		'& div': {
			marginRight: '30px'
		}
	}
};

const WAREHOUSES_FIELDS = [CONTAINER, CUSTOMER, NOTE, ORDER_NUMBER];

const WarehousesActions = ({ translate, handleChangeFiledContent, classes }) => {
	return (
		<div className={classes.inputsBlock}>
			{WAREHOUSES_FIELDS.map((warehouse, index) => {
				return (
					<TextInput
						key={`${warehouse}_${index}`}
						label={translate(`pos.search.${warehouse.toLocaleLowerCase()}`)}
						placeholder={translate(`pos.search.${warehouse.toLocaleLowerCase()}`)}
						alwaysOn
						onChange={(event, name) => handleChangeFiledContent(event, name, warehouse)}
						source={warehouse}
					/>
				);
			})}
		</div>
	);
};

const DecoratedForm = reduxForm({
	form: 'wh-search-form',
	destroyOnUnmount: false
});

const enhance = compose(withStyles(styles), translate, withDataProvider, DecoratedForm);

export default enhance(WarehousesActions);
