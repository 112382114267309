import React from 'react';
import { NumberField, SimpleShowLayout } from 'react-admin';

import { MobileGrid as BaseMobileGrid, UpdatedAtField } from '../components';

import AreaField from './AreaField';
import { ROLE_WAREHOUSE_MANAGER, ROLE_OFFICE_EMPLOYEE } from '../users/roles';

const TitleMobileGrid = ({ record }) => (
	<span>
		#{record.id}. {record.name}
	</span>
);

const BodyMobileGrid = ({ record, className, ...props }) => (
	<SimpleShowLayout className={className} record={record} resource="storageareas" {...props}>
		{record.total_area && <NumberField source="total_area" component={AreaField} />}
		{record.filled_area && <NumberField source="filled_area" component={AreaField} />}

		<UpdatedAtField />
	</SimpleShowLayout>
);

const MobileGrid = ({ permissions, ...props }) => (
	<BaseMobileGrid
		{...props}
		permissions={permissions}
		title={<TitleMobileGrid />}
		body={<BodyMobileGrid />}
		deletable={permissions !== ROLE_WAREHOUSE_MANAGER && permissions !== ROLE_OFFICE_EMPLOYEE}
		editable={permissions !== ROLE_WAREHOUSE_MANAGER && permissions !== ROLE_OFFICE_EMPLOYEE}
		isShow={permissions === ROLE_WAREHOUSE_MANAGER || permissions === ROLE_OFFICE_EMPLOYEE}
	/>
);

export default MobileGrid;
