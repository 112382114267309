import { withStyles, Switch, FormControlLabel } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
	CardActions,
	GET_ONE,
	Show,
	SimpleShowLayout,
	translate,
	withDataProvider
} from 'react-admin';
import { compose } from 'recompose';
import httpClient from '../providers/httpClient';
import Search from './Search';
import { CONTAINER, CUSTOMER, NOTE, ORDER_NUMBER } from './searchContext/constants';

import Structure from './Structure';
import { nextUniqueKey, showAlertHelper } from '../helpers';
import { MovingModeProvider, useMovingMode } from './movingMode/context';
import { default as MovingModeLayout } from './movingMode/Layout';
import { default as ContainerHallShowLayout } from './containerHall/ShowLayout';
import { Dialog } from '../components';
import { ROLE_SUPER_ADMIN, ROLE_WAREHOUSE_MANAGER } from '../users/roles';
import { CONTAINERHALL } from './containerHall/constants';
import { ContainerHallModeProvider } from './containerHall/context';
import { SectionsProvider } from './sectionsContext';
import { SearchProvider } from './searchContext';

const WarehouseTitle = ({ record }) => (
	<span>
		#{record.id}. {record.name}
	</span>
);

const findStorageArea = (obj, storageArea) => {
	if (
		typeof obj === 'object' &&
		obj.children &&
		Array.isArray(obj.children) &&
		obj.children.length > 0
	) {
		const area = obj.children.find(item => item.id === storageArea);

		if (area) {
			return area;
		} else {
			let result;
			for (let child of obj.children) {
				result = findStorageArea(child, storageArea);
				if (result) {
					break;
				}
			}
			return result;
		}
	}
};

const styles = theme => ({
	inputs: {
		display: 'flex',
		width: '80%',
		flexDirection: 'column'
	},
	searchBlock: {
		display: 'flex',
		gap: '30px'
	},
	nameField: {
		minWidth: '200px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		'& label': {
			color: 'rgba(0, 0, 0, 0.54)',
			padding: 0,
			fontSize: '0.8rem',
			lineHeight: 1
		},
		'& span': {
			color: 'rgba(0, 0, 0, 0.87)',
			fontSize: '1rem',
			fontWeight: '400',
			lineHeight: '1.46429em'
		}
	},
	container: {
		width: '100%',
		maxWidth: theme.breakpoints.values['md']
	},
	structure: {
		marginTop: theme.spacing.unit * 3
	}
});

const UnconnectedWrapWarehouseShow = ({ record, dataProvider, classes, translate }) => {
	const [state, setState] = useState({
		children: (record.children ?? []).map(child => ({
			...child,
			uniqueKey: nextUniqueKey()
		})),
		opened: []
	});

	const handlePreload = async storageId => {
		const stateCopy = JSON.parse(JSON.stringify(state));

		const storageArea = findStorageArea(stateCopy, storageId);

		if (storageArea?.children?.length > 0) {
			storageArea.children = await Promise.all(
				storageArea.children.map(async child => {
					if (!child.id) return { ...child };

					const { data } = await dataProvider(GET_ONE, 'storageareas', {
						id: child.id
					});

					return {
						...child,
						children: data.children
					};
				})
			);

			handleChangeStructure(stateCopy.children);
		}
	};

	const handleOpenSection = sectionId => {
		const newOpened = state.opened.includes(sectionId)
			? state.opened.filter(item => item !== sectionId)
			: state.opened.concat(sectionId);

		setState({
			opened: newOpened
		});
	};

	const handleChangeStructure = children => {
		setState({ children });
	};

	return (
		<SimpleShowLayout record={record}>
			<div className={classes.inputs}>
				<div className={classes.nameField}>
					<label htmlFor="name">{translate('resources.warehouses.data.name')}</label>
					<span>{record.name}</span>
				</div>
				<div className={classes.searchBlock}>
					<Search sections={state?.children} searchBy={CONTAINER} rootId={record?.id} />
					<Search sections={state?.children} searchBy={CUSTOMER} rootId={record?.id} />
					<Search sections={state?.children} searchBy={ORDER_NUMBER} rootId={record?.id} />
					<Search sections={state?.children} searchBy={NOTE} rootId={record?.id} />
				</div>
			</div>

			<Structure
				className={`${classes.container} ${classes.structure}`}
				name={state?.name}
				disabled={true}
				onExpand={handlePreload}
				openSection={handleOpenSection}
				openedSections={state.opened}
			>
				{state?.children}
			</Structure>
		</SimpleShowLayout>
	);
};

const enhance = compose(withStyles(styles), translate, withDataProvider);

const WrapWarehouseShow = enhance(UnconnectedWrapWarehouseShow);

const Actions = ({ isMovable, data }) => {
	const { isMovingModeActive, toggleMovingMode, setConfirmationModalShown } = useMovingMode();
	const isContainerHall = data?.type === CONTAINERHALL;
	const [hasPermissionForMovingMode, setHasPermissionForMovingMode] = useState(false);

	const enableMovableMode = async () => {
		const response = await httpClient(`${process.env.REACT_APP_API_URL}/users/me`);
		if (!response?.json) return;
		if (response.json.agent?.movable_mode_enabled) setHasPermissionForMovingMode(true);
	};

	useEffect(() => {
		enableMovableMode();
	}, []);

	const handleToggleMovingMove = () => {
		if (isMovingModeActive && showAlertHelper.compareStates()) {
			setConfirmationModalShown(true);
		} else {
			showAlertHelper.purgeState();
			toggleMovingMode();
		}
	};

	if (!isMovable || isContainerHall) return <CardActions />;

	return (
		<CardActions>
			<FormControlLabel
				control={
					<Switch
						checked={isMovingModeActive}
						disabled={!hasPermissionForMovingMode}
						color="primary"
						onChange={handleToggleMovingMove}
					/>
				}
				label="Moving mode"
			/>
		</CardActions>
	);
};

const Layout = props => {
	const {
		isMovingModeActive,
		isConfirmationModalShown,
		setConfirmationModalShown,
		toggleMovingMode
	} = useMovingMode();

	const confirmDialog = () => {
		showAlertHelper.purgeState();
		toggleMovingMode();
		setConfirmationModalShown(false);
	};

	const closeDialog = () => {
		setConfirmationModalShown(false);
	};

	if (props.record.type === CONTAINERHALL) {
		return (
			<SectionsProvider>
				<ContainerHallShowLayout {...props} />
			</SectionsProvider>
		);
	}

	return isMovingModeActive ? (
		<>
			<MovingModeLayout {...props} />
			<Dialog
				isOpen={isConfirmationModalShown}
				title="ra.message.to_list"
				content="ra.message.to_list_content"
				onConfirm={confirmDialog}
				onClose={closeDialog}
			/>
		</>
	) : (
		<WrapWarehouseShow {...props} />
	);
};

const WarehouseShow = props => {
	const isMovable =
		props.permissions === ROLE_WAREHOUSE_MANAGER || props.permissions === ROLE_SUPER_ADMIN;

	return (
		<ContainerHallModeProvider>
			<MovingModeProvider>
				<SearchProvider>
					<Show
						title={<WarehouseTitle />}
						actions={<Actions isMovable={isMovable} />}
						{...props}
						data-card
					>
						<Layout permissions={props.permissions} />
					</Show>
				</SearchProvider>
			</MovingModeProvider>
		</ContainerHallModeProvider>
	);
};

export default WarehouseShow;
