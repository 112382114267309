import { UPDATE_CARTS } from '../AC';

const initialState = new Map();

export default (state = initialState, { type, payload }) => {
	if (type === UPDATE_CARTS) {
		return payload;
	}
	return state;
};
