export default (elements, height) => {
	const heights = [...elements].map(element => {
		if (element.style.height !== undefined) {
			element.style.height = 'initial';
		}
		return height ? element.clientHeight + height : element.clientHeight;
	});
	const maxHeight = Math.max(...heights);
	elements.forEach(element => {
		element.style.height = `${maxHeight}px`;
	});
};
