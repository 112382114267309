import { Chip, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { TextInput } from 'react-admin';

import { required } from '../validations';

import QrReaderButton from './QrReaderButton';

const styles = theme => ({
	fullWidth: {
		width: '100%'
	},
	field: {
		display: 'flex',
		alignItems: 'flex-end',
		marginBottom: theme.spacing.unit,
		minHeight: theme.spacing.unit * 7
	},
	input: {
		flex: '1 0 1px',
		position: 'relative'
	},
	button: {
		marginLeft: '0.5em'
	},
	hiddenField: {
		position: 'absolute',
		top: theme.spacing.unit * 2,
		bottom: 0,
		zIndex: 1,
		marginTop: 0,
		marginBottom: 0
	},
	emptyHiddenField: {
		top: theme.spacing.unit * 3
	},
	values: {
		position: 'relative',
		zIndex: 2,
		marginTop: theme.spacing.unit * 2,
		paddingTop: 18,
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'flex-end',
		minHeight: 38
	},
	textInput: {
		color: 'transparent'
	},
	chip: {
		marginRight: theme.spacing.unit,
		marginBottom: 6
	},
	underline: {
		height: '100%'
	},
	error: {
		position: 'absolute',
		top: '100%',
		color: '#f44336!important'
	}
});

class UnconnectedChipsInput extends Component {
	render() {
		const {
			classes,
			className,
			source,
			resource,
			values,
			isOpenQrCodeReader,
			isLoading,
			buttonLabel,
			selected,
			onDeleteElement,
			onChangeQrCode,
			onCloseQrCodeReader
		} = this.props;

		return (
			<div className={`${className} ${classes.field}`}>
				<div className={classes.input}>
					<TextInput
						source={source}
						resource={resource}
						defaultValue={values.map(({ id }) => id).join(',')}
						validate={required(resource, source)}
						disabled={true}
						className={[
							classes.fullWidth,
							classes.hiddenField,
							values.length === 0 && classes.emptyHiddenField
						]
							.filter(Boolean)
							.join(' ')}
						InputProps={{
							classes: {
								input: classes.textInput,
								underline: classes.underline
							}
						}}
						FormHelperTextProps={{
							classes: {
								error: classes.error
							}
						}}
					/>
					<div className={classes.values}>
						{values.map(({ id }) => (
							<Chip
								key={id}
								label={id}
								onDelete={onDeleteElement && (() => onDeleteElement(id))}
								className={classes.chip}
								color={selected.indexOf(id) > -1 ? 'primary' : 'default'}
							/>
						))}
					</div>
				</div>
				{onChangeQrCode && (
					<QrReaderButton
						onConfirm={onChangeQrCode}
						onClose={onCloseQrCodeReader}
						isOpenDefault={isOpenQrCodeReader}
						disabled={isLoading}
						className={classes.button}
						label={buttonLabel}
					/>
				)}
			</div>
		);
	}
}

const ChipsInput = withStyles(styles)(UnconnectedChipsInput);

ChipsInput.defaultProps = {
	selected: []
};

export default ChipsInput;
