import { withStyles } from '@material-ui/core';
import { debounce, omit } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import React, { useState } from 'react';
import {
	GET_LIST,
	List,
	NumberField,
	Responsive,
	TextField,
	translate,
	withDataProvider
} from 'react-admin';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { Datagrid, ListActions as WarehouseActions, UpdatedAtField } from '../components';
import styles from '../styles';
import { ROLE_WAREHOUSE_MANAGER, ROLE_OFFICE_EMPLOYEE, ROLE_AGENT } from '../users/roles';

import MobileGrid from './MobileGrid';
import {
	CONTAINER,
	CONTAINER_FILTER,
	CUSTOMER,
	CUSTOMER_FILTER,
	NOTE,
	NOTE_FILTER,
	ORDER_NUMBER,
	ORDER_NUMBER_FILTER
} from './searchContext/constants';
import WarehouseCreateForm from './WarehouseCreateForm';
import WarehousesActions from './WarehousesActions';

const optionsNumberFormat = {
	minimumFractionDigits: 2,
	maximumFractionDigits: 2
};

const FILTERS = {
	[CONTAINER]: CONTAINER_FILTER,
	[CUSTOMER]: CUSTOMER_FILTER,
	[NOTE]: NOTE_FILTER,
	[ORDER_NUMBER]: ORDER_NUMBER_FILTER
};

const WarehouseList = ({ classes, locale, permissions, translate, dataProvider, ...props }) => {
	const [localFilters, setLocalFilters] = useState({});
	const [filters, setFilters] = useState({});

	const handleChangeFiledContent = debounce(async (event, text, field) => {
		setLocalFilters(prev =>
			!text ? omit(prev, [FILTERS[field]]) : { ...prev, [FILTERS[field]]: text }
		);
		let copiedFilters = cloneDeep(
			!text ? omit(localFilters, [FILTERS[field]]) : { ...localFilters, [FILTERS[field]]: text }
		);
		if (!Object.keys(copiedFilters).length) copiedFilters = { level: 1 };
		const response = await dataProvider(GET_LIST, 'storageareas', {
			filter: copiedFilters
		});
		const result = new Set();
		response.data.forEach(warehouse => {
			result.add(Number(warehouse.path.split(',')[0]));
		});
		setFilters(
			[...result.values()].length
				? { ...filters, 'id][in][': [...result.values()] }
				: { 'id][in][': null }
		);
	}, 1000);

	return (
		<>
			{permissions === ROLE_AGENT && <WarehouseCreateForm />}
			<List
				{...props}
				actions={<WarehousesActions handleChangeFiledContent={handleChangeFiledContent} />}
				filter={{
					...filters,
					level: 1
				}}
				perPage={25}
				bulkActionButtons={false}
				exporter={false}
			>
				<Responsive
					medium={<MobileGrid permissions={permissions} />}
					large={
						<Datagrid>
							<TextField source="name" />
							<NumberField
								source="total_area"
								label={translate('resources.storageareas.fields.total_area') + ', m²'}
								headerClassName={classes.minWidth}
								className={classes.nowrap}
								locales={locale}
								options={optionsNumberFormat}
								sortable={false}
							/>
							<NumberField
								source="filled_area"
								label={translate('resources.storageareas.fields.filled_area') + ', m²'}
								headerClassName={classes.minWidth}
								className={classes.nowrap}
								locales={locale}
								options={optionsNumberFormat}
								sortable={false}
							/>

							<UpdatedAtField sortBy="updatedAt" headerClassName={classes.minWidth} />
							<WarehouseActions
								headerClassName={classes.minWidth}
								deletable={
									permissions !== ROLE_WAREHOUSE_MANAGER && permissions !== ROLE_OFFICE_EMPLOYEE
								}
								editable={
									permissions !== ROLE_WAREHOUSE_MANAGER && permissions !== ROLE_OFFICE_EMPLOYEE
								}
								isShow={
									permissions === ROLE_WAREHOUSE_MANAGER || permissions === ROLE_OFFICE_EMPLOYEE
								}
								isRestoreDisabled={
									permissions === ROLE_WAREHOUSE_MANAGER || permissions === ROLE_OFFICE_EMPLOYEE
								}
								entityName={'warehouses'}
							/>
						</Datagrid>
					}
				/>
			</List>
		</>
	);
};

const mapStateToProps = state => ({
	locale: state.i18n.locale
});

const enhance = compose(
	withStyles(styles),
	withDataProvider,
	translate,
	connect(mapStateToProps, null)
);

export default enhance(WarehouseList);
