import { Card, CardContent, CardHeader, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { translate, withDataProvider } from 'react-admin';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { marginGutters } from '../helpers';
import StorageSpace from './StorageSpace';

const styles = theme => {
	const margin = marginGutters(theme);
	const smBreakpoint = theme.breakpoints.up('sm');

	return {
		root: {
			display: 'inline-flex',
			flexDirection: 'column',
			width: '35%',
			marginLeft: '20px'
		},
		container: {
			display: 'inline-flex',
			flexWrap: 'wrap',
			paddingBottom: 0,
			margin: '0 0 48px 24px',
			paddingLeft: 0,
			paddingRight: 0,
			'&:last-child': {
				paddingBottom: 0
			}
		},
		column: {
			...margin,
			minWidth: 50,
			whiteSpace: 'nowrap',
			marginBottom: theme.spacing.unit * 2,
			[smBreakpoint]: {
				...margin[smBreakpoint],
				marginBottom: theme.spacing.unit * 3
			}
		}
	};
};

const UnconnectedArticlesCount = ({ classes, className, translate, totalArticlesCount }) => {
	return (
		<Card className={`${classes.root} ${className}`}>
			<CardHeader title={translate('pos.dashboard.articles.title')} />
			<CardContent className={classes.container}>
				<Typography variant="display2">{totalArticlesCount}</Typography>
			</CardContent>
		</Card>
	);
};

const mapStateToProps = state => ({
	locale: state.i18n.locale
});

const enhance = compose(
	withStyles(styles),
	translate,
	withDataProvider,
	connect(mapStateToProps, null)
);

const ArticlesCount = enhance(UnconnectedArticlesCount);

StorageSpace.defaultProps = {
	totalArticlesCount: 0
};

export default ArticlesCount;
