import { keyBy } from 'lodash';
import { Card, CardContent, CardHeader, Toolbar, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { GET_LIST, translate, withDataProvider, Pagination, DateField } from 'react-admin';
import { compose } from 'recompose';

import { AddressField, Datagrid } from '../components';

import { CONFIRMED } from '../articles/statuses';
import TypeField from '../deliveries/TypeField';

const styles = {
	content: {
		padding: 0,
		'& tr:last-child > td': {
			borderBottom: 'none'
		},
		'& tr > td': {
			paddingLeft: '24px'
		},
		'& th': {
			paddingLeft: '24px'
		}
	},
	toolbar: {
		float: 'right'
	},
	address: {
		width: '100%',
		marginLeft: '100px'
	}
};

class UpcomingDeliveries extends Component {
	_isMounted = false;
	state = {
		deliveries: [],
		page: 1,
		perPage: 10
	};

	componentDidMount = async () => {
		const { perPage } = this.state;
		this._isMounted = true;

		this.updateDeliveries(1, perPage);
	};

	componentWillUnmount() {
		this._isMounted = false;
	}

	handlePageChange = async page => {
		const { perPage } = this.state;
		await this.updateDeliveries(page, perPage);
	};

	handlePerPageChange = async perPage => {
		await this.updateDeliveries(1, perPage);
	};

	render() {
		const { className, classes, translate } = this.props;
		const { deliveries, page, perPage, total } = this.state;

		return (
			<Card className={className}>
				<CardHeader title={translate('pos.dashboard.upcomingDeliveries.title')} />
				<CardContent className={classes.content}>
					<Datagrid
						data={keyBy(deliveries, 'id')}
						ids={deliveries.map(({ id }) => id)}
						currentSort={{
							field: ''
						}}
					>
						<AddressField
							showName
							sortBy="address"
							label="resources.delivery.fields.recipient"
							headerClassName={classes.fullWidth}
						/>
						<TypeField
							sortBy="address"
							label="resources.delivery.fields.type"
							headerClassName={classes.fullWidth}
							sortable={false}
						/>
						<DateField
							showTime={true}
							source="date"
							label="resources.delivery.fields.date"
							className={classes.nowrap}
							sortable={false}
						/>
					</Datagrid>
					{total > 0 && (
						<Toolbar className={classes.toolbar}>
							<Pagination
								total={total}
								page={page}
								perPage={perPage}
								setPage={this.handlePageChange}
								setPerPage={this.handlePerPageChange}
							/>
						</Toolbar>
					)}
				</CardContent>
			</Card>
		);
	}

	updateDeliveries = async (page, perPage) => {
		const { dataProvider } = this.props;
		const date = new Date();
		const { data: deliveries, total } = await dataProvider(GET_LIST, 'deliveries', {
			filter: {
				'articles.status': CONFIRMED,
				'date][gt': `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
			},
			pagination: {
				page,
				perPage
			}
		});

		if (this._isMounted) {
			this.setState({ deliveries, perPage, page, total });
		}
	};
}

const enhance = compose(withStyles(styles), translate, withDataProvider);

export default enhance(UpcomingDeliveries);
