import { withStyles } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import {
	SimpleForm,
	Toolbar,
	CREATE,
	GET_LIST,
	UPDATE,
	translate,
	GET_ONE,
	withDataProvider
} from 'react-admin';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Redirect } from 'react-router-dom';
import { compose } from 'recompose';

import { CommentList, ToolbarContainer } from '../components';
import { getWarehousesHelper } from '../helpers';
import { ROLE_COLLECTOR_GUY, ROLE_SUPER_ADMIN } from '../users/roles';
import generalStyles from '../styles';
import { isAllRevertedBarcodes } from '../validations';

import ArticleActions from './ArticleActions';
import ArticleFormFields from './ArticleFormFields';
import BarcodeList from './BarcodeList';
import DoneDialog from '../components/DoneDialog';
import ListOfRevertedBarcode from './ListOfRevertedBarcode';
import { IN_CART, IN_WAREHOUSE, OUTSOURCED } from './statuses';

const styles = theme => ({
	spacer: generalStyles(theme).spacer,
	fullWidth: {
		width: '100%',
		maxWidth: 'none'
	},
	comments: {
		marginTop: 16
	},
	barcode: {
		width: '100%'
	},
	listIcon: {
		marginRight: '0.5em',
		fontSize: 24
	},
	listLabel: {
		paddingLeft: 0
	},
	required: {
		'& label': {
			fontWeight: 'bold'
		}
	}
});

const eventListener = () => {
	localStorage.removeItem('is_next');
	localStorage.removeItem('changed_storage_area');
};

window.addEventListener('beforeunload', eventListener);

class ArticleForm extends Component {
	_isMounted = false;
	isDoneClick = false;
	redirect = false;
	state = {
		showDoneDialog: false,
		warehouses: [],
		filledInStorageArea: 0,
		filledInOtherProjects: 0,
		totalArea: 0
	};

	constructor(props) {
		super(props);

		const {
			record: { photos, damages, barcodes }
		} = this.props;
		this.photos = photos || [];
		this.damages = damages || [];
		this.barcodes = barcodes || [];

		if (this.barcodes.length > 0) {
			this.state.projectId = this.barcodes[0].project_id;
		}
	}

	componentDidMount = async () => {
		const { permissions } = this.props;
		const { projectId } = this.state;

		this._isMounted = true;

		if (permissions === ROLE_COLLECTOR_GUY) {
			return;
		}

		if (permissions !== ROLE_SUPER_ADMIN || projectId !== undefined) {
			await this.updateWarehouses();
		}
	};

	componentWillUnmount() {
		this._isMounted = false;
	}

	getProjectInfo = async storageArea => {
		const { dataProvider } = this.props;
		const { projectId } = this.state;

		const [
			{ data: projectAreas },
			{
				data: { measurements: projectMeasurements }
			}
		] = await Promise.all([
			dataProvider(GET_LIST, 'projectareas', {
				filter: {
					project: projectId,
					storageArea
				}
			}),
			dataProvider(GET_ONE, 'projects', {
				id: projectId
			})
		]);

		return {
			showDoneDialog: true,
			storageArea,
			projectAreas,
			projectMeasurements
		};
	};

	getStorageAreaInfo = async storageArea => {
		const { dataProvider } = this.props;
		const {
			data: { free_area: freeArea, filled_area, total_area }
		} = await dataProvider(GET_ONE, 'storageareas', {
			id: storageArea
		});

		return { freeArea, filled_area, total_area };
	};

	handleAddMeasurements = async () => {
		const { storageArea } = this.state;

		localStorage.setItem('changed_storage_area', storageArea);

		if (this.redirect === false) {
			await this.updateWarehouses();

			return;
		}

		if (this.isDoneClick !== false) {
			localStorage.removeItem('changed_storage_area');
		}

		return this.redirectTo(this.redirect);
	};

	handleChangeBarcodes = barcodes => {
		this.barcodes = barcodes;

		if (this.barcodes.length > 0) {
			const { projectId } = this.state;

			this.setState(
				{
					projectId: this.barcodes[0].project_id
				},
				async () => {
					if (projectId === undefined) {
						await this.updateWarehouses();
					}
				}
			);
		}
	};

	handleChangeDamages = (event, damages) => {
		this.damages = damages;
	};

	handleChangePhotos = (event, photos) => {
		this.photos = photos;
	};

	handleChangeStorageArea = async nextStorageArea => {
		const { permissions } = this.props;
		const prevStorageArea = parseInt(localStorage.getItem('changed_storage_area') || 0);

		if (
			permissions !== ROLE_COLLECTOR_GUY &&
			prevStorageArea &&
			prevStorageArea !== nextStorageArea
		) {
			this.redirect = false;
			await this.getProjectAndStorageAreaInfo(nextStorageArea, prevStorageArea);
		}
	};

	handleCloseDoneDialog = () => {
		if (this.isDoneClick !== false && localStorage.getItem('changed_storage_area') !== null) {
			localStorage.removeItem('changed_storage_area');
			this.redirectTo('edit');
		}

		if (this._isMounted) {
			this.setState({
				showDoneDialog: false
			});
		}
	};

	handleDone = async (article, newArticle) => {
		const { permissions } = this.props;
		const { projectId } = this.state;

		this.newArticle = newArticle;

		if (permissions === ROLE_COLLECTOR_GUY) {
			return this.redirectTo('list');
		}

		try {
			const { storagearea } = newArticle;

			this.isDoneClick = true;
			this.redirect = `/projects/${projectId}`;

			if (storagearea) {
				const { id: nextStorageArea } = storagearea;

				return await this.getProjectAndStorageAreaInfo(nextStorageArea);
			} else {
				return this.redirectTo('list');
			}
		} catch (e) {
			return this.redirectTo('list');
		}
	};

	handleNext = newArticle => {
		this.newArticle = newArticle;
		this.redirect = 'create';

		localStorage.setItem('is_next', 'is_next');

		if (newArticle.storagearea && newArticle.storagearea.id) {
			localStorage.setItem('changed_storage_area', newArticle.storagearea.id);
		}

		return this.redirectTo('create');
	};

	handleSave = async (newArticle, redirect) => {
		const { permissions } = this.props;
		const { storagearea } = newArticle;

		this.newArticle = newArticle;
		this.redirect = redirect;

		if (permissions === ROLE_COLLECTOR_GUY || !storagearea) {
			return this.redirectTo(this.redirect);
		}

		const { id: nextStorageArea } = storagearea;

		return await this.getProjectAndStorageAreaInfo(nextStorageArea);
	};

	getProjectAndStorageAreaInfo = async (nextStorageArea, prevStorageArea) => {
		const [projectInfo, storageInfo] = await Promise.all([
			this.getProjectInfo(prevStorageArea || nextStorageArea),
			this.getStorageAreaInfo(nextStorageArea)
		]);

		const { freeArea, filled_area, total_area } = storageInfo;
		const { projectAreas } = projectInfo;

		const filledInCurrentProject =
			projectAreas && projectAreas.length > 0 ? projectAreas[0].area : 0;

		this.setState({
			...projectInfo,
			freeArea,
			filledInStorageArea: filled_area,
			filledInCurrentProject,
			filledInOtherProjects: filled_area - filledInCurrentProject,
			totalArea: total_area
		});
	};

	// eslint-disable-next-line no-unused-vars
	handleSubmit = async ({ barcodes_ids, ...article }, redirect) => {
		const { dataProvider, resource } = this.props;

		article.category =
			article.category && article.category.id
				? {
						id: article.category.id
				  }
				: null;

		if (article.storagearea && article.storagearea.id) {
			article.storagearea = {
				id: article.storagearea.id
			};
		}

		article.photos = this.photos;
		article.damages = this.damages;
		article.barcodes = this.barcodes.map(({ id }) => ({ id }));

		if (article.status === OUTSOURCED) {
			article.status = IN_WAREHOUSE;
		}

		const { data: newArticle } = await dataProvider(article.id ? UPDATE : CREATE, resource, {
			id: article.id,
			data: article
		});

		return await this.handleSuccessSubmit(article, newArticle, redirect);
	};

	handleSuccessSubmit = async (article, newArticle, redirect) => {
		switch (redirect) {
			case 'next':
				return this.handleNext(newArticle);
			case 'done':
				return await this.handleDone(article, newArticle);
			default:
				return await this.handleSave(newArticle, redirect);
		}
	};

	redirectPath = redirect => {
		const { resource } = this.props;

		switch (redirect) {
			case 'create':
				return `/${resource}/create`;
			case 'edit':
				return `/${resource}/${this.newArticle.id}`;
			case 'show':
				return `/${resource}/${this.newArticle.id}/show`;
			case 'list':
				return `/${resource}`;
			default:
				return redirect;
		}
	};

	redirectTo = redirect => {
		const { dispatch } = this.props;

		if (redirect === false) {
			return;
		}

		const redirectPath = this.redirectPath(redirect);

		return dispatch(push(redirectPath));
	};

	render() {
		const {
			barcodeId,
			classes,
			isLoading,
			record,
			resource,
			permissions,
			translate,
			...props
		} = this.props;

		const {
			projectId,
			storageArea,
			showDoneDialog,
			projectAreas,
			projectMeasurements,
			warehouses,
			freeArea,
			filledInOtherProjects,
			filledInStorageArea,
			totalArea,
			filledInCurrentProject
		} = this.state;

		const userJSON = localStorage.getItem('user');
		const user = userJSON ? JSON.parse(userJSON) : undefined;
		if (record?.status === IN_CART && user?.id !== record.reserved_by?.id) {
			return <Redirect to={`/${resource}/${record.id}/show`} />;
		}

		return (
			<Fragment>
				<DoneDialog
					projectId={projectId}
					storageArea={storageArea}
					projectAreas={projectAreas}
					projectMeasurements={projectMeasurements}
					onClose={this.handleCloseDoneDialog}
					showDialog={showDoneDialog}
					onAdd={this.handleAddMeasurements}
					translate={translate}
					freeArea={freeArea}
					filledInOtherProjects={filledInOtherProjects}
					filledInStorageArea={filledInStorageArea}
					filledInCurrentProject={filledInCurrentProject}
					totalArea={totalArea}
				/>

				<SimpleForm
					{...props}
					resource={resource}
					redirect={record.id ? false : 'edit'}
					record={record}
					toolbar={
						<Toolbar classes={{ spacer: classes.spacer }}>
							<ToolbarContainer maxWidth="lg" className={classes.fullWidth}>
								<ArticleActions projectId={projectId} />
							</ToolbarContainer>
						</Toolbar>
					}
					save={this.handleSubmit}
				>
					{record.status !== OUTSOURCED && (
						<BarcodeList
							source="barcodes_ids"
							barcodeId={barcodeId}
							isLoading={isLoading}
							resource={resource}
							permissions={permissions}
							onChange={this.handleChangeBarcodes}
							className={`${classes.required}`}
						/>
					)}

					{record.status === OUTSOURCED && (
						<ListOfRevertedBarcode
							source="barcodes_ids"
							defaultValue={[barcodeId]}
							isLoading={isLoading}
							resource={resource}
							permissions={permissions}
							className={`${classes.required}`}
							validate={isAllRevertedBarcodes('ra.message.equal_barcodes')}
						/>
					)}
					<ArticleFormFields
						permissions={permissions}
						onChangePhotos={this.handleChangePhotos}
						onChangeDamages={this.handleChangeDamages}
						onChangeStorageArea={this.handleChangeStorageArea}
						warehouses={warehouses}
					/>

					{record.id && record.thread && (
						<CommentList className={`${classes.comments} ${classes.fullWidth}`} />
					)}
				</SimpleForm>
			</Fragment>
		);
	}

	updateWarehouses = async () => {
		const { dataProvider } = this.props;
		const { projectId } = this.state;

		const warehouses = await getWarehousesHelper(dataProvider, projectId);

		if (this._isMounted) {
			this.setState({ warehouses });
		}
	};
}

const mapStateToProps = state => ({
	isLoading: state.admin.loading > 0
});

const enhance = compose(
	withStyles(styles),
	connect(mapStateToProps, null),
	translate,
	withDataProvider
);

export default enhance(ArticleForm);
