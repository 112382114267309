import { withStyles } from '@material-ui/core';
import React, { cloneElement } from 'react';
import { DeleteButton, EditButton, ShowButton } from 'react-admin';
import RestoreButton from './RestoreButton';
import styles from '../styles';

import ExportRecord from './ExportRecord';

const UnconnectedListActions = ({
	className,
	classes,
	deleteButton,
	deletable,
	editable,
	isShow,
	isRestoreDisabled,
	exportable,
	customButtons,
	entityName,
	...props
}) => {
	const { deleted_at, deletable: active, id } = props.record;

	return (
		<div className={`${className} ${classes.flex}`}>
			{deletable && !deleted_at && id && (
				<DeleteButton
					label={deleteButton}
					{...props}
					undoable={false}
					disabled={active === false}
				/>
			)}
			{editable && !deleted_at && id && <EditButton {...props} />}
			{deleted_at && (
				<RestoreButton entityName={entityName} disabled={isRestoreDisabled} {...props} />
			)}
			{isShow && !deleted_at && <ShowButton {...props} />}
			{exportable && <ExportRecord {...props} />}
			{customButtons &&
				(customButtons.length
					? customButtons.map(customButton => React.cloneElement(customButton, props))
					: cloneElement(customButtons, props))}
		</div>
	);
};

const ListActions = withStyles(styles)(UnconnectedListActions);

ListActions.defaultProps = {
	deletable: true,
	editable: true,
	isShow: false,
	exportable: false
};

export default ListActions;
