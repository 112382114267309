import ArticleCategoryIcon from '@material-ui/icons/ViewQuilt';

import ArticleCategoryList from './ArticleCategoryList';
import ArticleCategoryCreate from './ArticleCategoryCreate';
import ArticleCategoryEdit from './ArticleCategoryEdit';

export default {
	list: ArticleCategoryList,
	create: ArticleCategoryCreate,
	edit: ArticleCategoryEdit,
	icon: ArticleCategoryIcon
};
