import { withStyles } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import React, { Fragment } from 'react';
import { NumberField, ReferenceField, TextField, FunctionField } from 'react-admin';
import {
	AddressField,
	Datagrid,
	ListActions as ArticleActions,
	UpdatedAtField
} from '../components';
import generalStyles from '../styles';
import {
	ROLE_AGENT,
	ROLE_COLLECTOR_GUY,
	ROLE_CUSTOMER,
	ROLE_MAIN_CUSTOMER,
	ROLE_OFFICE_EMPLOYEE,
	ROLE_SUPER_ADMIN,
	ROLE_WAREHOUSE_MANAGER
} from '../users/roles';
import { OUTSOURCED } from './statuses';
import AddToCartButton from './AddToCartButton';
import ArticleType from './ArticleType';
import ColorsField from './ColorsField';
import LightboxField from '../components/LightboxField';
import DatagridBody from 'ra-ui-materialui/lib/list/DatagridBody';
import ArticleDatagridRow from './ArticleDatagridRow';

const styles = theme => {
	const { minWidth, nowrap } = generalStyles(theme);

	return {
		minWidth,
		datagrid: {
			'& td:not(:last-child, :nth-last-child(2)), :nth-last-child(3))': {
				maxWidth: 300,
				wordWrap: 'break-word'
			},
			'& td': {
				textAlign: 'center',
				padding: '4px 20px'
			},
			'& th': {
				textAlign: 'center',
				padding: '4px 20px'
			}
		},
		nowrap: {
			...nowrap,
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			maxWidth: 150
		},
		articlePhotoModal: {
			maxWidth: 500
		},
		imageField: {
			textAlign: 'center',
			'& img': {
				margin: 0,
				cursor: 'pointer',
				width: '100px',
				height: '100px',
				objectFit: 'cover',
				objectPosition: 'center'
			}
		},
		barcodesContainer: {
			'& > span': {
				display: 'inline-block',
				padding: '5px',
				borderRadius: '50%',
				margin: 2
			}
		},
		scanned: {
			background: theme.palette.primary.scanned
		},
		notScanned: {
			background: theme.palette.primary.notScanned
		},
		expandHeader: {
			padding: 0,
			width: 48
		},
		expandIconCell: {
			width: 48
		},
		expandIcon: {
			transform: 'rotate(-90deg)',
			transition: theme.transitions.create('transform', {
				duration: theme.transitions.duration.shortest
			})
		},
		expanded: {
			transform: 'rotate(0deg)'
		},
		expandBackground: {
			backgroundColor: '#2aa9f3'
		},
		lastLine: {
			'& > tbody > tr:last-child > td': {
				border: 'none'
			}
		},
		thead: {
			marginLeft: '0.5em'
		},
		NumberInput: {
			maxWidth: 80
		},
		numeric: {
			textAlign: 'center !important'
		}
	};
};

const BarcodesForOutsourcingPage = ({ record, classes, scannedBarcodes }) => {
	const fullyScannedArticle = !record.barcodes.filter(({ id }) => !scannedBarcodes.includes(id))
		.length;

	return (
		<div className={classes.barcodesContainer}>
			{record.barcodes?.map(({ id }) => (
				<span
					className={
						fullyScannedArticle || record.status === OUTSOURCED
							? null
							: scannedBarcodes.includes(id)
							? classes.scanned
							: classes.notScanned
					}
					key={id}
				>
					{id}
				</span>
			))}
		</div>
	);
};

const Barcodes = ({ record, classes, ...props }) => (
	<TextField
		{...props}
		source="barcodes"
		record={{ barcodes: record.barcodes?.map(({ id }) => id).join(', ') }}
		className={classes.nowrap}
	/>
);

Barcodes.defaultProps = {
	addLabel: true
};

const ArticleDatagridBody = ({ rowConfig, ...props }) => (
	<DatagridBody {...props} row={<ArticleDatagridRow {...props} {...rowConfig} />} />
);

const ArticleDatagrid = ({
	classes,
	customActions,
	permissions,
	basePath,
	outsourcingPage,
	scannedBarcodes,
	hideExpand = false,
	hideExpandCell = false,
	deliveryPage,
	...props
}) => {
	const { selectedIds, isSortingBanned } = props;

	return (
		<Fragment>
			<Datagrid
				basePath={basePath}
				{...props}
				className={`${classes.datagrid}`}
				body={
					<ArticleDatagridBody
						rowConfig={{ ...props, hideExpandCell, outsourcingPage, deliveryPage }}
					/>
				}
			>
				<TextField source="empty" label="" />
				{!outsourcingPage ? (
					<Barcodes label="QID" sortable={false} classes={classes} source={'barcode'} />
				) : (
					<BarcodesForOutsourcingPage
						label="QID"
						sortable={false}
						classes={classes}
						scannedBarcodes={scannedBarcodes}
						source={'barcode'}
					/>
				)}
				<LightboxField
					label="Photo"
					sourceList={['photos', 'damages']}
					markedList={['damages']}
					sortable={!isSortingBanned}
				/>
				<TextField source="name" sortable={!isSortingBanned} />
				<TextField source="serial_number" sortable={!isSortingBanned} />
				<TextField
					source="category.name"
					label="resources.articles.fields.category"
					sortBy="category"
					sortable={!isSortingBanned}
				/>
				<ColorsField
					label="resources.articles.fields.colors"
					source={'colors'}
					sortable={!isSortingBanned}
				/>
				<TextField
					source="width"
					label="resources.articles.fields.width"
					sortable={!isSortingBanned}
				/>
				<TextField
					source="height"
					label="resources.articles.fields.height"
					sortable={!isSortingBanned}
				/>
				<TextField
					source="length"
					label="resources.articles.fields.length"
					sortable={!isSortingBanned}
				/>
				<ArticleType source="type" sortable={!isSortingBanned} />
				{permissions !== ROLE_COLLECTOR_GUY && (
					<ReferenceField
						source="barcodes[0].project_id"
						label="resources.articles.fields.project"
						reference="projects"
						sortable={false}
						basePath="/projects"
						linkType={
							permissions === ROLE_WAREHOUSE_MANAGER ||
							permissions === ROLE_MAIN_CUSTOMER ||
							permissions === ROLE_CUSTOMER
								? 'show'
								: 'edit'
						}
					>
						<AddressField source="customer.address" />
					</ReferenceField>
				)}
				<TextField
					source="manufacturer"
					label="resources.articles.fields.manufacturer"
					sortable={!isSortingBanned}
				/>
				<TextField
					source="storagearea.full_name"
					label="resources.articles.fields.warehouse_place"
					sortBy="storagearea"
					sortable={!isSortingBanned}
				/>
				<FunctionField
					render={record => record.thread?.num_comments || ''}
					label="resources.articles.fields.comments"
					sortable={false}
					source={'comments'}
				/>
				<FunctionField
					render={record => (record.is_scratch ? <CheckIcon /> : '')}
					label="resources.articles.fields.is_scratch"
					sortBy="isScratch"
					source={'is_scratch'}
					sortable={!isSortingBanned}
				/>
				<UpdatedAtField
					sortBy="updatedAt"
					headerClassName={classes.minWidth}
					source={'updated_at'}
					sortable={!isSortingBanned}
				/>
				<NumberField source="id" sortable={!isSortingBanned} />

				{!customActions && (
					<ArticleActions
						headerClassName={classes.minWidth}
						editable={permissions !== ROLE_MAIN_CUSTOMER && permissions !== ROLE_CUSTOMER}
						deletable={
							permissions === ROLE_SUPER_ADMIN ||
							permissions === ROLE_AGENT ||
							permissions === ROLE_OFFICE_EMPLOYEE
						}
						isShow={permissions === ROLE_MAIN_CUSTOMER || permissions === ROLE_CUSTOMER}
						entityName={'articles'}
						customButtons={
							permissions !== ROLE_COLLECTOR_GUY && <AddToCartButton selectedIds={selectedIds} />
						}
					/>
				)}
				{!hideExpand && (
					<NumberField label="Amount" source="serial_number_info.count" sortable={false} />
				)}
				{customActions && (
					<ArticleActions
						headerClassName={classes.minWidth}
						editable={false}
						deletable={false}
						isShow={false}
						customButtons={customActions}
						source={'custom_actions'}
						selectedIds={selectedIds}
						sortable={!isSortingBanned}
					/>
				)}
			</Datagrid>
		</Fragment>
	);
};

export default withStyles(styles)(ArticleDatagrid);
