import { useContainerHallMode } from '../context';
import React, { useEffect, useState } from 'react';
import { UPDATE, withDataProvider } from 'react-admin';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import { findStorageArea, moveStorageArea } from '../../helpers';
import { useSections } from '../../sectionsContext';

const styles = {
	emptyContainer: {
		border: '2px dashed transparent',
		minHeight: '75px',
		marginTop: '20px'
	},
	highlightedEmptyContainer: {
		minHeight: '75px',
		border: '2px dashed #4eabfc',
		borderRadius: '10px',
		cursor: 'pointer',
		marginTop: '20px'
	}
};

const EmptyContainer = ({ classes, column, dataProvider }) => {
	const { sections, updateSections } = useSections();
	const { selectedContainerId, chooseContainer, setIsContainerChosen } = useContainerHallMode();
	const [isMovingAvailable, setIsMovingAvailable] = useState(false);

	useEffect(() => {
		if (!selectedContainerId) {
			setIsMovingAvailable(false);
		} else {
			const isParentColumn = column.children.find(element => element?.id === selectedContainerId);
			setIsMovingAvailable(!isParentColumn);
		}
	}, [selectedContainerId]);

	const getClassName = () => {
		return isMovingAvailable ? classes.highlightedEmptyContainer : classes.emptyContainer;
	};

	const handleMoveContainer = async () => {
		if (!isMovingAvailable) return;
		const maxStorageIndex = Math.max(...column.children.map(child => child.storage_order ?? 0));
		const selectedContainer = findStorageArea(sections, selectedContainerId);
		const prevParentId = selectedContainer.parent_id;
		if (!prevParentId) return;

		try {
			const { data: updatedSection } = await dataProvider(UPDATE, 'storageareas', {
				id: selectedContainerId,
				data: {
					id: selectedContainerId,
					movable: true,
					storage_order: maxStorageIndex + 1,
					parent: {
						id: column.id
					}
				}
			});
			if (updatedSection) {
				moveStorageArea(sections, updatedSection, prevParentId, column.id);
				chooseContainer(null);
				setIsContainerChosen(null);
				updateSections();
			}
		} catch (error) {
			console.error('[warehouse]: subsection moving failed', error);
		}
	};

	return <div className={getClassName()} onClick={handleMoveContainer}></div>;
};

const enhance = compose(withStyles(styles), withDataProvider);

export default enhance(EmptyContainer);
