import React, { Component, createRef } from 'react';
import { compose } from 'recompose';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { PhotoLibrary as GalleryIcon, PriorityHigh } from '@material-ui/icons';
import Close from '@material-ui/icons/Close';
import { Button } from 'react-admin';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import NavArrow from './NavArrow';

const styles = theme => ({
	arrow: {
		minWidth: theme.spacing.unit * 6,
		[theme.breakpoints.up('sm')]: {
			'&:first-child': {
				marginRight: theme.spacing.unit * 3
			},
			'&:last-child': {
				marginLeft: theme.spacing.unit * 3
			}
		}
	},
	lightBoxImage: {
		maxWidth: '390px',
		width: '390px',
		height: '500px',
		objectFit: 'contain',
		[theme.breakpoints.down('sm')]: {
			height: '300px',
			maxWidth: 'calc(100% - 98px)',
			width: 'initial'
		}
	},
	theOnlyImage: {
		maxWidth: '500px',
		[theme.breakpoints.down('sm')]: {
			height: '300px',
			maxWidth: '100%'
		}
	},
	content: {
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'row',
		[theme.breakpoints.down('sm')]: {
			alignItems: 'center',
			justifyContent: 'center'
		}
	},
	imageField: {
		position: 'relative',
		'& img': {
			display: 'block',
			margin: 0,
			cursor: 'pointer',
			width: '100px',
			height: '100px',
			objectFit: 'cover',
			objectPosition: 'center'
		}
	},
	imagesBlock: {
		display: 'flex',
		position: 'relative',
		'& img': {
			display: 'block',
			marginRight: '20px',
			cursor: 'pointer',
			width: '100px',
			height: '100px',
			objectFit: 'cover',
			objectPosition: 'center'
		}
	},
	galleryIcon: {
		position: 'absolute',
		bottom: '4px',
		right: '4px',
		cursor: 'pointer',
		color: theme.palette.primary.contrastText
	},
	imagesWrapper: {
		position: 'relative',
		marginBottom: '15px'
	},
	damagesMarked: {
		position: 'absolute',
		right: '20px',
		color: '#fff',
		fontSize: '3rem',
		backgroundColor: '#2aa9f3',
		maxWidth: '20px',
		transform: 'translate(-100%, -50%)'
	},
	dotsContainer: {
		display: 'flex',
		gap: '5px',
		margin: '0',
		padding: '0',
		position: 'absolute',
		left: '50%',
		transform: 'translateX(-50%)',
		zIndex: '10',
		bottom: '-14px',
		'& button': {
			borderRadius: '50%',
			border: 'none',
			backgroundColor: '#075581',
			padding: '6px',
			'&:hover': {
				textDecoration: 'none',
				opacity: '.7',
				cursor: 'pointer'
			}
		},
		'& button.true': {
			backgroundColor: '#24a7f2'
		}
	}
});

class LightboxField extends Component {
	_isMounted = false;
	state = {
		currentImg: 0,
		opened: false,
		positionMarked: 0
	};
	imagesRef = createRef();
	imagesContainRef = createRef();

	get images() {
		const { record, source, markedList, sourceList } = this.props;
		const images = [];
		if (source.length > 0) {
			const temp = get(record, source);
			if (Array.isArray(temp)) {
				temp.forEach(url => {
					images.push({
						url,
						marked: false
					});
				});
			}
		} else if (sourceList.length > 0) {
			sourceList.forEach(category => {
				const temp = get(record, category);
				if (!Array.isArray(temp)) return;
				temp.forEach(url => {
					images.push({
						url,
						marked: markedList.includes(category)
					});
				});
			});
		} else {
			return '';
		}
		return images;
	}

	componentDidMount = () => {
		this._isMounted = true;
	};

	componentWillUnmount = () => {
		this._isMounted = false;
	};

	handleClose = () => {
		if (this._isMounted) {
			this.setState({
				opened: false
			});
		}
	};

	handleOpen = () => {
		if (this._isMounted) {
			this.setState({
				opened: true,
				currentImg: 0
			});
		}
	};

	handleOpenImage = index => {
		if (this._isMounted) {
			this.setState({
				opened: true,
				currentImg: index
			});
		}
	};

	handleClickNext = () => {
		if (this._isMounted) {
			const { currentImg } = this.state;
			this.setState({
				currentImg: currentImg === this.images.length - 1 ? 0 : currentImg + 1
			});
		}
	};

	handleClickBefore = () => {
		if (this._isMounted) {
			const { currentImg } = this.state;
			this.setState({
				currentImg: currentImg === 0 ? this.images.length - 1 : currentImg - 1
			});
		}
	};

	handlePositionMarked = () => {
		if (this._isMounted) {
			const heightContain = this.imagesContainRef.current.clientHeight;
			const widthContain = this.imagesContainRef.current.clientWidth;
			const damagesWidthN = this.imagesRef.current.naturalHeight;
			const damagesHeightN = this.imagesRef.current.naturalWidth;
			const damagesHeightR = (damagesWidthN * widthContain) / damagesHeightN;
			this.setState({
				positionMarked: damagesHeightR > heightContain ? 20 : (heightContain - damagesHeightR) / 2
			});
		}
	};
	handleDots = index => {
		if (this._isMounted) {
			this.setState({
				currentImg: index
			});
		}
	};

	render() {
		const { classes, photosType } = this.props;
		const { currentImg, opened, positionMarked } = this.state;

		if (this.images?.length === 0) return '';
		const theOnlyImage = this.images.length === 1;
		const currentImage = this.images[currentImg];

		return (
			<>
				{photosType ? (
					<div className={classes.imagesBlock}>
						{this.images.map((image, index) => {
							return (
								<img
									key={`${image?.url}_${index}`}
									src={image?.url}
									alt=""
									onClick={() => this.handleOpenImage(index)}
								/>
							);
						})}
					</div>
				) : (
					<div className={classes.imageField} onClick={this.handleOpen}>
						{this.images[0] ? <img src={this.images[0].url} alt="" /> : ''}
						{!theOnlyImage ? <GalleryIcon className={classes.galleryIcon} /> : ''}
					</div>
				)}
				<Dialog aria-labelledby="simple-dialog-title" open={opened} onClose={this.handleClose}>
					<DialogContent className={classes.content}>
						{theOnlyImage ? (
							''
						) : (
							<NavArrow type="prev" onClick={this.handleClickBefore} className={classes.arrow} />
						)}
						<div className={classes.imagesWrapper} ref={this.imagesContainRef}>
							{this.images[currentImg] ? (
								<img
									src={this.images[currentImg].url}
									className={theOnlyImage ? classes.theOnlyImage : classes.lightBoxImage}
									alt=""
									ref={this.imagesRef}
									onLoad={this.handlePositionMarked}
								/>
							) : (
								''
							)}
							{currentImage.marked ? (
								<PriorityHigh
									className={classes.damagesMarked}
									style={{ bottom: positionMarked + 'px' }}
								/>
							) : (
								''
							)}
							{this.images?.length > 1 ? (
								<div className={classes.dotsContainer}>
									{this.images.map((image, index) => {
										let active = false;
										if (currentImg === index) {
											active = true;
										}
										return (
											<button
												onClick={() => this.handleDots(index)}
												className={active + ' btn'}
												key={index}
											></button>
										);
									})}
								</div>
							) : (
								''
							)}
						</div>
						{theOnlyImage ? (
							''
						) : (
							<NavArrow type="next" onClick={this.handleClickNext} className={classes.arrow} />
						)}
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} label="ra.action.close">
							<Close />
						</Button>
					</DialogActions>
				</Dialog>
			</>
		);
	}
}

LightboxField.propTypes = {
	label: PropTypes.string,
	record: PropTypes.object,
	source: PropTypes.string,
	sourceList: PropTypes.arrayOf(PropTypes.string),
	markedList: PropTypes.arrayOf(PropTypes.string)
};

LightboxField.defaultProps = {
	addLabel: true,
	record: {},
	source: '',
	markedList: [],
	sourceList: []
};

const enhance = compose(withStyles(styles));

export default enhance(LightboxField);
