import React from 'react';
import { Create } from 'react-admin';

import ProjectForm from './ProjectForm';

const ProjectCreate = ({ permissions, ...props }) => (
	<Create {...props}>
		<ProjectForm permissions={permissions} />
	</Create>
);

export default ProjectCreate;
