import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { GET_LIST, withDataProvider } from 'react-admin';
import { compose } from 'recompose';

import StorageSpace from './StorageSpace';
import Warehouses from './Warehouses';
import UpcomingDeliveries from './UpcomingDeliveries';
import ArticlesCount from './ArticlesCount';

const styles = theme => ({
	widget: {
		marginBottom: theme.spacing.unit * 3
	},
	customers: {
		maxWidth: theme.breakpoints.values['md']
	},
	warehouses: {
		maxWidth: theme.breakpoints.values['md']
	},
	firstLine: {
		maxWidth: theme.breakpoints.values['md'],
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between'
	}
});

const perPage = 10;

class Dashboard extends Component {
	state = {
		totalSpace: 0,
		freeSpace: 0,
		warehouses: [],
		page: 1,
		nbPages: 0,
		totalArticlesCount: 0
	};

	_isMounted = false;

	setPage = async page => {
		const { dataProvider } = this.props;
		const { data: warehouses } = await dataProvider(
			GET_LIST,
			`storageareas?limit=${perPage}&offset=${perPage * (page - 1)}&filter[level]=1&deleted=true`,
			{}
		);
		this.setState({ warehouses, page });
	};

	componentDidMount = async () => {
		const { dataProvider } = this.props;
		this._isMounted = true;
		try {
			const [warehouses, articlesCount] = await Promise.all([
				dataProvider(
					GET_LIST,
					`storageareas?limit=${perPage}&offset=0&filter[level]=1&deleted=true`,
					{}
				),
				dataProvider(GET_LIST, 'articles', {
					filter: {
						'deletedAt][isNull': 1
					}
				})
			]);

			if (!warehouses || !articlesCount) return;

			const nbPages = Math.ceil(warehouses.total / perPage) || 1;

			if (this._isMounted) {
				//to doo
				const [totalSpace, freeSpace] = warehouses.data.reduce(
					([accumulatorTotalSpace, accumulatorFreeSpace], { total_area, free_area }) => [
						accumulatorTotalSpace + total_area,
						accumulatorFreeSpace + free_area
					],
					[0, 0]
				);

				this.setState({
					totalSpace,
					freeSpace,
					warehouses: warehouses.data,
					totalArticlesCount: articlesCount.total,
					nbPages
				});
			}
		} catch (error) {
			console.error('[warehouse]: ', error.message);
		}
	};

	componentWillUnmount() {
		this._isMounted = false;
	}

	render() {
		const { classes } = this.props;
		const { totalSpace, freeSpace, warehouses, page, nbPages, totalArticlesCount } = this.state;

		return (
			<div>
				<div className={classes.firstLine}>
					<StorageSpace total={totalSpace} free={freeSpace} className={classes.widget} />
					<ArticlesCount totalArticlesCount={totalArticlesCount} className={classes.widget} />
				</div>
				<UpcomingDeliveries className={`${classes.widget} ${classes.customers}`} />
				<Warehouses
					warehouses={warehouses}
					className={`${classes.widget} ${classes.warehouses}`}
					page={page}
					nbPages={nbPages}
					setPage={this.setPage}
				/>
			</div>
		);
	}
}

const enhance = compose(withStyles(styles), withDataProvider);

export default enhance(Dashboard);
