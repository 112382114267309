import React, { useState } from 'react';
import Context from './Context';

const Provider = ({ children }) => {
	const [isContainerHallMovingMode, setIsContainerHallMovingMode] = useState(false);
	const [isContainerChosen, setIsContainerChosen] = useState(false);
	const [selectedContainerId, setSelectedContainerId] = useState(null);
	const [sectionIdForNotes, setSectionIdForNotes] = useState(null);
	const [chosenSubsection, setChosenSubsection] = useState(null);

	const toggleContainerHallMovingMode = () => {
		setIsContainerHallMovingMode(prev => !prev);
		chooseContainer(null);
		setIsContainerChosen(null);
	};

	const chooseContainer = container => {
		setSelectedContainerId(container?.id ?? null);
		if (selectedContainerId === container?.id) {
			setIsContainerChosen(false);
			setSelectedContainerId(null);
		} else {
			setIsContainerChosen(true);
		}
	};

	return (
		<Context.Provider
			value={{
				isContainerHallMovingMode,
				toggleContainerHallMovingMode,
				isContainerChosen,
				selectedContainerId,
				setSelectedContainerId,
				setIsContainerChosen,
				chooseContainer,
				chosenSubsection,
				setChosenSubsection,
				sectionIdForNotes,
				setSectionIdForNotes
			}}
		>
			{children}
		</Context.Provider>
	);
};

export default Provider;
