export const DELIVERY = 'Delivery';
export const PICKUP = 'Pickup';
export const DISPOSAL = 'Disposal';

export default [DELIVERY, PICKUP, DISPOSAL];

export const COMMENT = 'Comment';
export const SURNAME = 'Surname';
export const DATE = 'Date';
export const ADDRESS = 'Address';
