import { TextField, withStyles } from '@material-ui/core';
import React, { Component } from 'react';

import { FileInput } from '../components';
import { guttersContainer, marginGutters } from '../helpers';
import { compose } from 'recompose';
import { translate } from 'react-admin';

const styles = theme => {
	const gutters = marginGutters(theme);
	const container = guttersContainer(theme);
	const upSmBreakpoint = theme.breakpoints.up('sm');

	const guttersSize = gutters.marginLeft + gutters.marginRight;
	const upSmGuttersSize = gutters[upSmBreakpoint].marginLeft + gutters[upSmBreakpoint].marginRight;

	return {
		container: {
			...container,
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'wrap'
		},
		name: {
			marginTop: 0
		},
		input: {
			...gutters,
			flex: `1 0 calc(100% - ${guttersSize}px)`,
			[upSmBreakpoint]: {
				...gutters[upSmBreakpoint],
				flexBasis: `calc(50% - ${upSmGuttersSize}px)`
			}
		},
		fullWidth: {
			flexBasis: `calc(100% - ${guttersSize}px)`,
			[upSmBreakpoint]: {
				flexBasis: `calc(100% - ${upSmGuttersSize}px)`
			}
		}
	};
};

class FieldsAddNote extends Component {
	constructor(props) {
		super(props);
	}

	handleAfterUploadFile = () => {
		this.setState({ isLoading: false });
	};

	handleBeforeUploadFile = () => {
		this.setState({ isLoading: true });
	};

	render() {
		const { classes, handleChangePhotos, handleChangeNote, note, record, translate } = this.props;

		return (
			<div className={classes.container}>
				<TextField
					id="textarea"
					label={translate('pos.search.comment')}
					placeholder="Placeholder"
					multiline
					className={[classes.input, classes.fullWidth].join(' ')}
					margin="normal"
					onChange={handleChangeNote}
					value={note}
				/>
				<FileInput
					resource="documents"
					record={record}
					source="storage_photos"
					label="resources.articles.fields.photos"
					onBeforeUpload={this.handleBeforeUploadFile}
					onAfterUpload={this.handleAfterUploadFile}
					onChange={handleChangePhotos}
					disabled={record?.storage_photos?.length >= 5}
					accept={['image/x-png', 'image/gif', 'image/jpeg', 'image/png'].join(',')}
					className={classes.input}
					isImageField={true}
					maxFilesCount={5}
				/>
			</div>
		);
	}
}

const enhance = compose(translate, withStyles(styles));

export default enhance(FieldsAddNote);
