import React from 'react';
import { translate, Button } from 'react-admin';
import { compose } from 'recompose';

import { withStyles } from '@material-ui/core';

const styles = () => ({
	spacer: {
		marginTop: '20px'
	},
	button: {
		borderRadius: '0',
		transition: 'none',
		'&+button': {
			borderLeftColor: 'transparent'
		}
	}
});

const deliveryStatuses = ['upcoming', 'past'];

const UnconnectedUpcomingDeliveredToggle = ({ classes, currentDeliveryStatus, changeFilter }) => {
	return (
		<div className={classes.spacer}>
			{deliveryStatuses.map((deliveryStatus, index) => {
				return (
					<Button
						key={index}
						label={`ra.action.${deliveryStatus}`}
						variant={deliveryStatus === currentDeliveryStatus ? 'contained' : 'outlined'}
						onClick={() => changeFilter(deliveryStatus)}
						className={classes.button}
					/>
				);
			})}
		</div>
	);
};

const enhance = compose(withStyles(styles), translate);

const UpcomingDeliveredFilter = enhance(UnconnectedUpcomingDeliveredToggle);

export default UpcomingDeliveredFilter;
