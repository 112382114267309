import { withStyles } from '@material-ui/core';
import React from 'react';
import { List, Responsive, TextField } from 'react-admin';

import {
	AddressField,
	Datagrid,
	FullNameField,
	ListActions as AgentActions,
	ListFilter as AgentFilter,
	UpdatedAtField
} from '../components';
import styles from '../styles';

import MobileGrid from './MobileGrid';

const AgentList = ({ classes, ...props }) => (
	<List
		{...props}
		filters={<AgentFilter />}
		perPage={25}
		bulkActionButtons={false}
		exporter={false}
	>
		<Responsive
			medium={<MobileGrid />}
			large={
				<Datagrid>
					<FullNameField sortBy="user.surname" />
					<TextField
						label="resources.address.fields.company"
						source="address.company"
						className={classes.nowrap}
					/>
					<AddressField showName={false} sortBy="address" />

					<UpdatedAtField sortBy="updatedAt" headerClassName={classes.minWidth} />
					<AgentActions headerClassName={classes.minWidth} entityName={'agents'} />
				</Datagrid>
			}
		/>
	</List>
);

export default withStyles(styles)(AgentList);
