import React from 'react';

import { headerFullNameAndCompanyHelper } from '../helpers/headerNameHelpers';

const ProjectTitle = ({ record }) => (
	<span>
		{headerFullNameAndCompanyHelper(record.id, record.customer.address, record.customer.user)}
	</span>
);

export default ProjectTitle;
