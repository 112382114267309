import { GET_LIST } from 'react-admin';

export default async (dataProvider, projectId) => {
	let { data: warehouses } = await dataProvider(GET_LIST, 'storageareas', {
		filter: {
			project: projectId
		},
		pagination: {
			perPage: 10000,
			page: 1
		}
	});

	warehouses.sort(({ path: prevPath }, { path: nextPath }) => (nextPath > prevPath ? -1 : 1));

	return warehouses.map(warehouse => {
		if (warehouse.has_children && warehouse.children.some(child => !child.movable)) {
			warehouse.disabled = true;
		}

		return warehouse;
	});
};
