import { debounce } from 'lodash';
import { Badge, IconButton, Button } from '@material-ui/core';
import {
	ShoppingCart as ShoppingCartIcon,
	ArrowDropDown as DropdownIcon
} from '@material-ui/icons';
import React, { Component } from 'react';
import { WithPermissions, changeListParams } from 'react-admin';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { IN_CART } from '../articles/statuses';
import httpClient from '../providers/httpClient';
import { updateCarts } from '../AC';
import {
	ROLE_AGENT,
	ROLE_COLLECTOR_GUY,
	ROLE_MAIN_CUSTOMER,
	ROLE_OFFICE_EMPLOYEE,
	ROLE_SUPER_ADMIN
} from '../users/roles';
import CartsList from './CartsList';

class UnconnectedShoppingCart extends Component {
	_isMounted = false;
	_requestActive = false;

	state = {
		isDropdown: true,
		anchorEl: null
	};

	constructor(props) {
		super(props);

		this.updateCartsData = debounce(this.updateCartsData, 500);
	}

	componentDidMount() {
		this._isMounted = true;
	}

	componentDidUpdate = async ({ isLoading: prevIsLoading }) => {
		const { isLoading } = this.props;

		if (!isLoading && prevIsLoading) {
			if (this._interval) {
				clearInterval(this._interval);
			}
			if (!this._requestActive) await this.updateCartsData();
			this._interval = setInterval(this.updateCartsData, 60000);
		}
	};

	componentWillUnmount() {
		this._isMounted = false;
		if (this._interval) {
			clearInterval(this._interval);
		}
	}

	handleClick = () => {
		this.props.changeListParams('articles', {});
	};

	handleMenuLinkClick = () => {
		this.props.changeListParams('articles', {});
		if (this._isMounted) this.setState({ anchorEl: null });
	};

	handleExpand = event => {
		if (this._isMounted) {
			this.setState({
				anchorEl: event.currentTarget
			});
		}
	};

	handleClose = () => {
		if (this._isMounted) this.setState({ anchorEl: null });
	};

	render() {
		const { classes, carts } = this.props;
		const { articlesCountInCart, isDropdown, anchorEl } = this.state;

		return (
			<>
				<IconButton
					component={isDropdown ? Button : Link}
					onClick={isDropdown ? e => this.handleExpand(e) : this.handleClick}
					className={isDropdown ? classes.dropDownCart : ''}
					to="/shopping-cart"
				>
					{articlesCountInCart ? (
						<Badge color="secondary" badgeContent={articlesCountInCart}>
							<ShoppingCartIcon className={classes.circleUserPhoto} />
						</Badge>
					) : (
						<ShoppingCartIcon className={classes.circleUserPhoto} />
					)}
					{isDropdown ? <DropdownIcon className={classes.dropDownArrow} /> : ''}
				</IconButton>

				{isDropdown && (
					<CartsList
						carts={carts}
						anchorEl={anchorEl}
						close={this.handleClose}
						click={this.handleMenuLinkClick}
					/>
				)}
			</>
		);
	}

	updateCartsData = async () => {
		try {
			const { updateCarts, permissions } = this.props;
			const user = JSON.parse(localStorage.getItem('user'));
			if (!user?.id) return;

			let totalArticles;
			this._requestActive = true;
			const responseHead = await httpClient(
				`${process.env.REACT_APP_API_URL}/articles?filter[status][in][]=${IN_CART}`,
				{
					method: 'HEAD'
				}
			);

			if (responseHead && responseHead.headers) {
				totalArticles = parseInt(responseHead.headers.get('x-total-count'), 10);
			}

			const responseArticles = await httpClient(
				`${
					process.env.REACT_APP_API_URL
				}/articles?filter[status][in][]=${IN_CART}&limit=${totalArticles ?? 1000}`,
				{
					method: 'GET'
				}
			);
			this._requestActive = false;
			if (!responseArticles?.body) return;

			const { body: jsonArticles } = responseArticles;

			if (jsonArticles) {
				const articles = JSON.parse(jsonArticles);

				const updatedCarts = new Map();

				articles.forEach(article => {
					if (!article.reserved_by) return;
					if (!updatedCarts.has(article.reserved_by.id))
						updatedCarts.set(article.reserved_by.id, [article]);

					const articles = updatedCarts.get(article.reserved_by.id);

					if (!articles.find(item => item.id === article.id))
						updatedCarts.set(article.reserved_by.id, articles.concat(article));
				});

				updateCarts(updatedCarts);

				const userArticles = updatedCarts.get(user.id);

				if (this._isMounted) {
					const isTheOnlyCart = updatedCarts.get(user.id) && updatedCarts.size === 1;
					const hideDropdown = isTheOnlyCart || updatedCarts.size === 0;

					this.setState({
						articlesCountInCart: userArticles?.length ?? 0,
						isDropdown:
							!hideDropdown &&
							(permissions === ROLE_MAIN_CUSTOMER ||
								permissions === ROLE_SUPER_ADMIN ||
								permissions === ROLE_AGENT ||
								permissions === ROLE_OFFICE_EMPLOYEE)
					});
				}
			}
		} catch (e) {
			console.error(e);
		}
	};
}

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0, carts: state.carts });
const ShoppingCart = connect(mapStateToProps, { changeListParams, updateCarts })(
	UnconnectedShoppingCart
);

const WithPermissionsShoppingCart = props => (
	<WithPermissions
		match={null}
		location={null}
		render={({ permissions }) =>
			permissions !== ROLE_COLLECTOR_GUY ? (
				<ShoppingCart {...props} permissions={permissions} />
			) : null
		}
	/>
);

export default WithPermissionsShoppingCart;
