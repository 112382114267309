import { get, isEqual } from 'lodash';
import { withStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import {
	NumberInput,
	ReferenceInput,
	SimpleForm,
	Toolbar,
	LongTextInput,
	translate,
	REDUX_FORM_NAME
} from 'react-admin';

import { AutocompleteInput, CommentList, FileInput, ToolbarContainer } from '../components';
import { projectsUserNameHelper, configStyles, showAlertHelper } from '../helpers';
import generalStyles from '../styles';
import {
	ROLE_WAREHOUSE_MANAGER,
	ROLE_AGENT,
	ROLE_OFFICE_EMPLOYEE,
	ROLE_SUPER_ADMIN
} from '../users/roles';
import { required } from '../validations';

import LoadingPoints from './LoadingPoints';
import BarcodeList from './BarcodeList';
import ProjectAreas from './ProjectAreas';

const renderAddressField = () => ({ id, user, address }) =>
	projectsUserNameHelper({ address, user, showName: false, idProject: id });

let customers;

const UnconnectedCustomersAutocomplete = ({ translate, choices, disabled, ...props }) => {
	customers = choices;
	const filteredChoices = choices.filter(choice => !choice.user?.deleted_at);

	return (
		<AutocompleteInput
			{...props}
			// shouldRenderSuggestions={value => !!value}
			choices={filteredChoices}
			optionText={renderAddressField(translate)}
			options={{
				fullWidth: true,
				disabled: disabled,
				suggestionsContainerProps: {
					disablePortal: true
				}
			}}
		/>
	);
};

const CustomersAutocomplete = translate(UnconnectedCustomersAutocomplete);

const stylesFields = theme => {
	const {
		gutters,
		container,
		upSmBreakpoint,
		guttersSize,
		upSmGuttersSize,
		mediaMinWidth
	} = configStyles(theme);

	const styles = generalStyles(theme);

	return {
		...styles,
		root: {
			...container,
			[theme.breakpoints.up(mediaMinWidth)]: {
				display: 'flex',
				flexWrap: 'wrap'
			},
			'& ul[role="listbox"] div[role="menuitem"] div': {
				textOverflow: 'ellipsis',
				overflow: 'hidden',
				whiteSpace: 'nowrap'
			},
			'& div[role="tooltip"]': {
				maxWidth: '100%'
			}
		},
		child: {
			...gutters,
			width: `calc(100% - ${guttersSize}px)`,
			maxWidth: theme.breakpoints.values['md'],
			[upSmBreakpoint]: {
				...gutters[upSmBreakpoint],
				width: `calc(100% - ${upSmGuttersSize}px)`
			},
			[theme.breakpoints.up(mediaMinWidth)]: {
				flex: '1 0 1px',
				minWidth: theme.breakpoints.values['sm']
			}
		},
		cardField: {
			marginTop: theme.spacing.unit * 2,
			marginBottom: 0,
			[upSmBreakpoint]: {
				marginTop: theme.spacing.unit * 3
			}
		},
		fileInput: {
			[theme.breakpoints.up(mediaMinWidth)]: {
				maxWidth: 'none'
			}
		},
		loaderContainer: {
			backgroundColor: fade(theme.palette.background.default, 0.5)
		}
	};
};

class UnconnectedProjectFormFields extends Component {
	state = {
		isLoading: false,
		loadingPoints: [],
		unloadingPoints: [],
		documentUrls: []
	};

	_isMounted = false;

	static getDerivedStateFromProps({ record: recordProps }, { record: recordState }) {
		if (isEqual(recordProps, recordState)) {
			return {};
		}

		const changedCustomer = get(recordProps, 'customer');
		const allLoadingPoints = get(recordProps, 'loading_points') || [];
		const loadingPoints = allLoadingPoints.filter(({ is_destination }) => !is_destination);
		const unloadingPoints = allLoadingPoints.filter(({ is_destination }) => is_destination);

		const documentUrls = get(recordProps, 'document_urls') || [];

		return {
			record: recordProps,
			changedCustomer,
			loadingPoints,
			unloadingPoints,
			documentUrls
		};
	}

	componentDidUpdate() {
		const { initial, current } = this.props;

		if (!initial || !current) return;

		const initialDocumentUrls = initial.document_urls
			? initial.document_urls.map(url => {
					const pathArray = url.split('/');
					return {
						src: url,
						title: pathArray[pathArray.length - 1]
					};
			  })
			: [];

		showAlertHelper.saveDefaultState({
			...initial,
			document_urls: initialDocumentUrls,
			articles_number: undefined
		});
		showAlertHelper.saveCurrentState({ ...current, articles_number: undefined });
	}

	componentDidMount() {
		this._isMounted = true;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleAfterUploadFile = () => {
		if (this._isMounted) {
			this.setState({ isLoading: false });
		}
	};

	handleBeforeUploadFile = () => {
		if (this._isMounted) {
			this.setState({ isLoading: true });
		}
	};

	handleChangeCustomer = (event, customerId) => {
		if (!this._isMounted) {
			return;
		}

		const changedCustomer = (customers || []).find(({ id }) => id === customerId);

		if (changedCustomer) {
			this.setState({ changedCustomer });
		}
	};

	handleChangeFile = (event, documentUrls) => {
		const { loadingPoints, unloadingPoints } = this.state;
		this.setState({ documentUrls });
		this.onChange({ loadingPoints, unloadingPoints, documentUrls });
	};

	handleChangeLoadingPoints = newLoadingPoints => {
		const { unloadingPoints, documentUrls } = this.state;
		const loadingPoints = newLoadingPoints.map(loadingPoint => ({
			...loadingPoint,
			is_destination: false
		}));
		this.setState({ loadingPoints });
		this.onChange({ loadingPoints, unloadingPoints, documentUrls });
	};

	handleChangeUnloadingPoints = newUnloadingPoints => {
		const { loadingPoints, documentUrls } = this.state;
		const unloadingPoints = newUnloadingPoints.map(unloadingPoint => ({
			...unloadingPoint,
			is_destination: true
		}));
		this.setState({ unloadingPoints });
		this.onChange({ loadingPoints, unloadingPoints, documentUrls });
	};

	onChange = ({ loadingPoints, unloadingPoints, documentUrls }) => {
		const { onChange } = this.props;

		onChange({
			loading_points: [...loadingPoints, ...unloadingPoints],
			document_urls: documentUrls
		});
	};

	render() {
		const { classes, record, resource, permissions, edit } = this.props;
		const { isLoading, changedCustomer, loadingPoints, unloadingPoints } = this.state;
		return this._isMounted ? (
			<div className={classes.root}>
				<div className={`${classes.child}`}>
					<ReferenceInput
						resource={resource}
						record={record}
						disabled={edit}
						label="resources.projects.fields.customer"
						source="customer.id"
						reference="customers"
						filter={{ level: 1, 'deletedAt][isNull': 1 }}
						onChange={this.handleChangeCustomer}
						className={classes.fullWidth}
						validate={required(resource, 'customer')}
					>
						<CustomersAutocomplete disabled={permissions === ROLE_WAREHOUSE_MANAGER} />
					</ReferenceInput>
					{!record.id && (
						<NumberInput
							record={record}
							source="articles_number"
							resource={resource}
							className={classes.fullWidth}
							validate={required(resource, 'articles_number')}
						/>
					)}
				</div>
				<LongTextInput
					record={record}
					resource={resource}
					source="order_number"
					className={classes.child}
					disabled={permissions === ROLE_WAREHOUSE_MANAGER}
				/>

				<LoadingPoints
					customer={changedCustomer}
					loadingPoints={loadingPoints}
					onChange={this.handleChangeLoadingPoints}
					classes={{ root: `${classes.child} ${classes.cardField}` }}
				/>
				<LoadingPoints
					customer={changedCustomer}
					loadingPoints={unloadingPoints}
					onChange={this.handleChangeUnloadingPoints}
					title={`resources.${resource}.fields.unloading_points`}
					classes={{ root: `${classes.child} ${classes.cardField}` }}
				/>

				{record.id && (
					<BarcodeList
						record={record}
						resource={resource}
						className={`${classes.child} ${classes.cardField}`}
						host={`${window.location.protocol}//${window.location.host}`}
						route="/articles/create?barcode="
						showAddAndPrint={
							permissions === ROLE_SUPER_ADMIN ||
							permissions === ROLE_AGENT ||
							permissions === ROLE_OFFICE_EMPLOYEE ||
							permissions === ROLE_WAREHOUSE_MANAGER
						}
					/>
				)}

				{record.id && (
					<BarcodeList
						record={record}
						resource={resource}
						used={true}
						className={`${classes.child} ${classes.cardField}`}
						host={`${window.location.protocol}//${window.location.host}`}
						route="/articles/"
						deletable={false}
						addable={false}
					/>
				)}

				<FileInput
					resource="documents"
					record={record}
					source="document_urls"
					label="resources.projects.fields.document_urls"
					onBeforeUpload={this.handleBeforeUploadFile}
					onAfterUpload={this.handleAfterUploadFile}
					onChange={this.handleChangeFile}
					className={`${classes.child} ${classes.fileInput} ${classes.cardField}`}
					accept={[
						'application/pdf',
						'application/msword',
						'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
						'application/octet-stream',
						'application/rtf',
						'text/plain',
						'text/rtf',
						'.png',
						'.gif',
						'.jpeg',
						'.jpg'
					].join(',')}
				/>

				{record.id && (
					<ProjectAreas
						record={record}
						resource={resource}
						className={`${classes.fileInput} ${classes.cardField}`}
					/>
				)}

				{isLoading && (
					<div className={`loader-container ${classes.loaderContainer}`}>
						<div className="loader">Loading...</div>
					</div>
				)}
			</div>
		) : null;
	}
}

const mapStateToProps = state => ({
	initial: state.form[REDUX_FORM_NAME]?.initial,
	current: state.form[REDUX_FORM_NAME]?.values
});

const enhance = compose(
	withStyles(stylesFields),
	connect(mapStateToProps, null, null, { pure: false })
);

const ProjectFormFields = enhance(UnconnectedProjectFormFields);

const stylesForm = theme => {
	const { mediaMinWidth, upSmBreakpoint } = configStyles(theme);

	return {
		root: {
			position: 'relative'
		},
		container: {
			width: '100%',
			[theme.breakpoints.down(mediaMinWidth)]: {
				maxWidth: theme.breakpoints.values['md']
			}
		},
		comments: {
			marginTop: theme.spacing.unit * 2,
			marginBottom: 0,
			[upSmBreakpoint]: {
				marginTop: theme.spacing.unit * 3
			}
		}
	};
};

class ProjectForm extends Component {
	otherData = {};

	handleChange = otherData => {
		this.otherData = otherData;
	};

	handleSave = ({ areas, transferTools, project_areas, ...project }, redirect) => {
		const { save } = this.props;

		if (project.customer) {
			project.customer = { id: project.customer.id };
		}

		save(
			{
				...project,
				project_areas: project_areas
					? project_areas.map((projectArea, index) => ({
							...projectArea,
							area: areas[index],
							transfer_tools: transferTools && transferTools[index]
					  }))
					: undefined,
				...this.otherData
			},
			redirect
		);
	};

	render() {
		const { classes, record, permissions, edit, ...props } = this.props;

		return (
			<SimpleForm
				{...props}
				redirect={record.id ? false : 'edit'}
				toolbar={
					<Toolbar classes={{ spacer: classes.spacer }}>
						<ToolbarContainer
							className={classes.container}
							deletable={permissions !== ROLE_WAREHOUSE_MANAGER}
						/>
					</Toolbar>
				}
				className={classes.root}
				save={this.handleSave}
				record={record}
			>
				<ProjectFormFields onChange={this.handleChange} permissions={permissions} edit={edit} />

				{record.id && record.thread && (
					<CommentList className={`${classes.container} ${classes.comments}`} />
				)}
			</SimpleForm>
		);
	}
}

export default withStyles(stylesForm)(ProjectForm);
