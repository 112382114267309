import { withStyles } from '@material-ui/core';
import { Button } from 'react-admin';
import { Print as PrintIcon } from '@material-ui/icons';
import React, { Fragment, useEffect, useState } from 'react';
import { compose } from 'recompose';
import ReactToPrint from 'react-to-print';
import { BarcodeView } from '../../../components';
import QRCode from 'qrcode.react';

const styles = {
	printedBarcodes: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	printedContainer: {
		width: 711,
		minWidth: 711
	},
	qrCodeContainer: {
		boxSizing: 'border-box',
		padding: 3.78,
		float: 'left',
		'&:nth-child(24n + 1), &:nth-child(24n + 2), &:nth-child(24n + 3), &:nth-child(24n + 4)': {
			paddingTop: 39.44
		},
		'&:nth-child(24n), &:nth-child(24n - 1), &:nth-child(24n - 2), &:nth-child(24n - 3)': {
			paddingBottom: 24.32
		}
	},
	descriptionBarcode: {
		color: '#000',
		width: '210px !important',
		height: '210px !important',
		position: 'absolute',
		top: -22,
		left: -22,
		fontSize: 10,
		lineHeight: '10px',
		wordBreak: 'break-all',
		'& > div': {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			position: 'absolute',
			width: 190
		},
		'& .snake-0': {
			transform: 'rotate(-90deg)',
			transformOrigin: '0 100%',
			bottom: 20,
			left: 10
		},
		'& .snake-1': {
			transform: 'rotate(-90deg)',
			transformOrigin: '0 100%',
			bottom: 20,
			left: 20
		},
		'& .snake-2': {
			top: 0,
			left: 20
		},
		'& .snake-3': {
			top: 10,
			left: 20
		},
		'& .snake-4': {
			right: 0,
			bottom: -10,
			transform: 'rotate(90deg)',
			transformOrigin: '100% 0'
		},
		'& .snake-5': {
			right: 10,
			bottom: -10,
			transform: 'rotate(90deg)',
			transformOrigin: '100% 0'
		},
		'& .snake-6': {
			bottom: 0,
			left: 0,
			transform: 'rotate(180deg)'
		},
		'& .snake-7': {
			bottom: 10,
			left: 0,
			transform: 'rotate(180deg)'
		}
	},
	'@media print': {
		descriptionBarcode: {
			top: -20,
			left: -20,
			width: '155px !important',
			height: '165px !important',
			lineHeight: '10px',
			fontSize: 10,
			'& > div': {
				width: 134
			}
		}
	}
};

const PrintAllContainer = ({ classes, record }) => {
	const [allPrintedBarcodes, setAllPrintedBarcodes] = useState([]);
	const [barcodes, setBarcodes] = useState([]);

	const setAllPrintedBarcodesRef = barcodes => {
		setAllPrintedBarcodes(barcodes);
	};

	useEffect(() => {
		const barcodes = [];
		record.children.forEach(child =>
			child.children.forEach(nextChild =>
				nextChild.children.forEach(thirdChild => {
					barcodes.push(thirdChild);
				})
			)
		);
		setBarcodes(barcodes);
	}, [record]);

	return (
		<>
			<ReactToPrint
				content={() => allPrintedBarcodes}
				trigger={() => (
					<Button label="ra.action.print_all">
						<PrintIcon />
					</Button>
				)}
			/>
			<div style={{ display: 'none' }}>
				<div ref={setAllPrintedBarcodesRef} className={classes.printedBarcodes}>
					<div className={classes.printedContainer}>
						{barcodes.map(barcode => {
							return (
								<div key={barcode.id} className={classes.qrCodeContainer}>
									<BarcodeView size={170.14}>
										<Fragment>
											<QRCode
												value={JSON.stringify({
													storagearea: barcode.id,
													name: barcode.name
												})}
												level="H"
											/>
											<div className={classes.descriptionBarcode}>
												{barcode.full_name.split('->').map((el, ind) => {
													return (
														<div className={`snake-${ind}`} key={ind}>
															{el}
														</div>
													);
												})}
											</div>
										</Fragment>
									</BarcodeView>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</>
	);
};

const enhance = compose(withStyles(styles));

export default enhance(PrintAllContainer);
