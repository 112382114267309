import {
	SimpleShowLayout,
	translate,
	Button,
	withDataProvider,
	UPDATE,
	GET_ONE
} from 'react-admin';
import { Card, CardContent, CardHeader, withStyles, CardActions } from '@material-ui/core';
import { Save as SaveIcon } from '@material-ui/icons';
import { DragDropContext } from 'react-beautiful-dnd';
import React, { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { nextUniqueKey, showAlertHelper } from '../../helpers';
import { compose } from 'recompose';
import Search from '../Search';
import { CONTAINER, CUSTOMER, ORDER_NUMBER, NOTE } from '../searchContext/constants';
import Section from './Section';
import { useMovingMode } from './context';

const styles = theme => ({
	searchBlock: {
		display: 'flex',
		gap: '30px'
	},
	inputs: {
		display: 'flex',
		width: '80%',
		flexDirection: 'column'
	},
	nameField: {
		minWidth: '200px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		'& label': {
			color: 'rgba(0, 0, 0, 0.54)',
			padding: 0,
			fontSize: '0.8rem',
			lineHeight: 1
		},
		'& span': {
			color: 'rgba(0, 0, 0, 0.87)',
			fontSize: '1rem',
			fontWeight: '400',
			lineHeight: '1.46429em'
		}
	},
	header: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	card: {
		width: '100%',
		maxWidth: theme.breakpoints.values['md'],
		marginTop: theme.spacing.unit * 3
	}
});

const findStorageArea = (storageAreas, storageAreaId) => {
	let storageArea;

	for (let childArea of storageAreas) {
		storageArea =
			childArea.id === storageAreaId
				? childArea
				: findStorageArea(childArea.children, storageAreaId);

		if (storageArea) {
			break;
		}
	}

	return storageArea;
};

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const Layout = ({ record, classes, translate, dataProvider }) => {
	const [sections, setSections] = useState([]);
	const [changedStorages, setChangedStorages] = useState(new Set());
	const { setDragging } = useMovingMode();
	const { name } = record;

	useEffect(() => {
		showAlertHelper.saveDefaultState([]);
		const childrenCopy = cloneDeep(record.children).map(child => ({
			...child,
			uniqueKey: nextUniqueKey()
		}));
		setSections(childrenCopy);
	}, []);

	useEffect(() => {
		showAlertHelper.saveCurrentState(Array.from(changedStorages));
	}, [changedStorages]);

	const path = [record.name];

	const handleSetChangedStorages = storageIds => {
		storageIds.forEach(storageId => {
			setChangedStorages(prev => new Set(prev.add(storageId)));
		});
	};

	const cleanChangedStorages = () => {
		setChangedStorages(prev => {
			prev.clear();
			return new Set();
		});
	};

	const changeTree = event => {
		const { draggableId, source, destination } = event;
		if (!destination || !draggableId) return;

		const newIndex = destination.index;
		const currentIndex = source.index;

		const newParentId = +destination.droppableId;
		const sectionId = +draggableId;
		const section = findStorageArea(sections, sectionId);
		const parentId = section.parent_id;

		if (parentId === newParentId && currentIndex === newIndex) return;

		const parent = findStorageArea(sections, parentId);
		const newParent = findStorageArea(sections, newParentId);

		if (!parentId || !parent || !newParent || !section) return;

		if (parentId === newParentId) {
			parent.children = reorder(parent.children, currentIndex, newIndex);
			handleSetChangedStorages([parentId]);
			return;
		}

		const [removed] = parent.children.splice(source.index, 1);
		newParent.children.splice(destination.index, 0, removed);

		section.parent_id = newParentId;

		setSections(prev => [...prev]);
		handleSetChangedStorages([parentId, newParentId]);
	};

	const save = async event => {
		event.preventDefault();
		const promises = [];
		changedStorages.forEach(changedStorageId => {
			const changedStorage = findStorageArea(sections, changedStorageId);
			if (!changedStorage) return;

			changedStorage.children.forEach((child, index) => {
				promises.push(
					dataProvider(UPDATE, 'storageareas', {
						id: child.id,
						data: {
							id: child.id,
							storage_order: index,
							parent: {
								id: child.parent_id
							}
						}
					})
				);
			});
		});

		await Promise.all(promises);
		await dataProvider(GET_ONE, 'storageareas', {
			id: record.id
		});
		cleanChangedStorages();
	};

	const handleDragEnd = event => {
		setDragging(false);
		changeTree(event);
	};

	const handleDragStart = () => {
		setDragging(true);
	};

	return (
		<SimpleShowLayout record={record}>
			<div className={classes.inputs}>
				<div className={classes.nameField}>
					<label htmlFor="name">{translate('resources.warehouses.data.name')}</label>
					<span>{record.name}</span>
				</div>
				<div className={classes.searchBlock}>
					<Search sections={record?.children} searchBy={CONTAINER} rootId={record?.id} />
					<Search sections={record?.children} searchBy={CUSTOMER} rootId={record?.id} />
					<Search sections={record?.children} searchBy={ORDER_NUMBER} rootId={record?.id} />
					<Search sections={record?.children} searchBy={NOTE} rootId={record?.id} />
				</div>
			</div>
			<Card className={classes.card}>
				<CardHeader
					title={
						<div className={classes.header}>
							<div>
								{translate('resources.warehouses.data.structure')} {name && `(${name})`}
							</div>
						</div>
					}
				/>
				<CardContent>
					<DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
						{sections.map((section, index) => (
							<Section section={section} key={section.id} index={index} path={path} />
						))}
					</DragDropContext>
				</CardContent>
				<CardActions>
					<Button
						disabled={!changedStorages.size}
						label="Save"
						variant="contained"
						color="primary"
						onClick={save}
					>
						<SaveIcon />
					</Button>
				</CardActions>
			</Card>
		</SimpleShowLayout>
	);
};

const enhance = compose(translate, withStyles(styles), withDataProvider);

export default enhance(Layout);
