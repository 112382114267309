export const findStorageArea = (storageAreas, storageAreaId) => {
	let storageArea;

	for (let childArea of storageAreas) {
		storageArea =
			childArea.id === storageAreaId
				? childArea
				: findStorageArea(childArea.children, storageAreaId);

		if (storageArea) {
			break;
		}
	}

	return storageArea;
};

export const removeStorageArea = (storageAreas, storageAreaId) => {
	const index = storageAreas.findIndex(storageArea => storageArea.id === storageAreaId);
	if (index !== -1) {
		storageAreas.splice(index, 1);
	}

	storageAreas.forEach(storageArea => removeStorageArea(storageArea.children, storageAreaId));
};

export const addStorageArea = (storageAreas, newStorageArea) => {
	const parent = findStorageArea(storageAreas, newStorageArea.parent_id);

	if (parent) {
		parent.children.push(newStorageArea);
	} else {
		storageAreas.push(newStorageArea);
	}
};

export const moveStorageArea = (storageAreas, storageArea, prevParentId, newParentId) => {
	const prevParent = findStorageArea(storageAreas, prevParentId);
	const newParent = findStorageArea(storageAreas, newParentId);

	if (!prevParent || !newParent) return;

	prevParent.children = prevParent.children.filter(child => child.id !== storageArea.id);
	newParent.children.push(storageArea);
};
