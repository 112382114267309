import { CircularProgress, Typography, withStyles } from '@material-ui/core';
import { CameraAlt as CameraIcon, OpenInBrowser as OpenIcon } from '@material-ui/icons';
import React, { Component, Fragment } from 'react';
import { Button, translate } from 'react-admin';
import BaseQrReader from 'react-qr-reader';
import { compose } from 'recompose';

import Dialog from './Dialog';
import SnackbarNotification from './SnackbarNotification';

const styles = theme => ({
	centerContainerStyle: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: 100
	},
	hozContainerStyle: {
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.up('sm')]: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center'
		}
	},
	inputContainer: {
		width: '100%',
		maxWidth: 400
	},
	marginBottom: {
		marginBottom: 16
	},
	leftLabel: {
		paddingRight: '0.5em'
	},
	rightLabel: {
		paddingLeft: '0.5em'
	}
});

/**
 * <QrReaderButton onConfirm={result => alert(result)} />
 */
class UnconnectedQrReaderButton extends Component {
	state = {
		loading: true,
		legacyMode: false,
		isOpen: false,
		isOpenError: false
	};

	static getDerivedStateFromProps({ isOpenDefault }, { isOpenDefault: stateIsOpenDefault }) {
		if (isOpenDefault !== stateIsOpenDefault) {
			return {
				isOpenDefault,
				isOpen: isOpenDefault
			};
		}

		return {};
	}

	handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		this.setState({ isOpenError: false });
	};

	handleConfirmQrCode = async result => {
		const { onConfirm } = this.props;
		try {
			await onConfirm(result);
			this.handleDialogClose();
		} catch (err) {
			this.setState({ isOpenError: true });
		}
	};

	handleDialogClose = () => {
		const { onClose } = this.props;

		if (onClose) {
			onClose();
		}

		this.setState({
			isOpen: false
		});
	};

	handleError = err => {
		console.error(err);
		this.setState({
			legacyMode: true
		});
	};

	handleImgSubmit = () => {
		this.qrReaderInput.openImageDialog();
	};

	handleLoad = () => {
		this.setState({
			loading: false
		});
	};

	handleOpenDialogClick = () => {
		const { onClick } = this.props;
		if (onClick) {
			onClick();
		}

		this.setState({
			isOpen: true
		});
	};

	handleScan = result => {
		if (result) {
			this.handleConfirmQrCode(result);
		}
	};

	render() {
		const {
			classes,
			translate,
			className,
			disabled,
			label,
			icon,
			alignIcon,
			size,
			variant
		} = this.props;
		const { isOpen, loading, legacyMode } = this.state;

		return (
			<Fragment>
				<Button
					className={className}
					disabled={disabled}
					variant={variant}
					color="primary"
					onClick={this.handleOpenDialogClick}
					label={label || 'qr_reader.open'}
					alignIcon={alignIcon}
					size={size}
				>
					{icon}
				</Button>
				<Dialog isOpen={isOpen} title="qr_reader.title" onClose={this.handleDialogClose}>
					{loading && (
						<div className={classes.centerContainerStyle}>
							<CircularProgress size={50} thickness={2} />
						</div>
					)}

					<div style={{ display: loading ? 'none' : 'block' }}>
						<SnackbarNotification isOpen={this.state.isOpenError} onClose={this.handleClose} />
						{legacyMode && (
							<div className={classes.hozContainerStyle}>
								<Typography variant="subheading" className={classes.marginBottom}>
									{translate('qr_reader.webcam_not_supported')}
								</Typography>
								<Button
									onClick={this.handleImgSubmit}
									variant="contained"
									color="primary"
									className={classes.marginBottom}
									label="qr_reader.submit_an_image"
								>
									<OpenIcon />
								</Button>
							</div>
						)}

						<div className={`${classes.inputContainer} ${classes.marginBottom}`}>
							<BaseQrReader
								onScan={this.handleScan}
								onError={this.handleError}
								onLoad={this.handleLoad}
								legacyMode={legacyMode}
								ref={this.setQrReaderInput}
							/>
						</div>
					</div>
				</Dialog>
			</Fragment>
		);
	}

	setQrReaderInput = qrReaderInput => {
		this.qrReaderInput = qrReaderInput;
	};
}

const enhance = compose(withStyles(styles), translate);

const QrReaderButton = enhance(UnconnectedQrReaderButton);

QrReaderButton.defaultProps = {
	icon: <CameraIcon />,
	alignIcon: 'left',
	size: 'small',
	isOpenDefault: false,
	variant: 'contained'
};

export default QrReaderButton;
