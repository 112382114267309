import { withStyles, Card, CardContent, CardHeader } from '@material-ui/core';
import { Print as PrintIcon } from '@material-ui/icons';
import React, { Component, Fragment, useState } from 'react';
import { translate } from 'react-admin';
import { compose } from 'recompose';
import { Button } from 'react-admin';

import AddSectionButton from './AddSectionButton';
import ReactToPrint from 'react-to-print';
import NodeForPrint from './NodeForPrint';
import SectionNodeWrapper from './SectionNodeWrapper';

const styles = {
	header: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	printButton: {
		float: 'right',
		marginRight: 24,
		marginTop: 16,
		marginBottom: 16
	},
	printedBarcodes: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	printedContainer: {
		width: 711,
		minWidth: 711
	}
};

const PrintAllBarcodes = ({ children: propsChildren, onPrint, classes, name, disabled }) => {
	const [children, setChildren] = useState(propsChildren || []);
	const [allPrintedBarcodes, setAllPrintedBarcodes] = useState([]);

	const handleBeforeGetContent = () => {
		const children = onPrint();

		return new Promise(resolve => {
			setChildren(children);
			resolve();
		});
	};

	const allPrintedBarcodesRef = allPrintedBarcodes => {
		setAllPrintedBarcodes(allPrintedBarcodes);
	};

	return (
		<Fragment>
			<ReactToPrint
				content={() => allPrintedBarcodes}
				onBeforeGetContent={handleBeforeGetContent}
				trigger={() => (
					<Button label="ra.action.print_all" className={classes.printButton}>
						<PrintIcon />
					</Button>
				)}
			/>
			<div style={{ display: 'none' }}>
				<div ref={allPrintedBarcodesRef} className={classes.printedBarcodes}>
					<div className={classes.printedContainer}>
						<NodeForPrint
							section={{ children: children || [] }}
							isRoot={true}
							path={[name]}
							disabled={disabled}
						/>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

class Structure extends Component {
	handleAddSection = resource => {
		this.sectionNode.handleAddSection(resource);
	};

	handleOnChange = ({ children }) => {
		const { onChange } = this.props;
		onChange(children);
	};

	// eslint-disable-next-line no-unused-vars
	shouldComponentUpdate(nextProps, nextState, nextContext) {
		return (
			this.props.name !== nextProps.name ||
			this.props.disabled !== nextProps.disabled ||
			this.props.openedSections !== nextProps.openedSections ||
			this.props.children !== nextProps.children
		);
	}

	handlePrint = () => {
		return this.props.children;
	};

	render() {
		const {
			children,
			classes,
			className,
			name,
			translate,
			record,
			disabled,
			onExpand,
			openSection,
			formSection,
			isEdit
		} = this.props;

		return (
			<Card className={className}>
				<PrintAllBarcodes
					onPrint={this.handlePrint}
					classes={classes}
					name={name}
					disabled={disabled}
				>
					{children}
				</PrintAllBarcodes>
				<CardHeader
					title={
						<div className={classes.header}>
							<div>
								{translate('resources.warehouses.data.structure')} {name && `(${name})`}
							</div>
							{!disabled && (
								<AddSectionButton
									onAdd={this.handleAddSection}
									label="ra.action.add_subsection"
									section={{
										...record,
										name
									}}
								/>
							)}
						</div>
					}
				/>
				<CardContent>
					<SectionNodeWrapper
						section={{ children: children || [] }}
						formSection={formSection}
						isRoot={true}
						innerRef={this.setSectionNode}
						onChange={this.handleOnChange}
						path={[name]}
						onExpand={onExpand}
						disabled={disabled}
						visible={true}
						openSection={openSection}
						isEdit={isEdit}
					/>
				</CardContent>
			</Card>
		);
	}

	setSectionNode = sectionNode => {
		if (sectionNode) this.sectionNode = sectionNode.getWrappedInstance();
	};
}

const enhance = compose(withStyles(styles), translate);

export default enhance(Structure);
