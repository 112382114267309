import { get } from 'lodash';
import { withStyles } from '@material-ui/core';
import React from 'react';
import TimeAgo from 'react-timeago';
import { format } from 'date-fns';

import styles from '../styles';

const UnconnectedUpdatedAtField = ({ classes, source, record = {} }) => {
	const updated_at = get(record, source);

	return (
		<span>
			{updated_at && new Date() - new Date(updated_at) < 604800000 ? (
				<TimeAgo date={updated_at} className={classes.nowrap} />
			) : (
				format(new Date(updated_at), 'yyyy-MM-dd')
			)}
		</span>
	);
};

const UpdatedAtField = withStyles(styles)(UnconnectedUpdatedAtField);

UpdatedAtField.defaultProps = {
	source: 'updated_at',
	label: 'resources.general.fields.updated_at',
	addLabel: true
};

export default UpdatedAtField;
