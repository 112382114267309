import React from 'react';
import { Edit } from 'react-admin';

import { FullNameField } from '../components';

import AgentForm from './AgentForm';

const AgentTitle = ({ record }) => (
	<span>
		#{record.id}. <FullNameField record={record} />
	</span>
);

const AgentEdit = ({ permissions, ...props }) => (
	<Edit title={<AgentTitle />} undoable={false} {...props}>
		<AgentForm permissions={permissions} />
	</Edit>
);

export default AgentEdit;
