import React from 'react';
import { Edit } from 'react-admin';

import ArticleCategoryForm from './ArticleCategoryForm';

const ArticleCategoryTitle = ({ record }) => (
	<span>
		#{record.id}. {record.name}
	</span>
);

const ArticleCategoryEdit = props => (
	<Edit title={<ArticleCategoryTitle />} undoable={false} {...props}>
		<ArticleCategoryForm />
	</Edit>
);

export default ArticleCategoryEdit;
