import React, { Fragment, Component } from 'react';
import { withStyles, CardActions } from '@material-ui/core';
import { Delete as DeleteIcon, Print as PrintIcon } from '@material-ui/icons';
import { translate, withDataProvider, Button } from 'react-admin';
import ReactToPrint from 'react-to-print';
import QRCode from 'qrcode.react';
import { compose } from 'recompose';

import { Dialog, BarcodeView } from '../components';
import AddBarcodeButton from './AddBarcodeButton';

const styles = () => ({
	root: {
		justifyContent: 'flex-end'
	},
	printedBarcodes: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	printedContainer: {
		width: 711,
		minWidth: 711
	},
	printedBarcode: {
		boxSizing: 'border-box',
		padding: 3.78,
		float: 'left',
		'&:nth-child(24n + 1), &:nth-child(24n + 2), &:nth-child(24n + 3), &:nth-child(24n + 4)': {
			paddingTop: 39.44
		},
		'&:nth-child(24n), &:nth-child(24n - 1), &:nth-child(24n - 2), &:nth-child(24n - 3)': {
			paddingBottom: 24.32
		}
	}
});

class UnconnectedBarcodeListActions extends Component {
	render() {
		const {
			inHeader,
			addable,
			onAdd,
			record,
			translate,
			deletable,
			handleClickDeleteBarcodes,
			isOpenDeleteDialog,
			confirmDeleteDialog,
			closeDeleteDialog,
			allPrintedBarcodes,
			selectedIds,
			setAllPrintedBarcodes,
			classes,
			barcodes,
			addAndPrint,
			host,
			route
		} = this.props;

		if (!inHeader && (!barcodes || barcodes.length === 0)) return null;

		return (
			<CardActions className={inHeader && classes.root}>
				{addable && (
					<AddBarcodeButton
						addAndPrint={addAndPrint}
						onAdd={onAdd}
						projectId={record.id}
						host={host}
						route={route}
						barcodesStyles={classes}
						record={record}
					/>
				)}
				{deletable && (
					<Fragment>
						<Button
							label="ra.action.delete_selected"
							color="secondary"
							onClick={handleClickDeleteBarcodes}
						>
							<DeleteIcon />
						</Button>
						<Dialog
							isOpen={isOpenDeleteDialog}
							title="ra.message.bulk_delete_title"
							content="ra.message.bulk_delete_content"
							translateOptions={{
								name: translate('resources.barcodes.name', {
									smart_count: selectedIds.length
								}),
								smart_count: selectedIds.length
							}}
							onConfirm={confirmDeleteDialog}
							onClose={closeDeleteDialog}
						/>
					</Fragment>
				)}
				<ReactToPrint
					content={() => allPrintedBarcodes}
					trigger={() => (
						<Button label="ra.action.print_selected">
							<PrintIcon />
						</Button>
					)}
				/>
				<div style={{ display: 'none' }}>
					<div ref={setAllPrintedBarcodes} className={classes.printedBarcodes}>
						<div className={classes.printedContainer}>
							{barcodes
								.filter(barcode => selectedIds.includes(barcode.id))
								.map(({ id }) => (
									<div key={id} className={classes.printedBarcode}>
										<BarcodeView project={record} record={{ id }} size={170}>
											<QRCode value={host + route + id} level="H" />
										</BarcodeView>
									</div>
								))}
						</div>
					</div>
				</div>
			</CardActions>
		);
	}
}

const enhance = compose(withStyles(styles), withDataProvider, translate);

const BarcodeListActions = enhance(UnconnectedBarcodeListActions);

export default BarcodeListActions;
