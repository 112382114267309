import React from 'react';
import NavigateNext from '@material-ui/icons/NavigateNext';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import PropTypes from 'prop-types';
import { Button } from 'react-admin';

const NavArrow = ({ type, onClick, disabled, className }) => {
	const renderIcon = () => {
		switch (type) {
			case 'next':
				return <NavigateNext />;
			case 'prev':
				return <NavigateBefore />;
			default:
				return '';
		}
	};
	return (
		<Button onClick={onClick} disabled={disabled} className={className}>
			{renderIcon()}
		</Button>
	);
};

NavArrow.propTypes = {
	type: PropTypes.string,
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	className: PropTypes.string
};

NavArrow.defaultProps = {
	type: 'next',
	onClick: () => {},
	disabled: false,
	className: ''
};

export default NavArrow;
