import { keyBy } from 'lodash';
import { CircularProgress, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { lightTheme } from '../layout/themes';
import ArticleDatagrid from '../articles/ArticleDatagrid';
import generalStyles from '../styles';
import RemoveArticleButton from './RemoveArticleButton';
import ExchangeArticleButton from './ExchangeArticleButton';
import { OUTSOURCED } from '../articles/statuses';
import { dataProvider } from '../providers';
import { GET_LIST, Pagination } from 'react-admin';

const styles = theme => {
	const { nowrap } = generalStyles(theme);

	return {
		nowrap,
		lastLine: {
			'& > tbody > tr:last-child > td': {
				border: 'none'
			}
		}
	};
};

const ExpandDelivery = ({
	classes,
	permissions,
	record,
	handleConfirmRemove,
	scannedBarcodes,
	exchange
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [articles, setArticles] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);

	useEffect(() => {
		setIsLoading(true);
		dataProvider(GET_LIST, 'articles', {
			filter: {
				delivery: record.id
			},
			pagination: { page, perPage }
		})
			.then(data => {
				if (data && data.total) {
					setTotalCount(data.total);
				}
				if (!data?.data) return;
				record.articles = data.data;
				setArticles(data.data);
			})
			.catch(error => {
				console.error(error);
			})
			.finally(() => {
				setIsLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, perPage]);

	const scannedArticleRowStyle = ({ barcodes, status }) => {
		if (status === OUTSOURCED) {
			return {
				backgroundColor: lightTheme.palette.primary.outsourced
			};
		} else if (!barcodes.filter(({ id }) => !scannedBarcodes.includes(id)).length) {
			return {
				backgroundColor: lightTheme.palette.primary.scanned
			};
		}
	};

	if (isLoading) {
		return (
			<div style={{ width: '40px', margin: '20px auto' }}>
				<CircularProgress size={40} />
			</div>
		);
	}

	return (
		<>
			{totalCount > 0 && (
				<Pagination
					page={page}
					perPage={perPage}
					setPage={setPage}
					setPerPage={setPerPage}
					total={totalCount}
					rowsPerPageOptions={[5, 10, 25, 100, 250, 500]}
				/>
			)}
			<ArticleDatagrid
				ids={articles?.map(({ id }) => id) ?? []}
				data={keyBy(articles ?? [], 'id')}
				currentSort={{
					field: 'id',
					order: 'DESC'
				}}
				rowStyle={scannedArticleRowStyle}
				basePath="deliveries"
				isSortingBanned={true}
				permissions={permissions}
				className={classes.lastLine}
				scannedBarcodes={scannedBarcodes}
				outsourcingPage={true}
				customActions={[
					<RemoveArticleButton
						key="1"
						className={classes.nowrap}
						deliveryId={record.id}
						handleConfirmRemove={handleConfirmRemove}
						scannedBarcodes={scannedBarcodes}
					/>,
					<ExchangeArticleButton
						key="2"
						className={classes.nowrap}
						scannedBarcodes={scannedBarcodes}
						deliveryId={record.id}
						exchange={exchange}
					/>
				]}
			/>
			{totalCount > 0 && (
				<Pagination
					page={page}
					perPage={perPage}
					setPage={setPage}
					setPerPage={setPerPage}
					total={totalCount}
					rowsPerPageOptions={[5, 10, 25, 100, 250, 500]}
				/>
			)}
		</>
	);
};

export default withStyles(styles)(ExpandDelivery);
