import React from 'react';
import { SelectInput } from 'react-admin';

import WarehouseField from './WarehouseField';

const CustomSelectStorageAreaInput = ({ choices, className }) => {
	const warehouses = [...choices]
		.sort(({ path: prevPath }, { path: nextPath }) => (nextPath > prevPath ? -1 : 1))
		.map(warehouse => {
			if (warehouse.has_children) {
				warehouse.disabled = true;
			}
			return warehouse;
		});

	return (
		<SelectInput
			choices={warehouses}
			className={className}
			label="resources.articles.fields.warehouse_place"
			source="storagearea"
			optionText={<WarehouseField />}
		/>
	);
};

export default CustomSelectStorageAreaInput;
