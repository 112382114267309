import { withStyles } from '@material-ui/core';
import React from 'react';
import { compose } from 'recompose';

import { withCountries } from '../helpers';
import styles from '../styles';

import AutocompleteInput from './AutocompleteInput';

const CountryAutocompleteInput = ({ classes, className, countries, disabled, ...props }) => {
	const defaultCountry = localStorage.getItem('defaultCountry') || 'DE';

	return (
		<AutocompleteInput
			{...props}
			choices={countries || []}
			classes={{ container: className }}
			className={classes.fullWidth}
			optionValue="code"
			defaultValue={defaultCountry}
			options={{
				disabled: disabled
			}}
		/>
	);
};

const enhance = compose(withStyles(styles), withCountries);

export default enhance(CountryAutocompleteInput);
