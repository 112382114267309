import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';

const styles = {
	numbersOfColumns: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '160px',
		backgroundColor: 'white',
		borderRadius: '10px',
		fontSize: '50px',
		margin: '0 20px'
	}
};

const RowWithNumberOfColumns = ({ maxColumnsCount, classes }) => {
	return [...Array(maxColumnsCount).keys()].map(index => {
		return (
			<div key={index + 1} className={classes.numbersOfColumns}>
				<div>{index + 1}</div>
			</div>
		);
	});
};

const enhance = compose(withStyles(styles));

export default enhance(RowWithNumberOfColumns);
