export const ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN';
export const ROLE_AGENT = 'ROLE_AGENT';
export const ROLE_OFFICE_EMPLOYEE = 'ROLE_OFFICE_EMPLOYEE';
export const ROLE_COLLECTOR_GUY = 'ROLE_COLLECTOR_GUY';
export const ROLE_WAREHOUSE_MANAGER = 'ROLE_WAREHOUSE_MANAGER';
export const ROLE_MAIN_CUSTOMER = 'ROLE_MAIN_CUSTOMER';
export const ROLE_CUSTOMER = 'ROLE_CUSTOMER';
export const ROLE_USER = 'ROLE_USER';
export const ROLE_GUEST = 'ROLE_GUEST';

export default [
	ROLE_AGENT,
	ROLE_OFFICE_EMPLOYEE,
	ROLE_COLLECTOR_GUY,
	ROLE_WAREHOUSE_MANAGER,
	ROLE_MAIN_CUSTOMER,
	ROLE_CUSTOMER,
	ROLE_USER
];
