import { translate } from 'react-admin';
import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import PrintAllContainer from './PrintAllContainer';

const styles = {
	header: {
		display: 'flex',
		alignItems: 'center'
	},
	printAllBlock: {
		marginLeft: '50px'
	}
};

const Header = ({ classes, translate, name, record }) => {
	return (
		<div className={classes.header}>
			<div>
				{translate('resources.warehouses.data.structure')} {name && `(${name})`}
			</div>
			<div className={classes.printAllBlock}>
				<PrintAllContainer record={record} />
			</div>
		</div>
	);
};

const enhance = compose(translate, withStyles(styles));

export default enhance(Header);
