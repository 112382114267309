import { isArray } from 'lodash';
import { InputAdornment } from '@material-ui/core';
import React from 'react';
import { NumberInput, minValue } from 'react-admin';

const Measurement = ({
	record,
	source,
	className,
	resource,
	measure,
	label,
	placeholder,
	defaultValue,
	validate,
	integer,
	onChangeHandler,
	inputClasses = ''
}) => {
	if (!isArray(validate)) {
		validate = [validate];
	}

	return (
		<NumberInput
			source={source}
			record={record}
			onChange={(event, key) => onChangeHandler && onChangeHandler(key)}
			className={className}
			label={label}
			resource={resource}
			InputProps={{
				endAdornment: <InputAdornment position="end">{measure}</InputAdornment>,
				className: inputClasses,
				inputProps: {
					min: 0,
					step: integer ? 1 : 0.01
				}
			}}
			defaultValue={defaultValue}
			placeholder={placeholder}
			validate={[minValue(0), ...validate]}
		/>
	);
};

Measurement.defaultProps = {
	validate: []
};

export default Measurement;
