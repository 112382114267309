import { Delete as DeleteIcon } from '@material-ui/icons';
import React, { Component, Fragment } from 'react';
import { Button, Confirm, translate } from 'react-admin';

class DeleteButton extends Component {
	state = {
		isOpenDeleteConfirm: false
	};

	handleDeleteClick = () => {
		this.setState({
			isOpenDeleteConfirm: true
		});
	};

	handleDeleteDialogClose = () => {
		this.setState({
			isOpenDeleteConfirm: false
		});
	};

	render() {
		const { translate, onConfirm } = this.props;
		const { isOpenDeleteConfirm } = this.state;

		return (
			<Fragment>
				<Button label="ra.action.delete" color="secondary" onClick={this.handleDeleteClick}>
					<DeleteIcon />
				</Button>
				<Confirm
					isOpen={isOpenDeleteConfirm}
					title="ra.message.bulk_delete_title"
					content="ra.message.bulk_delete_content"
					translateOptions={{
						name: translate('comment.name', {
							smart_count: 1
						}),
						smart_count: 1
					}}
					onConfirm={onConfirm}
					onClose={this.handleDeleteDialogClose}
				/>
			</Fragment>
		);
	}
}

export default translate(DeleteButton);
