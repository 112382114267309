import { get } from 'lodash';
import { withStyles } from '@material-ui/core';
import { Delete as DeleteIcon, Print as PrintIcon } from '@material-ui/icons';
import React, { Component, Fragment } from 'react';
import { Button, Confirm, DELETE, translate, withDataProvider } from 'react-admin';
import ReactToPrint from 'react-to-print';
import QRCode from 'qrcode.react';
import { compose } from 'recompose';

import { BarcodeView } from '../components';
import { marginGutters } from '../helpers';

const styles = theme => ({
	gutters: {
		...marginGutters(theme),
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	noWrap: {
		display: 'flex',
		flexWrap: 'nowrap'
	},
	printedContainer: {
		width: 711,
		minWidth: 711
	},
	qrCodeContainer: {
		boxSizing: 'border-box',
		paddingTop: 39.44
	}
});

class UnconnectedBarcodeActions extends Component {
	_isMounted = false;

	state = {
		isOpenDeleteDialog: false
	};

	componentDidMount() {
		this._isMounted = true;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleConfirmDeleteDialog = async () => {
		const { record, source, dataProvider, onDelete } = this.props;
		const value = get(record, source);

		const { data: barcode } = await dataProvider(DELETE, 'barcodes', {
			id: value
		});

		onDelete(barcode);
		this.handleDialogClose();
	};

	handleDeleteClick = () => {
		if (this._isMounted) {
			this.setState({
				isOpenDeleteDialog: true
			});
		}
	};

	handleDialogClose = () => {
		if (this._isMounted) {
			this.setState({
				isOpenDeleteDialog: false
			});
		}
	};

	render() {
		const { classes, deletable, prefix, project, record, source, translate } = this.props;
		const { isOpenDeleteDialog } = this.state;
		const value = get(record, source);

		return (
			<div className={classes.noWrap}>
				<ReactToPrint
					content={() => this.printedBarcode}
					trigger={() => (
						<Button label="ra.action.print">
							<PrintIcon />
						</Button>
					)}
				/>
				<div style={{ display: 'none' }}>
					<div ref={this.setPrintedBarcode} className={classes.gutters}>
						<div className={classes.printedContainer}>
							<div className={classes.qrCodeContainer}>
								<BarcodeView project={project} record={record} size={170.14}>
									<QRCode value={prefix + value} level="H" />
								</BarcodeView>
							</div>
						</div>
					</div>
				</div>
				{deletable && (
					<Fragment>
						<Button label="ra.action.delete" onClick={this.handleDeleteClick} color="secondary">
							<DeleteIcon />
						</Button>

						<Confirm
							isOpen={isOpenDeleteDialog}
							title="ra.message.delete_title"
							content="ra.message.delete_content"
							translateOptions={{
								name: translate('resources.barcodes.name', {
									smart_count: 1
								}),
								id: record.id
							}}
							onConfirm={this.handleConfirmDeleteDialog}
							onClose={this.handleDialogClose}
						/>
					</Fragment>
				)}
			</div>
		);
	}

	setPrintedBarcode = printedBarcode => {
		this.printedBarcode = printedBarcode;
	};
}

const enhance = compose(withStyles(styles), withDataProvider, translate);

const BarcodeActions = enhance(UnconnectedBarcodeActions);

BarcodeActions.defaultProps = {
	label: '',
	deletable: true
};

export default BarcodeActions;
