import { fetchUtils } from 'react-admin';

const abortControllers = {};

const httpClient = (url, options = {}) => {
	const accessToken = localStorage.getItem('access_token');
	let tokenType = localStorage.getItem('token_type');

	if (tokenType) {
		tokenType = tokenType.charAt(0).toUpperCase() + tokenType.substr(1);
	}

	if (accessToken && tokenType) {
		options.user = {
			authenticated: true,
			token: `${tokenType} ${accessToken}`
		};
	}

	if (options.method !== 'POST') {
		const abortKey = [url, options.method].filter(Boolean).join('||||');
		if (!abortControllers[abortKey]) {
			abortControllers[abortKey] = new AbortController();
			options.signal = abortControllers[abortKey].signal;
		} else {
			abortControllers[abortKey].abort();
		}
	}

	return fetchUtils.fetchJson(url, options).catch(async error => {
		if ('AbortError' === error.name) {
			return Promise.resolve();
		}

		return Promise.reject(error);
	});
};

export default httpClient;
