import { useContext } from 'react';
import Context from './Context';

const useMovingMode = () => {
	const context = useContext(Context);

	if (!context) {
		throw new Error('useMovingMode must be used within an MovingModeProvider');
	}

	return context;
};

export default useMovingMode;
