import React, { Fragment, useState } from 'react';
import { IN_CART, IN_WAREHOUSE } from './statuses';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import ExpandRowButton from 'ra-ui-materialui/lib/list/ExpandRowButton';
import Checkbox from '@material-ui/core/es/Checkbox/Checkbox';
import { FunctionField, NumberInput, SimpleForm, setListSelectedIds } from 'react-admin';
import ExpandArticles from './ExpandArticles';
import { compose } from 'recompose';
import { connect } from 'react-redux';

const ArticleDatagridRow = ({
	record,
	resource,
	id,
	classes,
	onToggleItem,
	children,
	basePath,
	customActions,
	crudUpdateMany,
	selectedIds,
	...props
}) => {
	const computeColSpan = props => {
		const { children, hasBulkActions } = props;
		return (
			1 + // show expand button
			(hasBulkActions ? 1 : 0) + // checkbox column
			React.Children.toArray(children).filter(child => !!child).length // non-null children
		);
	};

	const [expanded, setExpanded] = useState(false);
	const [colSpan] = useState(computeColSpan({ children, customActions }));
	const label = basePath === '/articles' ? 'add_to_cart' : 'remove_from_cart';
	const expand = record?.serial_number_info?.count > 1;

	const handleToggleExpanded = event => {
		setExpanded(!expanded);
		event.stopPropagation();
	};
	const handleToggle = event => {
		if (record.serial_number_info && record.serial_number_info.count > 1) {
			record.serial_number_info.id.forEach(currentId => onToggleItem(currentId));
		} else {
			onToggleItem(id);
		}
		event.stopPropagation();
	};
	const handleSubmit = event => {
		event.stopPropagation();
		event.preventDefault();
		if (selectedIds.length) {
			const status = label === 'add_to_cart' ? { status: IN_CART } : { status: IN_WAREHOUSE };
			crudUpdateMany('articles', selectedIds, status, basePath);
		}
	};
	const handleAddToCartAndOrderMany = (event, ids) => {
		event.target.value =
			event.target.value > ids.length || event.target.value < 0 ? ids.length : event.target.value;
		selectedIds = ids.slice(0, event.target.value);
		props.setListSelectedIds('articles', ids.slice(0, event.target.value));
	};

	const checkIds = value => {
		return value.includes(record.id);
	};

	return (
		<Fragment>
			<TableRow key={id}>
				{!props.outsourcingPage && !props.deliveryPage && (
					<TableCell padding="none">
						<Checkbox
							color="primary"
							className={`select-item ${classes.checkbox}`}
							checked={checkIds(selectedIds)}
							onClick={handleToggle}
						/>
					</TableCell>
				)}
				{expand ? (
					<TableCell padding="none" className={classes.expandIconCell}>
						<ExpandRowButton
							classes={{
								expandIcon: classes.expandIcon,
								expandHeader: classes.expandHeader,
								expandIconCell: classes.expandIconCell,
								expanded: classes.expanded
							}}
							expanded={expanded}
							expandContentId={`${id}-expand`}
							onClick={handleToggleExpanded}
						/>
					</TableCell>
				) : !props.hideExpandCell ? (
					<TableCell source="empty" />
				) : (
					undefined
				)}
				{/* data columns based on children */}
				{React.Children.map(children, field => {
					if (expand) {
						const projectId = record.barcodes[0].project_id;
						return (
							field &&
							field.props.source !== 'empty' && (
								<TableCell key={`${id}-${field?.props?.source}`}>
									{React.cloneElement(field, {
										record: {
											...record,
											name: '',
											barcodes: [],
											'storagearea.full_name': '',
											'barcodes[0].project_id': projectId,
											damages: [],
											id: ''
										},
										basePath,
										resource
									})}
								</TableCell>
							)
						);
					}
					return (
						field &&
						field.props.source !== 'empty' && (
							<TableCell key={`${id}-${field?.props?.source}`}>
								{React.cloneElement(field, {
									record,
									basePath,
									resource
								})}
							</TableCell>
						)
					);
				})}
				{customActions && (
					<TableCell padding="none">
						<FunctionField
							render={() =>
								expand ? (
									<SimpleForm
										toolbar={<Fragment />}
										id={`order${record.id}`}
										onSubmit={e => handleSubmit(e)}
									>
										<NumberInput
											label={`Order`}
											source={`order${record.id}`}
											key={`order${record.id}`}
											onInput={e => handleAddToCartAndOrderMany(e, record.serial_number_info?.id)}
											className={classes.NumberInput}
										/>
									</SimpleForm>
								) : (
									''
								)
							}
							label={label}
							source={label}
						></FunctionField>
					</TableCell>
				)}
				<TableCell padding="none">
					<FunctionField
						render={() =>
							expand ? (
								<SimpleForm
									toolbar={<Fragment />}
									id={`${label}${record.id}`}
									onSubmit={e => handleSubmit(e)}
								>
									<NumberInput
										label={`${label === 'add_to_cart' ? 'Add to cart' : 'Remove'}`}
										source={`${label}${record.id}`}
										key={`${label}${record.id}`}
										onInput={e => handleAddToCartAndOrderMany(e, record.serial_number_info?.id)}
										className={classes.NumberInput}
									/>
								</SimpleForm>
							) : (
								''
							)
						}
						label={label}
						source={label}
					></FunctionField>
				</TableCell>
			</TableRow>

			{expand && expanded && (
				<TableRow key={`${id}-expand`} id={`${id}-expand`}>
					<TableCell colSpan={colSpan}>
						<ExpandArticles
							record={record}
							resource={resource}
							classes={classes}
							basePath={basePath}
							customActions={customActions}
							onToggleItem={onToggleItem}
							selectedIds={selectedIds}
							{...props}
						/>
					</TableCell>
				</TableRow>
			)}
		</Fragment>
	);
};

const enhance = compose(connect(undefined, { setListSelectedIds }));

export default enhance(ArticleDatagridRow);
