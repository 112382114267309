import { withStyles } from '@material-ui/core';
import React from 'react';
import { SimpleShowLayout } from 'react-admin';

import {
	AddressField,
	FullNameField,
	MobileGrid as BaseMobileGrid,
	UpdatedAtField
} from '../components';
import { marginGutters } from '../helpers';

const TitleMobileGrid = ({ record }) => (
	<span>
		#{record.id}. <FullNameField record={record} />
	</span>
);

const styles = theme => ({
	field: {
		'&.ra-field': {
			...marginGutters(theme),
			flex: '1 0 1px',
			minWidth: 240
		}
	}
});

// eslint-disable-next-line no-unused-vars
const UnconnectedBodyMobileGrid = ({ record, classes, className, isEmbedComments, ...props }) => (
	<SimpleShowLayout className={`${className} ${classes.root}`} record={record} {...props}>
		<AddressField showName={false} />
		<UpdatedAtField />
	</SimpleShowLayout>
);

export const BodyMobileGrid = withStyles(styles)(UnconnectedBodyMobileGrid);

BodyMobileGrid.defaultProps = {
	isEmbedComments: false
};

const MobileGrid = props => (
	<BaseMobileGrid {...props} title={<TitleMobileGrid />} body={<BodyMobileGrid />} />
);

export default MobileGrid;
