import { CircularProgress, withStyles } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import React, { Component, Fragment } from 'react';
import { Button, TextInput, CREATE, withDataProvider } from 'react-admin';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { Dialog } from '../components';

const styles = {
	fullWidth: {
		width: '100%',
		marginTop: 0,
		marginBottom: 0
	}
};

class AddOtherCategoryButton extends Component {
	state = {
		isOpenDialog: false,
		categoryName: ''
	};

	handleAddCategoryClick = () => {
		this.setState({
			isOpenDialog: true
		});
	};

	handleChangeName = (event, categoryName) => {
		this.setState({
			categoryName
		});
	};

	handleConfirmDialog = async () => {
		const { dataProvider, onAdd } = this.props;

		const { data: category } = await dataProvider(
			CREATE,
			'article-categories',
			{
				data: {
					name: this.state.categoryName
				}
			},
			{
				onSuccess: {
					notification: {
						body: 'resources.articles.data.created_category',
						level: 'info'
					}
				}
			}
		);

		onAdd(category);

		this.handleDialogClose();
	};

	handleDialogClose = () => {
		this.setState({
			isOpenDialog: false
		});
	};

	// eslint-disable-next-line no-unused-vars
	componentDidUpdate(prevProps, prevState, snapshot) {
		const { categoryName } = this.props;

		if (categoryName !== prevProps.categoryName) {
			this.setState({
				categoryName: categoryName || ''
			});
		}
	}

	render() {
		const { classes, isLoading } = this.props;
		const { isOpenDialog, categoryName } = this.state;

		return (
			<Fragment>
				<Button
					label="resources.articles.action.add_other_category"
					onClick={this.handleAddCategoryClick}
				>
					<AddIcon />
				</Button>
				<Dialog
					isOpen={isOpenDialog}
					title="resources.articles.data.add_category"
					customButtons={
						<Button
							label="ra.action.add"
							disabled={isLoading || !categoryName}
							onClick={this.handleConfirmDialog}
						>
							{isLoading ? <CircularProgress size={20} thickness={2} /> : <AddIcon />}
						</Button>
					}
					onClose={this.handleDialogClose}
				>
					<TextInput
						source="category_name"
						defaultValue={categoryName}
						onChange={this.handleChangeName}
						className={classes.fullWidth}
					/>
				</Dialog>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });

const enhance = compose(withStyles(styles), withDataProvider, connect(mapStateToProps, null));

export default enhance(AddOtherCategoryButton);
