import { isEmpty } from 'lodash';

export default ({ address, user = {}, showName = true }) => {
	const firstname = (!isEmpty(user) ? user.firstname : address.firstname) || '';
	const surname = (!isEmpty(user) ? user.surname : address.surname) || '';

	let name = '';
	if (showName) {
		name = address.company || '';
	} else if (firstname || surname) {
		name = ((firstname && '') + ' ' + (surname && '')).trim() || '';
	}

	return [
		name && name + ',',
		// address.country_code && translate ? translate('countries.' + address.country_code) + ',' : '',
		address.street || '',
		address.house_number + ',' || '',
		address.postal_code || '',
		address.city || ''
	]
		.filter(Boolean)
		.filter(String)
		.join(' ');
};
