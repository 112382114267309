import { get } from 'lodash';
import { Button, withStyles } from '@material-ui/core';
import { Print as PrintIcon } from '@material-ui/icons';
import QRCode from 'qrcode.react';
import React, { Component, Fragment, cloneElement } from 'react';
import { translate } from 'react-admin';
import ReactToPrint from 'react-to-print';
import { compose } from 'recompose';

import Dialog from './Dialog';

const styles = {
	grcode: {
		display: 'block'
	},
	minWidthQRCode: {
		boxSizing: 'border-box',
		minWidth: 248
	},
	iconPaddingStyle: {
		paddingRight: '0.5em'
	},
	printedBarcode: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	printedContainer: {
		width: 711,
		minWidth: 711
	},
	qrCodeContainer: {
		boxSizing: 'border-box',
		paddingTop: 39.44
	},
	container: {
		width: 248,
		height: 248
	},
	QRimage: {
		padding: '8px 16px'
	}
};

const QRCodeWithDescription = ({ prefix, value, className, description, record, size }) => (
	<Fragment>
		<QRCode value={prefix + value} className={className} size={size} level="H" />
		{description &&
			cloneElement(description, {
				record,
				style: {
					marginBottom: (-62 / 248) * size,
					width: size
				}
			})}
	</Fragment>
);

class UnconnectedQRCodeField extends Component {
	state = {
		isShow: false
	};

	handleQRCodeClick = () => {
		this.setState({
			isShow: true
		});
	};

	handleDialogClose = event => {
		event.stopPropagation();
		this.setState({
			isShow: false
		});
	};

	setPrintedBarcode = printedBarcode => {
		this.printedBarcode = printedBarcode;
	};

	// eslint-disable-next-line no-unused-vars
	shouldComponentUpdate(nextProps, nextState, nextContext) {
		const value = get(this.props.record, this.props.source);
		const nextValue = get(nextProps.record, nextProps.source);

		return (
			this.props.prefix + value !== nextProps.prefix + nextValue ||
			this.props.description !== nextProps.description ||
			this.state.isShow !== nextState.isShow
		);
	}

	render() {
		const {
			classes,
			description,
			prefix,
			record,
			source,
			translate,
			view,
			openOnClick
		} = this.props;
		const { isShow } = this.state;
		const value = get(record, source);

		const qrCodeProps = { prefix, value, description, record };
		const qrCodeWithDescription = (
			<QRCodeWithDescription {...qrCodeProps} className={classes.grcode} />
		);
		const qrCode = view
			? cloneElement(view, {
					record: record,
					children: qrCodeWithDescription
			  })
			: cloneElement(qrCodeWithDescription, {
					size: 248
			  });
		const hiddenQRCode = cloneElement(qrCode, {
			size: 170.14
		});

		return (
			<Fragment>
				{openOnClick ? (
					<Button onClick={this.handleQRCodeClick}>
						<img src="/qrCode.png" alt="qrCode" />
					</Button>
				) : (
					<img className={classes.QRimage} src="/qrCode.png" alt="qrCode" />
				)}
				<Dialog
					isOpen={isShow}
					onClose={this.handleDialogClose}
					classes={{
						contentText: classes.minWidthQRCode
					}}
					customButtons={
						<ReactToPrint
							content={() => this.printedBarcode}
							trigger={() => (
								<Button>
									<PrintIcon className={classes.iconPaddingStyle} />
									{translate('ra.action.print')}
								</Button>
							)}
						/>
					}
				>
					<div className={classes.container}>
						{isShow && (
							<Fragment>
								{qrCode}

								<div style={{ display: 'none' }}>
									<div ref={this.setPrintedBarcode} className={classes.printedBarcode}>
										<div className={classes.printedContainer}>
											<div className={classes.qrCodeContainer}>{hiddenQRCode}</div>
										</div>
									</div>
								</div>
							</Fragment>
						)}
					</div>
				</Dialog>
			</Fragment>
		);
	}
}

const enhance = compose(withStyles(styles), translate);

const QRCodeField = enhance(UnconnectedQRCodeField);

QRCodeField.defaultProps = {
	label: 'QR-Code',
	addLabel: true,
	prefix: '',
	openOnClick: true
};

export default QRCodeField;
