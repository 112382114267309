import React from 'react';

import SectionNodeWrapper from './SectionNodeWrapper';

const SectionTree = ({
	children,
	className,
	onChange,
	onDeleteSection,
	path,
	disabled,
	onExpand,
	visible,
	openSection,
	formSection,
	parent,
	isEdit
}) => (
	<div className={`${className}`}>
		{children.map((section, sectionIndex) => (
			<SectionNodeWrapper
				key={section.id}
				formSection={formSection}
				section={section}
				sectionIndex={sectionIndex}
				onDelete={onDeleteSection}
				onExpand={onExpand}
				onChange={onChange}
				path={path}
				disabled={disabled}
				visible={visible}
				openSection={openSection}
				isMovable={parent.movable}
				isEdit={isEdit}
			/>
		))}
	</div>
);

export default SectionTree;
