import {
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Switch,
	Typography,
	withStyles
} from '@material-ui/core';
import { Cancel as CancelIcon, Save as SaveIcon } from '@material-ui/icons';
import React, { cloneElement, useEffect, useMemo, useState } from 'react';
import { Button, SimpleForm, translate, UPDATE, withDataProvider } from 'react-admin';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { isSubmitting, reset, submit } from 'redux-form';

import EmptySpan from './EmptySpan';
import LightboxField from './LightboxField';
import FieldsAddNote from '../warehouses/FieldsAddNote';
import { formUniqueKey } from '../helpers';
import { findStorageArea } from '../warehouses/helpers';

const styles = theme => ({
	dialog: {
		zIndex: 2
	},
	content: {
		padding: 0
	},
	form: {
		marginTop: -theme.spacing.unit * 2
	},
	actions: {
		paddingLeft: 12,
		paddingRight: 12
	},
	switch: {
		marginLeft: '10px'
	},
	viewingContent: {
		marginLeft: '0'
	},
	note: {
		marginBottom: '10px'
	}
});

const UnconnectedNoteForm = ({
	reset,
	submit,
	classes,
	isLoading,
	resource,
	size,
	title,
	translate,
	customSaveButton,
	disableMargin,
	dataProvider,
	updateSections,
	setSectionIdForNotes,
	sectionIdForNotes,
	sections,
	showDialog
}) => {
	const [isEditMode, setIsEditMode] = useState(false);
	const [note, setNote] = useState('');
	const [photos, setPhotos] = useState([]);

	const currentSection = useMemo(() => {
		if (!sectionIdForNotes) return;
		return findStorageArea(sections, sectionIdForNotes);
	}, [sectionIdForNotes, sections]);

	const [form] = useState(`note-form-${formUniqueKey(currentSection)}`);

	useEffect(() => {
		setNote(currentSection?.storage_note ?? '');
		setPhotos(currentSection?.storage_photos?.slice() ?? []);
	}, [currentSection]);

	const handleCloseClick = event => {
		if (event && event.stopPropagation) {
			event.stopPropagation();
		}
		setSectionIdForNotes(null);
		setIsEditMode(false);

		reset(form);
	};

	const handleChangePhotos = (event, photos) => {
		setPhotos(photos);
	};

	const handleChangeNote = event => {
		setNote(event.target.value);
	};

	const handleSaveClick = event => {
		event.stopPropagation();

		submit(form);
	};

	const handleSubmit = async () => {
		const { data: updatedSection } = await dataProvider(UPDATE, 'storageareas', {
			id: currentSection?.id,
			data: {
				...currentSection,
				storage_note: note,
				storage_photos: photos
			}
		});

		if (!updatedSection) return;

		// eslint-disable-next-line require-atomic-updates
		currentSection.storage_note = note;
		// eslint-disable-next-line require-atomic-updates
		currentSection.storage_photos = photos;

		if (updateSections) updateSections();

		handleCloseClick();
	};

	return (
		<Dialog
			fullWidth
			maxWidth={size}
			open={showDialog}
			onClose={handleCloseClick}
			className={classes.dialog}
			onClick={event => {
				event.stopPropagation();
			}}
		>
			<DialogTitle>{translate(title, { name: currentSection?.name })}</DialogTitle>
			<FormControlLabel
				control={
					<Switch
						checked={isEditMode}
						color="primary"
						onChange={() => setIsEditMode(!isEditMode)}
					/>
				}
				className={classes.switch}
				label={translate('resources.warehouses.action.edit_mode')}
			/>
			{isEditMode ? (
				<>
					<DialogContent className={classes.content}>
						<SimpleForm
							form={form}
							onSubmit={handleSubmit}
							toolbar={<EmptySpan />}
							className={!disableMargin ? classes.form : ''}
							resource={resource}
							record={currentSection}
						>
							<FieldsAddNote
								handleChangePhotos={handleChangePhotos}
								handleChangeNote={handleChangeNote}
								note={note}
							/>
						</SimpleForm>
					</DialogContent>
					<DialogActions className={classes.actions}>
						<Button label="ra.action.cancel" onClick={handleCloseClick}>
							<CancelIcon />
						</Button>
						{customSaveButton ? (
							cloneElement(customSaveButton, {
								onClick: handleSaveClick,
								disabled: isLoading,
								children: isLoading ? (
									<CircularProgress size={20} thickness={2} />
								) : (
									customSaveButton.props.children
								)
							})
						) : (
							<Button label="ra.action.save" onClick={handleSaveClick} disabled={isLoading}>
								{isLoading ? <CircularProgress size={20} thickness={2} /> : <SaveIcon />}
							</Button>
						)}
					</DialogActions>
				</>
			) : (
				<>
					<DialogContent className={classes.viewingContent}>
						<Typography variant="body1" className={classes.note}>
							{note}
						</Typography>
						<LightboxField record={currentSection} source="storage_photos" photosType={'array'} />
					</DialogContent>
					<DialogActions className={classes.actions}>
						<Button label="ra.action.cancel" onClick={handleCloseClick}>
							<CancelIcon />
						</Button>
					</DialogActions>
				</>
			)}
		</Dialog>
	);
};

const mapStateToProps = (state, { form }) => ({
	isSubmitting: isSubmitting(form)(state),
	isLoading: state.admin.loading > 0
});

const enhance = compose(
	connect(mapStateToProps, {
		submit,
		reset
	}),
	withStyles(styles),
	translate,
	withDataProvider
);

const NoteForm = enhance(UnconnectedNoteForm);

NoteForm.defaultProps = {
	saveButton: 'add',
	form: 'form',
	size: 'md'
};

export default NoteForm;
