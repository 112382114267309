import memoize from './memoize';

const isEmpty = function(value) {
	return (
		typeof value === 'undefined' ||
		value === null ||
		(Array.isArray(value) && value.length === 0) ||
		value === ''
	);
};

export default memoize((resource, field, message = 'ra.validation.required') =>
	Object.assign(
		(value, allValues, { translate }) => {
			if (isEmpty(value)) {
				const translateField = translate(`resources.${resource}.fields.${field}`);

				return translate(message, { field: translateField });
			}

			return undefined;
		},
		{ isRequired: true }
	)
);
