import { isEmpty } from 'lodash';
import { Button, Card, CardActions, CardContent, withStyles } from '@material-ui/core';
import RichTextInput from 'ra-input-rich-text';
import React, { Component } from 'react';
import { translate } from 'react-admin';
import { compose } from 'recompose';

const styles = theme => ({
	cardContent: {
		paddingBottom: 0,
		'& .ql-picker-label': {
			color: theme.palette.primary.special,
			'&:hover': {
				color: `${theme.palette.primary.special} !important`
			}
		},
		'& line': {
			stroke: `${theme.palette.primary.special} !important`
		},
		'& path': {
			stroke: `${theme.palette.primary.special} !important`
		},
		'& polygon': {
			stroke: `${theme.palette.primary.special} !important`
		},
		'& rect': {
			fill: `${theme.palette.primary.special} !important`
		}
	},
	cardActions: {
		justifyContent: 'flex-end',
		paddingLeft: 20,
		paddingRight: 20
	}
});

class CommentInput extends Component {
	state = {
		isValid: false
	};

	value = '';

	handleChangeComment = value => {
		this.value = value;

		this.validate();
	};

	handleSendComment = () => {
		const { onSend } = this.props;

		if (!isEmpty(this.value)) {
			onSend(this.value);

			this.value = '';

			this.validate();
		}
	};

	render() {
		const { classes, className, translate } = this.props;
		const { isValid } = this.state;

		return (
			<Card className={className}>
				<CardContent className={classes.cardContent}>
					<RichTextInput
						source="body"
						meta={{ error: null }}
						input={{
							value: this.value,
							onChange: this.handleChangeComment
						}}
					/>
				</CardContent>
				<CardActions className={classes.cardActions}>
					<Button
						color="primary"
						variant="contained"
						disabled={!isValid}
						onClick={this.handleSendComment}
					>
						{translate('comment.action.send')}
					</Button>
				</CardActions>
			</Card>
		);
	}

	validate() {
		const { isValid } = this.state;

		if (!isEmpty(this.value)) {
			if (!isValid) {
				this.setState({
					isValid: true
				});
			}
		} else {
			if (isValid) {
				this.setState({
					isValid: false
				});
			}
		}
	}
}

const enhance = compose(withStyles(styles), translate);

export default enhance(CommentInput);
