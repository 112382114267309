import { Card, CardActions, CardContent, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { GET_LIST, withDataProvider, GET_ONE } from 'react-admin';
import { connect } from 'react-redux';

import { compose } from 'recompose';
import DeliveryList from './DeliveryList';
import { QrReaderButton } from '../components';
import { OUTSOURCED } from '../articles/statuses';
import { hasAll } from '../helpers';
import DoneDialog from '../components/DoneDialog';
import { CONFIRMED } from '../deliveries/statuses';

const styles = {
	actions: {
		justifyContent: 'flex-end',
		paddingLeft: 20,
		paddingRight: 20
	},
	content: {
		paddingLeft: 0,
		paddingRight: 0
	}
};

class ScanDeliveryBarcodes extends Component {
	_isMounted = false;
	state = {
		scannedBarcodes: [],
		showDoneDialog: false,
		projectId: null,
		storageArea: null,
		projectAreas: [],
		projectMeasurements: null,
		article: null,
		delivery: null,
		filledInStorageArea: null,
		filledInCurrentProject: null,
		filledInOtherProjects: null,
		totalArea: null,
		deliveries: [],
		justScannedDeliveries: [],
		perPage: 10,
		page: 1
	};

	addScannedBarcode = async barcodeId => {
		const article = await this.fetchArticleByBarcode(barcodeId);
		if (article !== null) {
			const delivery = await this.fetchDeliveryByArticle(article.id);
			if (delivery !== null) {
				const articles = await this.fetchArticlesToDelivery(delivery);
				this.setJustScannedDeliveries(delivery, articles);
				if (this._isMounted) {
					this.setState({
						article,
						delivery
					});
					const { scannedBarcodes } = this.state;
					if (!scannedBarcodes.includes(barcodeId) && article.status !== OUTSOURCED) {
						this.setScannedBarcodes([...scannedBarcodes, barcodeId]);
					}
				}
			}
		}
	};

	setScannedBarcodes = barcodes => {
		this.setState({
			scannedBarcodes: barcodes
		});
	};

	fetchArticleByBarcode = async barcodeId => {
		const { dataProvider } = this.props;

		const { data: articles } = await dataProvider(GET_LIST, 'articles', {
			queryparams: {
				fromAll: 1
			},
			filter: {
				'barcodes.id': barcodeId
			}
		});

		if (articles.length > 0) {
			return articles[0];
		}

		return null;
	};

	fetchDeliveryByArticle = async articleId => {
		const { dataProvider } = this.props;

		const { data: deliveries } = await dataProvider(GET_LIST, 'deliveries', {
			filter: {
				'articles.id': articleId
			}
		});

		if (deliveries.length > 0) {
			return deliveries[0];
		}

		return null;
	};

	fetchArticlesToDelivery = async delivery => {
		const { dataProvider } = this.props;

		const { data: articles } = await dataProvider(GET_LIST, 'articles', {
			filter: {
				delivery: delivery.id
			}
		});
		if (!articles) return;

		this.setState({
			deliveries: this.state.deliveries.map(stateDelivery => {
				if (stateDelivery.id !== delivery.id) return stateDelivery;

				return {
					...stateDelivery,
					articles
				};
			})
		});
		return articles;
	};

	setJustScannedDeliveries = (delivery, articles) => {
		this.setState({
			justScannedDeliveries: !this.state.justScannedDeliveries.some(obj => obj.id === delivery.id)
				? [...this.state.justScannedDeliveries, { ...delivery, articles }]
				: this.state.justScannedDeliveries
		});
	};

	updateDeliveries = async (page, perPage) => {
		const { dataProvider } = this.props;

		const { data: deliveries, total } = await dataProvider(GET_LIST, 'deliveries', {
			filter: {
				'status][eq': CONFIRMED
			},
			sort: {
				field: 'date',
				order: 'ASC'
			},
			pagination: {
				page,
				perPage
			}
		});

		this.setState({ deliveries, perPage, page, total });
	};

	fetchProjectByStoragearea = async (storageArea, projectId) => {
		const { dataProvider } = this.props;

		const [
			{ data: projectAreas },
			{
				data: { measurements: projectMeasurements }
			}
		] = await Promise.all([
			dataProvider(GET_LIST, 'projectareas', {
				filter: {
					project: projectId,
					storageArea
				}
			}),
			dataProvider(GET_ONE, 'projects', {
				id: projectId
			})
		]);

		return {
			projectAreas,
			projectMeasurements
		};
	};

	componentDidMount = async () => {
		this._isMounted = true;
	};

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleConfirmBarcode = async value => {
		const search = value.split('/');

		if (search.length) {
			const barcodeId = Number(search[search.length - 1]);

			await this.addScannedBarcode(barcodeId);

			const { article, delivery } = this.state;

			if (!article || !delivery || !article.storagearea) return;

			const { project, storagearea } = article;
			const article_storageareas = new Map();

			if (delivery.articles) {
				delivery.articles.forEach(article => {
					const articleStorageArea = article.storagearea?.id;
					if (!articleStorageArea || article.status === OUTSOURCED) return;

					article.barcodes.forEach(barcode => {
						if (article_storageareas.has(articleStorageArea)) {
							article_storageareas.set(
								articleStorageArea,
								article_storageareas.get(articleStorageArea).concat(barcode.id)
							);
						} else {
							article_storageareas.set(articleStorageArea, [barcode.id]);
						}
					});
				});

				if (hasAll(article_storageareas.get(storagearea.id), this.state.scannedBarcodes)) {
					const projectInfo = await this.fetchProjectByStoragearea(storagearea.id, project);
					if (!projectInfo) return;

					const { total_area } = storagearea;
					const { projectAreas } = projectInfo;

					const filledInCurrentProject =
						projectAreas && projectAreas.length > 0 ? projectAreas[0].area : 0;
					const filledInStorageArea =
						storagearea.project_areas?.reduce((acc, pa) => acc + pa.area, 0) ?? 0;

					this.setState({
						projectId: project,
						storageArea: storagearea.id,
						showDoneDialog: true,
						filledInStorageArea,
						filledInCurrentProject,
						filledInOtherProjects: filledInStorageArea - filledInCurrentProject,
						totalArea: total_area,
						...projectInfo
					});
				}
			}
		}
	};

	handleCloseDoneDialog = () => {
		this.setState({
			showDoneDialog: false
		});
	};

	render() {
		const { classes, location, permissions } = this.props;
		const {
			scannedBarcodes,
			showDoneDialog,
			projectId,
			storageArea,
			projectAreas,
			projectMeasurements,
			filledInOtherProjects,
			filledInStorageArea,
			totalArea,
			filledInCurrentProject
		} = this.state;

		return (
			<Card>
				<DoneDialog
					projectId={projectId}
					storageArea={storageArea}
					projectAreas={projectAreas}
					projectMeasurements={projectMeasurements}
					onClose={this.handleCloseDoneDialog}
					showDialog={showDoneDialog}
					filledInOtherProjects={filledInOtherProjects}
					filledInStorageArea={filledInStorageArea}
					filledInCurrentProject={filledInCurrentProject}
					totalArea={totalArea}
				/>
				<CardActions className={classes.actions}>
					<QrReaderButton onConfirm={this.handleConfirmBarcode} />
				</CardActions>
				<CardContent className={classes.content}>
					<DeliveryList
						setScannedBarcodes={this.setScannedBarcodes}
						scannedBarcodes={scannedBarcodes}
						fetchArticleByBarcode={this.fetchArticleByBarcode}
						fetchArticlesToDelivery={this.fetchArticlesToDelivery}
						fetchDeliveryByArticle={this.fetchDeliveryByArticle}
						updateDeliveries={this.updateDeliveries}
						location={location}
						deliveries={this.state.deliveries}
						justScannedDeliveries={this.state.justScannedDeliveries}
						perPage={this.state.perPage}
						page={this.state.page}
						total={this.state.total}
						permissions={permissions}
					/>
				</CardContent>
			</Card>
		);
	}
}

const mapStateToProps = state => ({
	isLoading: state.admin.loading > 0
});

const enhance = compose(withStyles(styles), withDataProvider, connect(mapStateToProps));

export default enhance(ScanDeliveryBarcodes);
