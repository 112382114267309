import { withStyles } from '@material-ui/core';
import React from 'react';
import { addField, SelectInput } from 'react-admin';

import { QrReaderButton } from '../components';

import WarehouseField from './WarehouseField';
import { compose } from 'recompose';

const styles = theme => ({
	warehousesInput: {
		flex: '1 0 1px',
		'& .changed-value': {
			paddingLeft: '0!important'
		}
	},
	qr_reader: {
		marginLeft: '0.5em',
		marginTop: theme.spacing.unit * 2,
		marginBottom: theme.spacing.unit
	},
	qr_reader_error: {
		marginBottom: 28
	}
});

const UnconnectedWarehouseSelectInput = ({
	classes,
	className,
	record,
	resource,
	source,
	disabled,
	onConfirmQrCode,
	warehouses,
	defaultValue,
	isRequired,
	input,
	meta
}) => (
	<div className={className}>
		<SelectInput
			record={record}
			resource={resource}
			source={source}
			label="resources.articles.fields.warehouse_place"
			choices={warehouses}
			className={classes.warehousesInput}
			optionText={<WarehouseField />}
			defaultValue={defaultValue}
			disabled={disabled}
			input={input}
			meta={meta}
			isRequired={isRequired}
		/>
		{onConfirmQrCode && (
			<QrReaderButton
				onConfirm={onConfirmQrCode}
				className={[
					classes.qr_reader,
					meta.touched && meta.error ? classes.qr_reader_error : undefined
				]
					.filter(Boolean)
					.join(' ')}
			/>
		)}
	</div>
);
const enhance = compose(addField, withStyles(styles));

const WarehouseSelectInput = enhance(UnconnectedWarehouseSelectInput);

WarehouseSelectInput.defaultProps = {
	source: 'storagearea.id'
};

export default WarehouseSelectInput;
