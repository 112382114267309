import React from 'react';
import { Create } from 'react-admin';

import AgentForm from './AgentForm';

const AgentCreate = ({ permissions, ...props }) => (
	<Create {...props}>
		<AgentForm permissions={permissions} />
	</Create>
);

export default AgentCreate;
