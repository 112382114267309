import React from 'react';
import { SelectInput, translate } from 'react-admin';

import filterAddressHelpers from '../helpers/filterAddressHelpers';

const CustomSelectProjectInput = props => {
	const { choices } = props;
	const projects = choices
		.filter(({ customer: { address, user } }) => address && user)
		.map(({ id, customer: { address, user } }) => ({
			id: id,
			name: filterAddressHelpers({
				address,
				user,
				idProject: id
			})
		}));

	return (
		<SelectInput choices={projects} source="project" label="resources.articles.fields.project" />
	);
};

export default translate(CustomSelectProjectInput);
