import { isEqual } from 'lodash';
import React, { Component } from 'react';

import NodeForPrint from './NodeForPrint';

class TreeForPrint extends Component {
	// eslint-disable-next-line no-unused-vars
	shouldComponentUpdate(nextProps, nextState, nextContext) {
		return (
			!isEqual(this.props.children, nextProps.children) ||
			this.props.path !== nextProps.path ||
			this.props.disabled !== nextProps.disabled
		);
	}

	render() {
		const { children, path, disabled } = this.props;

		return children.map(section => (
			<NodeForPrint key={section.uniqueKey} section={section} path={path} disabled={disabled} />
		));
	}
}

export default TreeForPrint;
