import React from 'react';
import { Edit } from 'react-admin';

import ProjectForm from './ProjectForm';
import ProjectTitle from './ProjectTitle';

const ProjectEdit = ({ permissions, ...props }) => (
	<Edit title={<ProjectTitle />} actions={null} {...props}>
		<ProjectForm permissions={permissions} edit={true} />
	</Edit>
);

export default ProjectEdit;
