import { get } from 'lodash';
import { List } from '@material-ui/core';
import React from 'react';

import BaseTree from './BaseTree';
import TreeNode from './TreeNode';

class Tree extends BaseTree {
	_isMounted = false;

	constructor(props) {
		super(props);

		const { tree } = this.props;
		this.state = { tree };
	}

	componentDidMount() {
		this._isMounted = true;
		this.updateTree();
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	render() {
		const { children, source, ...props } = this.props;
		const { tree } = this.state;

		return (
			<List dense disablePadding>
				{tree.map(node => {
					const nodeId = get(node, source ? `${source}.id` : 'id');
					return (
						<TreeNode
							key={`TreeNode_${nodeId}`}
							{...props}
							node={node}
							source={source}
							onAdd={this.handleAdd}
							onDelete={this.handleDelete}
							onEdit={this.handleEdit}
						>
							{children}
						</TreeNode>
					);
				})}
			</List>
		);
	}

	updateTree() {
		const { fetchTree } = this.props;

		if (fetchTree) {
			fetchTree().then(tree => {
				if (this._isMounted) {
					this.setState({ tree });
				}
			});
		}
	}
}

Tree.defaultProps = {
	tree: []
};

export default Tree;
