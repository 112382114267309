import { unparse as convertToCSV } from 'papaparse/papaparse';
import { Button, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { GET_LIST, downloadCSV } from 'react-admin';
import { Publish } from '@material-ui/icons';
import { withDataProvider } from 'react-admin';
import { compose } from 'recompose';

const styles = {
	root: {
		background: 'inherit',
		color: '#1da4f2',
		border: 'none',
		boxShadow: 'none'
	},
	publish: {
		fontSize: 20,
		color: '#1da4f2',
		transform: 'rotate(180deg)'
	},
	paddingLeft: {
		paddingLeft: '0.5em'
	}
};

class ExportRecord extends Component {
	download = async () => {
		const { dataProvider, record } = this.props;

		const { data: reports } = await dataProvider(
			GET_LIST,
			`reports?limit=10000&filter[project]=${record.id}`,
			{}
		);

		if (reports.length) {
			const data = reports.map(el => ({
				Project: el.project,
				Number_of_articles: el.number_of_articles,
				"QRcode's": el.barcodes,
				User: el.full_name,
				Date: new Date(el.update_date_of_article).toLocaleString(),
				Activity: el.activity,
				Old_storage_area: el.old_storage_area,
				New_storage_area: el.new_storage_area,
				Article_status: el.article_status,
				Qm_of_Project: el.square_meters,
				Cbm_of_Project: el.cubic_meters,
				Transfer_tools: el.transfer_tools,
				Old_loading_points: el.old_loading_points,
				New_loading_points: el.new_loading_points,
				Old_unloading_points: el.old_unloading_points,
				New_unloading_points: el.new_unloading_points
			}));
			const csv = convertToCSV({
				data,
				fields: [
					'Project',
					'Number_of_articles',
					"QRcode_ID's",
					'User',
					'Date',
					'Activity',
					'Old_storage_area',
					'New_storage_area',
					'Article_status',
					'Qm_of_Project',
					'Cbm_of_Project',
					'Transfer_tools',
					'Old_loading_points',
					'New_loading_points',
					'Old_unloading_points',
					'New_unloading_points'
				]
			});

			downloadCSV(csv, `Report for Project ID ${record.id}, Customer ${reports[0].customer}`);
		} else {
			alert('There are no reports');
		}
	};

	render() {
		const { classes, onlyIcon } = this.props;

		return (
			<Button variant="contained" size="small" className={classes.root} onClick={this.download}>
				<Publish className={classes.publish} />
				{!onlyIcon && <span className={classes.paddingLeft}>Export</span>}
			</Button>
		);
	}
}

const enhance = compose(withDataProvider, withStyles(styles));

export default enhance(ExportRecord);
