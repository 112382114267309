let currentUser;

export default (checkInLocalStorage = true) => {
	if (!checkInLocalStorage && currentUser) {
		return currentUser;
	}

	const user = localStorage.getItem('user');

	if (user) {
		currentUser = JSON.parse(user);

		return currentUser;
	}

	return null;
};
