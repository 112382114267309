import ArticleIcon from '@material-ui/icons/Widgets';

import ArticleList from './ArticleList';
import ArticleCreate from './ArticleCreate';
import ArticleEdit from './ArticleEdit';
import ArticleShow from './ArticleShow';

export default {
	list: ArticleList,
	create: ArticleCreate,
	edit: ArticleEdit,
	icon: ArticleIcon,
	show: ArticleShow
};
