import { stringify } from 'querystring';
import { isEmpty, omit } from 'lodash';
import simpleRestProvider from 'ra-data-simple-rest';
import { GET_LIST, GET_MANY, GET_MANY_REFERENCE, CREATE, UPDATE_MANY } from 'react-admin';

import { DELETE_COMMENT, HEAD } from './actions';
import httpClient from './httpClient';

/**
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The data request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertDataRequestToHTTP = (type, resource, params) => {
	let url = '';
	const options = {};
	const filter = {};
	/**
	 * when we click button "Group By SN", we put in parameter filter flag: isGrouped and depending on this flag
	 * group or not articles
	 * line 24 - 32
	 */
	let intermediateFilter = {};
	let isGrouped = false;
	if (typeof params.filter === 'object') {
		if (Object.hasOwn(params.filter, 'isGrouped')) {
			isGrouped = params.filter.isGrouped;
			const unnecessaryField = 'isGrouped';
			intermediateFilter = omit(params.filter, unnecessaryField);
		} else {
			intermediateFilter = { ...params.filter };
		}
	}
	if (type === GET_MANY) {
		filter[`filter[id][in][]`] = params.ids;

		url = `${process.env.REACT_APP_API_URL}/${resource}?${stringify(filter)}`;

		return { url, options };
	}

	if (type === GET_MANY_REFERENCE) {
		filter[`filter[${params.target}]`] = params.id;
	}

	if (type === DELETE_COMMENT) {
		url = `${process.env.REACT_APP_API_URL}/${resource}`;
		if (params.ids) {
			const query = {
				filter: JSON.stringify({ id: params.ids })
			};
			url += `?${stringify(query)}`;
		}
		options.method = 'PATCH';
		options.body = JSON.stringify(params.data);

		return { url, options };
	}
	if (type === CREATE) {
		url = `${process.env.REACT_APP_API_URL}/${resource}`;
		options.method = 'POST';
		options.body = JSON.stringify(params.data);

		return { url, options };
	}
	if (type === HEAD) {
		options.method = 'HEAD';
	}

	if (!isEmpty(intermediateFilter)) {
		Object.keys(intermediateFilter || []).forEach(key => {
			filter[`filter[${key}]`] = intermediateFilter[key];
		});
	}

	const query = { ...filter };

	if (params.pagination) {
		const { page, perPage } = params.pagination;

		query.limit = perPage;
		query.offset = (page - 1) * perPage;
	}

	if (params.sort) {
		const { field, order } = params.sort;

		if (field === 'serial_number') {
			query['sort[serialNumber]'] = order;
		} else if (field === 'address') {
			query[`sort[${field}.city]`] = order;
			query[`sort[${field}.postalCode]`] = order;
			query[`sort[${field}.street]`] = order;
		} else {
			query[`sort[${field}]`] = order;
		}
	}

	if (params.group) {
		params.group.forEach((value, index) => {
			query[`group[${index}]`] = value;
		});
	}

	if (params.queryparams) {
		Object.keys(params.queryparams || []).forEach(key => {
			query[key] = params.queryparams[key];
		});
	}

	if (isGrouped) {
		query['groupBy'] = 'serialNumber';
	}

	if (resource === 'articles') {
		query['sort[status]'] = 'DESC';
	}

	url = `${process.env.REACT_APP_API_URL}/${resource}`;

	if (!isEmpty(query)) {
		url += `?${stringify(query)}`;
	}

	return { url, options };
};

/**
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {Object} response HTTP response from fetch()
 * @param {Object} params The data request params, depending on the type
 * @returns {Object} Data response
 */
const convertHTTPResponse = (type, response, params) => {
	const headers = (response && response.headers) || new Headers();
	const isManyRequest = [GET_LIST, GET_MANY, GET_MANY_REFERENCE].includes(type);
	const json = (response && response.json) || (isManyRequest ? [] : {});

	if (type === CREATE || type === DELETE_COMMENT) {
		return {
			data: {
				...params.data,
				...json
			}
		};
	}

	if (!isManyRequest) {
		return {
			data: json
		};
	}

	return {
		data: json,
		total: parseInt(headers.get('x-total-count'), 10)
	};
};

const dataProvider = simpleRestProvider(process.env.REACT_APP_API_URL, httpClient);

export default (type, resource, params) => {
	switch (type) {
		case GET_LIST:
		case GET_MANY:
		case GET_MANY_REFERENCE:
		case DELETE_COMMENT:
		case HEAD:
		case CREATE: {
			const { url, options } = convertDataRequestToHTTP(type, resource, params);

			return httpClient(url, options).then(response => convertHTTPResponse(type, response, params));
		}
		case UPDATE_MANY: {
			return Promise.all(
				params.ids.map(id =>
					httpClient(`${process.env.REACT_APP_API_URL}/${resource}/${id}`, {
						method: 'PATCH',
						body: JSON.stringify(params.data)
					})
				)
			).then(responses => ({
				data: responses.map(({ json }) => json)
			}));
		}
		default: {
			return dataProvider(type, resource, params);
		}
	}
};
