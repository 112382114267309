import React from 'react';
import { Edit } from 'react-admin';

import CustomerForm from './CustomerForm';
import { headerFullNameAndCompanyHelper } from '../helpers/headerNameHelpers';

const CustomerTitle = ({ record }) => (
	<span>{headerFullNameAndCompanyHelper(record.id, record.address, record.user)}</span>
);

const CustomerEdit = ({ permissions, ...props }) => (
	<Edit title={<CustomerTitle />} undoable={false} {...props}>
		<CustomerForm permissions={permissions} />
	</Edit>
);

export default CustomerEdit;
